import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dropzone} from "./Dropzone";
import {deleteMedia, getResumeMedia} from "../../../actions/UploadPhoto";
import {DeleteIcon} from "../../Icons/Delete";

export const PhotoGalleryEmployer = () => {
  const dispatch = useDispatch();
  const user = useSelector(user => user.authentication.user);
  const userId = user.id;
  const gallery = useSelector(state => state.media.portfolio);
  
  useEffect(() => {
     dispatch(getResumeMedia(
        {
           link: "hirer-portfolio",
           title: "portfolio"
        },
        {uid: userId}
     ));
  },[dispatch, userId]);

  const handleDelete = (id)  => {
    dispatch(deleteMedia(
        {
           link: 'hirer-portfolio',
           title: "portfolio",
           id: id
        }
     ));
  };

  return (
      <>
        {gallery &&
            <div className="wrapper-images flex-element">
              <Dropzone path="hirer-portfolio" />
              {gallery.map((image) => {
                return <div className="img-wrap relative" key={image.id}>
                          <img src={image.path} alt=""/>
                          <button aria-label="Удалить" className="button-transparent" onClick={() => handleDelete(image.id)}>
                            <DeleteIcon />
                          </button>
                        </div>
                  })}
            </div>
        }
      </>
  )
};

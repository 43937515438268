import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import { getUniversityInfo } from '../../actions/HomeAction';
import {useDispatch, useSelector} from 'react-redux';
import GalleryPhoto from "../GalleryPhoto/GalleryPhoto";
import {Preloader} from "../Preloader/Preloader";
import {Helmet} from "react-helmet/es/Helmet";
import {Avatar} from "../Avatar/Avatar";
import {getResumeMedia} from "../../actions/UploadPhoto";

export const UniversityCard = ({match}) => {
  const id = match.params.universityId;
  const universityInfo = useSelector(state => state.universityInfo);
  const gallery = useSelector(state => state.media.portfolio);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUniversityInfo(id));
    dispatch(getResumeMedia(
        {
           link: "university-portfolio",
           title: "portfolio"
        },
        {
           university_id: id
        }
     ))
  },[dispatch, id]);

  return (
      universityInfo.university === null ? <Preloader/> :
      <div className="inner-card inner-card--primary">
        <Helmet>
          <title>{universityInfo.university.abbreviation}</title>
        </Helmet>
        <aside>
          <div className="card-aside mb">
            <div className="card-info card-bg">
              <div className="card-info__head card-info-head--center">
                <Avatar photo={universityInfo.university.photo} alt={universityInfo.university.abbreviation} class="card-info__img" />
                <h1 className="title-bold text-black text-upper mb-none">{universityInfo.university.abbreviation}</h1>
              </div>

              <div className="card-info__body">
                <h3 className="title-bold text-upper">Общая информация</h3>
                <ul>
                  <li>
                    <span>Год основания:</span>
                     <span>{universityInfo.university.since}</span>
                    {/*<span>{new Date(universityInfo.university.created_at * 1000).toLocaleString('ru', {*/}
                    {/*  year: 'numeric',*/}
                    {/*  month: 'long',*/}
                    {/*  day: 'numeric'*/}
                    {/*})}</span>*/}
                  </li>
                  <li>
                    <span>Ректор:</span>
                    <span>{universityInfo.university.rector}</span>
                  </li>
                  <li>
                    <span>Телефон приемной:</span>
                    <span>{universityInfo.university.reception_phone}</span>
                  </li>
                  <li>
                    <span>Сайт:</span>
                    <span><Link target="_blank" to={{pathname: `${universityInfo.university.site}`}}>{universityInfo.university.site}</Link></span>
                  </li>
                </ul>
              </div>

              <div className="card-info__foot">
                <div className="button-group flex-element">
                  <Link target="_blank" to={{pathname: `${universityInfo.university.endowment_fund}`}} className="button button-small button-green-gradient">Эндаумент</Link>
                  <Link to="#" className="button button-small button-gradient-primary">Хоздоговорная работа</Link>
                </div>
              </div>

            </div>
          </div>
        </aside>

        <div className="inner-card__content flex-element flex-element--justify-sb card-content">
          <div className="card-content__left">
            <div className="card-about card-about--height card-bg">
              <div className="card-about__head card-about__head--amendments">
                <h3 className="title-bold mb-none">Об университете</h3>
              </div>
              <div className="card-about__body" dangerouslySetInnerHTML={{__html: universityInfo.university.description}}></div>
            </div>
          </div>
          <div className="card-content__right">
            {gallery && gallery.length <= 0 ?
              null
              :
              <div className="card-bg card-photo">
                <h3 className="title-bold mb-none">Фотогалерея</h3>
                <GalleryPhoto gallery={gallery} />
              </div>
            }
          </div>
        </div>
      </div>
  )
};


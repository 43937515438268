import React from 'react';
import {Field, reduxForm} from "redux-form";
import validate from "../../FormElement/validate";
import {FieldInput} from "../../FormElement/Field/FieldInput";

export const FormFaculties = ({handleSubmit}) => (
    <form className="form" onSubmit={e => handleSubmit(e)}>
       <div className="form__item">
          <label htmlFor="enterTitle" className="visually-hidden">Введите название</label>
          <Field
             id="enterTitle"
             name="name"
             type="text"
             placeholder="Введите название"
             component={FieldInput} />
       </div>


        <button className="button button-green-gradient button-bold button-upper button-small button-indent">добавить</button>
    </form>
);

export default reduxForm({
  form: 'faculty',
  validate
})(FormFaculties)

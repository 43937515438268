import {articleConstants} from "../constants/articleConstants";
import API from "../helpers/api";
import {history} from "../helpers/history";

export function isLoadingThemes(isLoading) {
   return {
      type: articleConstants.IS_LOADING_THEMES,
      isLoading
   }
}
export function getThemeSuccess(themes) {
   return {
      type: articleConstants.GET_THEMES_SUCCESS,
      themes
   }
}

export function getThemeFailure(error) {
   return {
      type: articleConstants.GET_THEMES_FAILURE,
      error
   }
}

export function getThemes(type) {
   return async dispatch => {
      dispatch(isLoadingThemes(true));
      try {
         const themes = await API.get(`/help/categories/${type}`);
         const article = await API.get(`/help/${themes.data.items[0].materials[0].link}`);

         dispatch(getThemeSuccess(themes.data.items));
         dispatch(getArticleSuccess(article.data));

         history.push(`/help/${type}/${themes.data.items[0].materials[0].link}`)
      } catch (error) {
         dispatch(getThemeFailure(error));
      }
   };
}


export function getArticleSuccess(article) {
   return {
      type: articleConstants.GET_ARTICLE_SUCCESS,
      article
   }
}

export function getArticle(link) {
   return (dispatch) => {
      return API.get(`/help/${link}`)
         .then(article => {
            dispatch(getArticleSuccess(article.data));
         })
         .catch(error => {
            console.log(error)
         })
   }
}

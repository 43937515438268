import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";
import GalleryPhoto from "../../GalleryPhoto/GalleryPhoto";
import { Keywords } from "../../Keywords/Keywords";
import { Avatar } from "../../Avatar/Avatar";
import { YandexMap } from "../../YandexMap/YandexMap";
import { CardPreviewVacancy } from "../../Layouts/CardPreviewVacancy";
import { status } from "../../../constants/status";

export const CompanyCard = ({ companyInfo, checkAuth, gallery, vacancy }) => {
   return (
      <div className="inner-card inner-card--primary">
         <Helmet>
            <title>{companyInfo.company.name}</title>
         </Helmet>
         <aside>
            <div className="card-aside mb">
               <div className="card-info card-bg">
                  <div className="card-info__head card-info__head--center">
                     <Avatar
                        photo={companyInfo.company.photo}
                        alt={companyInfo.company.name}
                        class="card-info__img"
                     />
                     <h1 className="title-bold text-black text-upper mb-none">
                        {companyInfo.company.name}
                     </h1>
                     {companyInfo.company.status === status.DISABLED && (
                        <span className="text-red min">
                           Компания заблокирована
                        </span>
                     )}
                  </div>
                  <div className="card-info__body">
                     <h3 className="title-bold title-min text-upper">
                        Общая информация
                     </h3>
                     <ul>
                        <li>
                           <span>Город:</span>
                           <span>{companyInfo.company.city}</span>
                        </li>
                        <li>
                           <span>На рынке:</span>
                           <span>
                              {new Date().getFullYear() -
                                 companyInfo.company.start_year}{" "}
                              лет
                           </span>
                        </li>
                        <li>
                           <span>Контактное лицо:</span>
                           <span>
                              {companyInfo.company["user"]
                                 ? `${companyInfo.company["user"].surname} ${companyInfo.company["user"].username}`
                                 : null}
                           </span>
                        </li>
                        {checkAuth !== undefined && (
                           <li>
                              <span>Телефон:</span>
                              <span>
                                 {companyInfo.company["user"]
                                    ? companyInfo.company["user"].phone
                                    : null}
                              </span>
                           </li>
                        )}
                        {checkAuth !== undefined && (
                           <li>
                              <span>E-mail:</span>
                              <span>
                                 {companyInfo.company["user"]
                                    ? companyInfo.company["user"].email
                                    : null}
                              </span>
                           </li>
                        )}
                     </ul>
                  </div>

                  {checkAuth === undefined && (
                     <div className="card-info__body">
                        <p className="mb-none text-red">
                           Персональные данные доступны только
                           зарегистрированным пользователям
                        </p>
                     </div>
                  )}

                  <div className="card-info__foot">
                     <h3 className="title-bold title-min text-upper">
                        Отрасль компании
                     </h3>
                     {companyInfo.company.category ? (
                        <Keywords
                           keywords={companyInfo.company.category}
                           classBadge="badge--light"
                        />
                     ) : null}
                  </div>
               </div>
            </div>

            <div className="card-map card-bg">
               <h3>Местоположение</h3>
               <p>{companyInfo.company.main_address}</p>
               <YandexMap address={companyInfo.company.main_address} />
            </div>
         </aside>

         <div className="inner-card__content flex-element flex-element--justify-sb card-content">
            <div className="card-content__left">
               <div className="card-about card-bg card-about--height">
                  <div className="card-about__head">
                     <h3 className="title-bold mb-none">О себе</h3>
                  </div>
                  <div className="card-about__body">
                     <p
                        dangerouslySetInnerHTML={{
                           __html: companyInfo.company.description
                              .replace(/\n/g, "<br>")
                              .trim(),
                        }}
                     />
                  </div>
               </div>
            </div>
            <div className="card-content__right">
               <div className="card-bg card-photo card-about--height">
                  <h3 className="title-bold mb-none">Фотогалерея</h3>
                  <GalleryPhoto gallery={gallery} />
               </div>
            </div>

            <div className="card-content__list">
               {vacancy !== undefined && vacancy.length > 0 && (
                  <>
                     <p>
                        Вакансии компании:{" "}
                        {
                           vacancy.filter((status) => status.status === 10)
                              .length
                        }
                     </p>
                     {vacancy
                        .filter((status) => status.status === 10)
                        .map((vacancy) => (
                           <Link
                              to={{ pathname: `/vacancies/${vacancy.id}` }}
                              className="card-preview flex-element"
                              key={vacancy.id}
                           >
                              <CardPreviewVacancy data={vacancy} />
                           </Link>
                        ))}
                  </>
               )}
            </div>
         </div>
      </div>
   );
};

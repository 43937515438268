import {universityConstants} from "../../constants/universityConstants";
import API from "../../helpers/api";
import { toast } from "react-toastify";

export const addFacultiesSuccess = (faculty) => ({
    type: universityConstants.ADD_FACULTY_SUCCESS,
    faculty
});

export function addFaculties(id, name) {
    return dispatch => {
        return API.post(`/faculty`, {university_id: id, name: name})
            .then(res => {
                dispatch(addFacultiesSuccess(res.data.model));
                toast.success("Факультет добававлен");
            }).catch(error => {
                toast.error(error.response.data.message.hash[0]);
            });
    }
}


export function getFacultiesSuccess(faculties, pagination) {
    return {
        type: universityConstants.GET_FACULTIES_SUCCESS,
        faculties,
        pagination
    }
}

export function getFacultiesFailure(error) {
    return {
        type: universityConstants.GET_FACULTIES_FAILURE,
        error
    }
}

export function isLoadingFaculties(isLoading) {
  return {
    type: universityConstants.IS_LOADING_FACULTIES,
    isLoading
  }
}

export function getFaculties(id, page) {
    return (dispatch) => {
        dispatch(isLoadingFaculties(true));
        return API.get(`/faculty/search?university_id=${id}&page=${page}`)
            .then(faculties => {
              dispatch(getFacultiesSuccess(faculties.data.items, faculties.data._meta));
            })
            .catch(error => {
                dispatch(getFacultiesFailure(error));
            })
    }
}


export function deleteFacultiesSuccess(faculty) {
    return {
        type: universityConstants.DELETE_FACULTY_SUCCESS,
        faculty
    }
}

export function deleteFaculties(id) {
    return (dispatch) => {
        return API.get(`/faculty/${id}`)
            .then(faculties => {
                dispatch(deleteFacultiesSuccess(faculties.data.id));
                toast.success("Факультет удален");
            })
            .catch(error => {
                console.log(error)
            })
    }
}

// загрузка из файла
export function addFacultiesFile(university_id, file, values) {
  const formData = new FormData();
  formData.append("university_id", university_id);
  formData.append("file", file);
  formData.append("name_start", values.name_start);

  return dispatch => {
    dispatch(isLoadingFaculties(true));
    return API.post(`/faculty/upload-excel`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
      dispatch(getFaculties(university_id));
      toast.success('Факультеты загружены')
    }).catch(error => {
      console.log(error)
    });
  }
}

import React from 'react';
import {useDispatch} from "react-redux";
import {Link} from 'react-router-dom';

import {favoritesDelete} from "../../actions/FavoritesAction";
import {FavoritesList} from './FavoritesList';

export const Favorites = ({favorites, text}) => {
   const dispatch = useDispatch();

   const removeFavorites = (id) => {
      dispatch(favoritesDelete(id))
   };

   return (
      <div className="bg-card bg-card--inner">
         {favorites.length > 0 ? (
            <div className="table-responsive">
               <table className="table table--shadow-none table--upper table-small-size table--head-transparent">
                  <thead>
                  <tr>
                     <th scope="col">Дата</th>
                     <th scope="col">{text === 'Резюме' ? "Резюме" : "Вакансия"}</th>
                     {text === 'Резюме' && <th scope="col">Кандидат</th>}
                     <th scope="col">Действия</th>
                  </tr>
                  </thead>
                  <tbody>
                     <FavoritesList text={text} date={favorites} removeFavorites={removeFavorites}/>
                  </tbody>
               </table>
            </div>
         ) : text === 'Резюме' ? <p className="mb-none txt-info">Добавляйте в избранное понравшиеся вам <Link to={{pathname: '/resumes'}}>резюме</Link>.</p>
           :
            <p className="mb-none txt-info">Добавляйте в избранное понравшиеся вам <Link to={{pathname: '/vacancies'}}>вакансии</Link>. </p>}
      </div>
   )
};


import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';
import { userActions } from '../../../actions/AuthAction';
import {FieldMask} from "../../FormElement/Field/FieldMask";
import {FieldInput} from "../../FormElement/Field/FieldInput";
import validate from "../../FormElement/validate";
import {FieldSelect} from "../../FormElement/Field/FieldSelect";
import {Link} from "react-router-dom";

const RegistrationUniversityForm = ({cities, universities, handleSubmit, handleChange, show}) => {
  let optionsCity = cities.map(city => {return ({value: city.city, label: city.city})});
  let optionsUniversity =  universities.map(universities => {return ({value: universities.id, label: universities.abbreviation})});

  return (
      <form className="form" onSubmit={handleSubmit}>
        <fieldset>
          <legend>Контактное лицо</legend>
          <Field
              name="type"
              component="input"
              type="hidden"
              value="university"
          />

          <div className="form__line flex-element">
            <div className="form__item">
              <label className="form__label" htmlFor="username">Имя</label>
              <Field
                  id="username"
                  name="username"
                  type="text"
                  component={FieldInput}
                  label="Имя"
                  placeholder="Имя"
              />
            </div>

            <div className="form__item">
              <label className="form__label" htmlFor="surname">Фамилия</label>
              <Field
                  id="surname"
                  name="surname"
                  type="text"
                  component={FieldInput}
                  label="Фамилия"
                  placeholder="Фамилия"
              />
            </div>

          </div>

          <div className="form__line flex-element">
            <div className="form__item form__item--custom">
              <label className="form__label">
                 <span>Город</span>
              </label>
              <Field
                  name="city"
                  placeholder="Выберите"
                  options={optionsCity}
                  onChange={(value) => {handleChange(value)}}
                  component={FieldSelect}
              />
            </div>

            <div className={show ? 'form__item item-hidden item-hidden--active' : `form__item item-hidden`}>
              <label className="form__label" htmlFor="university_id">Университет</label>
              <Field
                  id='university_id'
                  name="university_id"
                  placeholder="Выберите"
                  component={FieldSelect}
                  options={optionsUniversity}
              />
            </div>
          </div>

          <div className="form__line flex-element">
            <div className="form__item">
              <label className="form__label" htmlFor="email">Email</label>
              <Field
                  id="email"
                  name="email"
                  component={FieldInput}
                  placeholder="Email"
                  label="Email" />
            </div>

            <div className="form__item">
              <label className="form__label" htmlFor="phone">Телефон</label>
              <Field
                  id="phone"
                  name="phone"
                  component={FieldMask}
                  label="Телефон"
                  placeholder="+7 (___) ___-__-__"
                  mask="+7 (999) 999-99-99"
              />
            </div>
          </div>

            <div className="form__line flex-element">
              <div className="form__item">
                <label className="form__label" htmlFor="pass">Пароль</label>

                <Field
                    id="pass"
                    name="pass"
                    type="password"
                    component={FieldInput}
                    placeholder="Введите пароль"
                    label="Пароль" />
              </div>

              <div className="form__item">
                <label className="form__label" htmlFor="new_pass">Повторите пароль</label>
                <Field
                    id="new_pass"
                    name="new_pass"
                    type="password"
                    component={FieldInput}
                    placeholder="Введите пароль"
                    label="Повторите пароль" />
              </div>
            </div>
           <p className="mb-none">Нажимая «Зарегистрироваться», вы подтверждаете, что ознакомлены, полностью согласны и принимаете условия <Link to={{pathname: `../file/Пользовательское соглашение.docx`}} target="e_blank">«Пользовательского соглашения»</Link> и <Link to={{pathname: `../file/Политика_конфиденциальности_25.01.2020.docx`}} target="e_blank">«Политики конфиденциальности»</Link></p>
          <button type="submit" className="button button-green-gradient button-small button-bold button-upper">Зарегистрироваться</button>
        </fieldset>
      </form>
  )
};

export default connect(null, {
  onSubmit: (values) => {
    let valuesNewObj = Object.assign({}, values);
    valuesNewObj.university_id = values.university_id.value;
    return userActions.register(valuesNewObj)
  }
})(reduxForm({
  form: 'register',
  initialValues: {
    type: 'university'
  },
  validate
})(RegistrationUniversityForm))

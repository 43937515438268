import { universityConstants } from "../../constants/universityConstants";
import API from "../../helpers/api";
import { toast } from "react-toastify";
import { getFaculties } from "./FacultiesAction";

export const addSpecialitySuccess = (specialty) => ({
  type: universityConstants.ADD_SPECIALTY_SUCCESS,
  specialty
});

export function addSpeciality(values) {
  return dispatch => {
    return API.post(`/speciality`, values)
      .then(res => {
        dispatch(addSpecialitySuccess(res.data.model));
        toast.success("Специальность добавлена");
      }).catch(error => {
        toast.error(error.response.data.message.hash[0])
      });
  }
}


export function deleteSpecialtySuccess(specialty) {
  return {
    type: universityConstants.DELETE_SPECIALTY_SUCCESS,
    specialty
  }
}

export function deleteSpecialty(id) {
  return (dispatch) => {
    return API.delete(`/speciality/${id}`)
      .then(specialty => {
        dispatch(deleteSpecialtySuccess(specialty.data.id));
        toast.success("Спциалность удалена");
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export function isLoadingSpecialities(isLoading) {
  return {
    type: universityConstants.IS_LOADING_SPECIALTIES,
    isLoading
  }
}
export function getSpecialitiesSuccess(specialties, pagination) {


  return {
    type: universityConstants.GET_SPECIALTIES_SUCCESS,
    specialties,
    pagination
  };
}

export const getSpecialitiesFailure = (error) => ({
  type: universityConstants.GET_SPECIALTIES_FAILURE,
  error
});

export function getSpeciality(id, page) {
  return (dispatch) => {
    dispatch(isLoadingSpecialities(true));
    return API.get(`/speciality/search?faculty_id=${id}&page=${page}`)
      .then(specialty => {
        dispatch(getSpecialitiesSuccess(specialty.data.items, specialty.data._meta));
      })
      .catch(error => {
        dispatch(getSpecialitiesFailure(error))
      })
  }
}

export function addSpecialitiesFile(file, university_id, faculty_id, name_start, code_start) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("university_id", university_id);
  formData.append("faculty_id", faculty_id);
  formData.append("name_start", name_start);
  formData.append("code_start", code_start);

  return dispatch => {
    dispatch(isLoadingSpecialities(true));
    return API.post(`/speciality/upload-excel`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        dispatch(getFaculties(university_id, 1));
        toast.success('Специальности загружены')
      }).catch(error => {
        dispatch(isLoadingSpecialities(false));
        toast.error(error.response.data.message);
      });
  }
}

// список всех специальностей, используется только для фильтра
export function getSpecialities() {
  return (dispatch) => {
    return API.get(`/speciality/all`) //
      .then((speciality) => {
        dispatch(getSpecialitiesSuccess(speciality.data.items));
      })
      .catch(error => dispatch(getSpecialitiesFailure(error)))
  }
}

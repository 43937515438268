import React, {} from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {userActions} from "../../../actions/AuthAction";
import validate from "../../FormElement/validate";
import {FieldInput} from "../../FormElement/Field/FieldInput";

const LoginBase = ({handleSubmit, error}) => {
    return (
        <form className="form" onSubmit={handleSubmit}>

          <div className="form__item">
            <label htmlFor="password" className="visually-hidden">E-mail</label>
            <Field
                id="email"
                name="phone"
                type="text"
                placeholder="Введите E-mail"
                component={FieldInput}/>
          </div>

          <div className="form__item">
             <label htmlFor="password" className="visually-hidden">Пароль</label>
            <Field
                id="password"
                name="password"
                type="password"
                placeholder="Введите пароль"
                component={FieldInput} />
          </div>

          {error && <span className="hasError hasError--pb ">{error}</span>}

          <div className="flex-element flex-element--options form__line">
            <button type="submit" className="button button-gradient-primary button-indent">Войти</button>
            <div className="form__group">
              <Link to="login/student">Войти как студент</Link>
              <Link to="restore">Забыли пароль?</Link>
            </div>
          </div>
        </form>
    )
};

export default connect(null, {
  onSubmit: values => {
    return userActions.login('auth', values)
  }
})(reduxForm({
  form: 'login-basis',
  validate,
})(LoginBase))

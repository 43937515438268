import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import PersonalDataSettingForm from './PersonalDataSettingForm';
import {changePersonalData, photoChange} from '../../../actions/PersonalData';

export const PersonalDataSettingContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);
  const userId = user.id;

  const handleSubmit = (values) => {
    if (values.photo && typeof values.photo === 'object') {
      const promise = dispatch(photoChange(values.photo[0]));

      promise.then((newPhoto) => {
        const newObject = Object.assign({}, values);
        newObject.photo = newPhoto;
        dispatch(changePersonalData(userId, newObject))
      })
    } else {
      dispatch(changePersonalData(userId, values))
    }
  };

  return (
    <div className="content-form">
      <PersonalDataSettingForm
          initialValues={user}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
      />
    </div>
  )
};


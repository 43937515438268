export function flattenObjHelper(ob) {
  let toReturn = {};

  let i;
  for (i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      let flatObject = flattenObjHelper(ob[i]);
      let x;
      for (x in flatObject) {
        if (!flatObject.hasOwnProperty(x) || x !== 'value') continue;

        toReturn[i] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }

  return toReturn;
}


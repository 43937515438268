import {FormStudent} from "./Form";
import React from "react";
import {connect} from "react-redux";
import {addStudent} from "../../../../actions/Universtiy/StudentsAction";
import validate from "../../../FormElement/validate";
import {reduxForm} from "redux-form";

const PersonalDataStudentFormPost = ({handleSubmit}) => {
  return <FormStudent handleSubmit={handleSubmit} />
};

export default connect(null, {
  onSubmit: addStudent
})(reduxForm({
  form: 'personalDataStudentFormPost',
  enableReinitialize: true,
  validate
})(PersonalDataStudentFormPost));


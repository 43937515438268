import React from 'react';
import { Link } from "react-router-dom";
import {ButtonDelete} from "../../ButtonOptions/ButtonOptions";

export const FacultiesBodyList = ({ data, handleClickDelete, url }) => (
    <>
      {
        data.map((faculty) => {
          return <tr key={faculty.id}>
            <td>
              <Link to={`${url}/${faculty.id}/speciality`}>{faculty.name}</Link>
            </td>
            <td>{faculty.infoCount.specialities}</td>
            <td>{faculty.infoCount.groups}</td>
            <td>{faculty.infoCount.students}</td>
            <td>
               <ButtonDelete onClick={() => handleClickDelete(faculty.id)} />
            </td>
          </tr>
        })
      }
    </>
);

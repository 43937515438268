import React from "react";
import { Route } from "react-router-dom";
import { MainVacancyListContainer } from "../components/VacancyList/MainVacancyList/MainVacancyListContainer.jsx";
import { MainVacancyCardContainer } from "../components/VacancyCard/MainVacancyCardContainer";

export const VacanciesPage = ({ match }) => {
   return (
      <div className="inner-page inner-page--pb">
         <div className="container">
            <Route
               exact
               path={match.path}
               component={MainVacancyListContainer}
            />
            <Route
               path={`${match.url}/:vacancyId`}
               component={MainVacancyCardContainer}
            />
         </div>
      </div>
   );
};

import React from "react";
import {Field, reduxForm} from "redux-form";
import validate from "../../FormElement/validate";
import {FieldMask} from '../../FormElement/Field/FieldMask';
import {FieldInput} from "../../FormElement/Field/FieldInput";

const FirstVisitForm = ({ handleSubmit }) => (
    <form className="form" onSubmit={e => handleSubmit(e)}>

      <div className="form__item">
        <label className="form__label" htmlFor="email">E-mail</label>
        <Field
            id="email "
            name="email"
            type="text"
            component={FieldInput} />
      </div>

      <div className="form__item">
        <label className="form__label" htmlFor="pass">Пароль</label>
        <Field
            id="pass"
            name="pass"
            type="password"
            component={FieldInput} />
      </div>

      <div className="form__item">
        <label className="form__label" htmlFor="new_pass">Повторите пароль</label>
        <Field
            id="new_pass"
            name="new_pass"
            type="password"
            component={FieldInput} />
      </div>

      <div className="form__item">
        <label className="form__label" htmlFor="phone">Номер телефона</label>
        <Field
            label="Номер телефона"
            id="phone"
            name="phone"
            type="text"
            placeholder="+7 (___) ___-__-__"
            component={FieldMask}
            mask="+7 (999) 999-99-99"
        />
      </div>

      <button className="button button-green-gradient button-indent button-bold">Сохранить</button>
    </form>
);

export default reduxForm({
    form: 'settings-student',
		validate,
		enableReinitialize: true
})(FirstVisitForm)

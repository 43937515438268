import React from "react";
import { Route } from "react-router-dom";
import { CreateVacancy } from "../components/VacancyList/CreateOrEditVacancy/CreateVacancy";
import { MyVacancyCardContainer } from "../components/VacancyCard/MyVacancyCardContainer";
import { SettingsEmployerContainer } from "../components/Settings/SettingsJustEmployer/SetiingEmployerContainer";
import { MyVacancyListContainer } from "../components/VacancyList/MyVacancyList/MyVacancyListContainer";
import { FavoritesEmployer } from "../components/Favorites/FavoritesContainer";
import { InvitationsEmployer } from "../components/Invitations/InvitationsEmployer";
import { InvitationsCardResume } from "../components/ResumeCard/ResumeCardContainer";
import { ChangeVacancy } from "../components/VacancyList/CreateOrEditVacancy/ChangeVacancy";
import { FeedbackCardResume } from "../components/ResumeCard/ResumeCardContainer";

export const PersonalEmployerPage = ({ match }) => (
   <div className="content">
      <div className="container">
         <Route
            exact
            path={`${match.path}/vacancy`}
            component={MyVacancyListContainer}
         />
         <Route
            exact
            path={`${match.path}/vacancy/:vacancyId`}
            component={MyVacancyCardContainer}
         />
         <Route
            exact
            path={`${match.path}/vacancy/:vacancyId/edit`}
            component={ChangeVacancy}
         />
         <Route
            exact
            path={`${match.path}/create-vacancy`}
            component={CreateVacancy}
         />
         <Route
            exact
            path={`${match.path}/vacancy/:vacancyId/feedback/:feedbackID`}
            component={FeedbackCardResume}
         />
         <Route
            exact
            path={`${match.path}/invitation`}
            component={InvitationsEmployer}
         />
         <Route
            exact
            path={`${match.path}/invitation/:invitationId`}
            component={InvitationsCardResume}
         />
         <Route
            exact
            path={`${match.path}/favorite`}
            component={FavoritesEmployer}
         />
         <Route
            exact
            path={`${match.url}/settings`}
            component={SettingsEmployerContainer}
         />
      </div>
   </div>
);

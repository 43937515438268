import React from "react";

export const FieldInput = ({ text, id, input, placeholder, type, disabled, meta: { touched, error } }) => (
    <>
        <input
           {...input}
           id={id}
           disabled={disabled}
           placeholder={placeholder}
           type={type} />
            {text === true ? (
                <div className="form-text">Например, Стажер в отдел продаж, Младший специалист технического отдела, Курьер</div> )
            : ''
            }
            { touched && error && <span className='hasError'>{error}</span> }
    </>

);

import React from "react";
import Select from "react-select";

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
       color: state.isSelected ? '#fff' : '#3f4448',
       fontSize: '13px',
    }),
    control: (provided) => ({
        ...provided,
        border: '1px solid #d2d8e4',
        borderRadius: '4px',
        padding: '4px 5px',
    }),
    indicatorSeparator: () => ({
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#90a0b7',
        padding: '3px',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        color: '#90a0b7',
        padding: '3px',
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '13px',
        color: '#68727a'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#3f4448;",
        fontSize: '13px'
    })
};

export const FieldSelect = ({id, input, placeholder, options, meta: { touched, error }}) => {
   return (
       <>
           <Select
               styles={customStyles}
               {...input}
               placeholder={placeholder}
               value={input.value}
               isClearable
               onChange={(value) => {input.onChange(value)}}
               // onBlur={() => input.onBlur(input.value)}
               onBlur={(value) => input.onBlur()}
               onBlurResetsInput={false}
               options={options}
               inputId={id}
               noOptionsMessage = {() => 'Ничего не найдено'}
           />
           { touched && error && <span className='hasError'>{error}</span> }
      </>
   );
};

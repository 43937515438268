import React, {useState} from 'react';
import {AntTab, AntTabs} from "./AntTabs";
import {TabPanel, a11yProps} from "./TabPanel";

export const TabsContent = ({tabs, changeValue=0}) => {
   const [value, setValue] = useState(changeValue);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   return (
      <>
         <AntTabs aria-label="Вкладки" value={value} onChange={handleChange}>
            {tabs.map((tab, index) => <AntTab key={index} label={tab.label} {...a11yProps(index)} />)}
         </AntTabs>

         {tabs.map((panel, index) => <TabPanel key={index} value={value} index={index}>{panel.components}</TabPanel>)}
      </>
   )
};

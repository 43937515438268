export const mapArrayToString = (obj, fields) => {
   for (let i = 0; i  < fields.length; i++) {
      obj[fields[i]] = obj[fields[i]].map(item => item.value).join(', ');
   }
};

export const fromStringIntoArray = (obj, fields) => {
   for (let i = 0; i  < fields.length; i++) {
      obj[fields[i]] = obj[fields[i]].split(", ").map(value => ({value: value, label: value}));
   }
};

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormPersonal from "./FormPersonal";
import {changeSettingStudent} from "../../../../actions/PersonalData";
import {flattenObjHelper} from "../../../Filter/flatteronObjHelper";

export const Personal = () => {
   const dispatch = useDispatch();
   const user = useSelector(state => state.authentication.user);
   const studentInfo = user.student_info;
   const idStudent = studentInfo.id;

   const handleSubmit = ({visible = false, ...rest}) => {
      const copyValues = flattenObjHelper(rest);
      copyValues.birthday = Date.parse(rest.birthday.split("-").reverse().join('-')) / 1000;
      copyValues.visible = String(visible);
      dispatch(changeSettingStudent(idStudent, copyValues))
   };

   let newObject = Object.assign({});
   newObject.visible = studentInfo.visible === 'true' ? newObject.visible = true : newObject.visible = false;
   newObject.birthday = studentInfo.birthday === 0 ? 0 : new Date(studentInfo.birthday * 1000).toISOString().substring(0, 10).split('-').reverse().join('-');
   newObject.gender = studentInfo.gender === 'female' ? {value: 'female', label: 'Женский'} : {
      value: 'male',
      label: 'Мужской'
   };
   newObject.city = studentInfo.city;

   let initialsValues = {
      ...newObject
   };

   return (
      <FormPersonal
         initialValues={initialsValues}
         onSubmit={(values) => {
            handleSubmit(values)
         }}
      />
   )
};

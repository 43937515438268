import React from "react";
import Camera from "../../assets/img/camera.png";

export const Avatar = ({ photo, alt, ...rest }) => {
   return photo ? (
      <img src={photo} alt={alt} className={rest.class} />
   ) : (
      <img src={Camera} alt={alt} className={rest.class} />
   );
};

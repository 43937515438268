import React from 'react';
import {workTime, typeEmployment, workExperience} from "../FormElement/Options";
import {Field, reduxForm} from 'redux-form';
import {FieldInput} from "../FormElement/Field/FieldInput";
import {FieldSelect} from "../FormElement/Field/FieldSelect";
import {FieldDaData} from "../FormElement/Field/FieldDaData";
import {FieldContainerThreeSelect} from "../FormElement/Field/FieldContainerThreeSelect";

export const FilterVacancies = ({handleSubmit, data}) => {
  return (
      <form className="form" onSubmit={e => handleSubmit(e)}>
        <div className="form__group form__group--flex-end">
          <div className="form__item">
            <label className="form__label" htmlFor="name">Введите название</label>
              <Field
                  id="name"
                  name="name"
                  type="text"
                  component={FieldInput}
                  placeholder="Введите название"
              />
          </div>

          <div className="form__item form__item--custom">
            <label htmlFor="city" className="form__label">
               <span>Город</span>
               <Field
                  name="city"
                  type="text"
                  component={FieldDaData}
               />
            </label>
          </div>

          <div className="form__item form__item--custom form__item--category">
            <span className="form__span form__label">Профессиональная область</span>
            <Field
               name="category"
               type="text"
               placeholder="Выберите проф область"
               data={data}
               component={FieldContainerThreeSelect}
            />

          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="work_time">График работы</label>

            <Field
                id='work_time'
                name="work_time"
                options={workTime}
                component={FieldSelect}
                placeholder="Выберите график работы"
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="type_employment">Тип занятости</label>
            <Field
                id="type_employment"
                name="type_employment"
                type="text"
                placeholder="Выберите"
                options={typeEmployment}
                component={FieldSelect}
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="work_experience">Опыт работы</label>
            <Field
                id="work_experience"
                name="work_experience"
                type="text"
                placeholder="Выберите"
                options={workExperience}
                component={FieldSelect}
            />
          </div>

           <div className="form__item">
              <fieldset>
                 <legend className="legend-custom">Зарплатные ожидания, руб</legend>
                 <div className="flex-element form__inline">
                    <div className="form__inline-item">
                       <label className="visually-hidden" htmlFor="salary_from">От</label>
                       <Field
                          id="salary_from"
                          name="salary_from"
                          type="number"
                          placeholder="От"
                          component={FieldInput}
                       />
                    </div>
                    <div className="form__inline-item">
                       <label className="visually-hidden" htmlFor="salary_up">До</label>
                       <Field
                          id="salary_up"
                          name="salary_up"
                          type="number"
                          placeholder="До"
                          component={FieldInput}
                       />
                    </div>
                 </div>

              </fieldset>
           </div>

          <button className="button button-green-gradient button-bold button-small button-upper button-indent">Найти</button>
        </div>
      </form>
  )
};

export default reduxForm({
  form: 'filter'
})(FilterVacancies)

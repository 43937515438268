import React from "react";
import {Field, reduxForm} from "redux-form";
import {FieldInput} from "../../FormElement/Field/FieldInput";
import validate from "../../FormElement/validate";
import {changePersonalPassword} from "../../../actions/PersonalData";
import {connect} from "react-redux";

const FormSettings = ({handleSubmit}) => {
  return(
      <form className="form" onSubmit={e => handleSubmit(e)}>
        <fieldset>
          <legend>Сменить пароль</legend>

          <div className="form__item">
            <label htmlFor="old" className="form__label">Старый пароль</label>
            <Field
                component={FieldInput}
                id="old"
                name="old"
                type="password"
            />
          </div>

          <div className="form__item">
            <label htmlFor="pass" className="form__label">Новый пароль</label>
            <Field
                component={FieldInput}
                id="pass"
                name="pass"
                type="password"
            />
          </div>

          <div className="form__item">
            <label htmlFor="new_pass" className="form__label">Повторите пароль</label>
            <Field
                component={FieldInput}
                id="new_pass"
                name="new_pass"
                type="password"
            />
          </div>

          <button className="button button-green-gradient button-indent button-bold">Сохранить</button>
        </fieldset>
      </form>
  )
};


export default connect(null, {
  onSubmit: changePersonalPassword
})(reduxForm({
  form: 'changePassword',
  validate
})(FormSettings))

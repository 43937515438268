import { userConstants } from "../constants/userConstants";
import { personalDataConstants } from "../constants/personalDataConstats";

export function registration(state = {}, action) {
   switch (action.type) {
      case userConstants.REGISTER_SUCCESS:
         return { registering: true };
      case userConstants.REGISTER_FAILURE:
         return { registerFailed: true };
      default:
         return state;
   }
}

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
   switch (action.type) {
      case userConstants.LOGIN_SUCCESS:
         return {
            loggedIn: true,
            user: action.user,
         };
      case userConstants.LOGIN_FAILURE:
         return {};
      case userConstants.LOGOUT:
         return {};

      case userConstants.CHANGE_PASSWORD:
         return state;

      case personalDataConstants.CHANGE_PROFILE_SETTING:
      case personalDataConstants.CHANGE_SETTINGS:
         return {
            loggedIn: true,
            user: action.user,
         };

      default:
         return state;
   }
}

export function restore(state = {}, action) {
   switch (action.type) {
      case userConstants.RESTORE_PASSWORD_SUCCESS:
         return action.status;
      case userConstants.SAVE_NEW_PASSWORD_SUCCESS:
         return Object.assign({}, action.status, {
            reset: true,
         });
      default:
         return state;
   }
}

import React from 'react';

export function Box({title, text, icon}) {
  return(
    <div className="box-card">
      <div className="box-card__image flex-element flex-element--align-center"><img aria-hidden="true" alt="" src={icon} /></div>
      <div className="box-card__title title-bold">{title}</div>
      <div className="box-card__text">{text}</div>
    </div>
  )
}

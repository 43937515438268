import React from 'react';

export const HeaderIcon = () => {
   return (
      <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
         <path d="M20.49 6.18996L19.49 5.63996L10.49 0.639956H10.38C10.3187 0.614035 10.2551 0.593946 10.19 0.579956H10H9.82C9.75168 0.593955 9.68474 0.614035 9.62 0.639956H9.51L0.51 5.63996C0.356102 5.72717 0.228094 5.85365 0.139034 6.00649C0.0499749 6.15933 0.00305176 6.33306 0.00305176 6.50996C0.00305176 6.68685 0.0499749 6.86058 0.139034 7.01342C0.228094 7.16626 0.356102 7.29274 0.51 7.37996L3 8.75996V13.5C3 14.2956 3.31607 15.0587 3.87868 15.6213C4.44129 16.1839 5.20435 16.5 6 16.5H14C14.7956 16.5 15.5587 16.1839 16.1213 15.6213C16.6839 15.0587 17 14.2956 17 13.5V8.75996L19 7.63996V10.5C19 10.7652 19.1054 11.0195 19.2929 11.2071C19.4804 11.3946 19.7348 11.5 20 11.5C20.2652 11.5 20.5196 11.3946 20.7071 11.2071C20.8946 11.0195 21 10.7652 21 10.5V7.05996C20.9997 6.88276 20.9523 6.70883 20.8627 6.55596C20.7731 6.40309 20.6445 6.27678 20.49 6.18996ZM15 13.5C15 13.7652 14.8946 14.0195 14.7071 14.2071C14.5196 14.3946 14.2652 14.5 14 14.5H6C5.73478 14.5 5.48043 14.3946 5.29289 14.2071C5.10536 14.0195 5 13.7652 5 13.5V9.86996L9.51 12.37L9.66 12.43H9.75C9.833 12.4404 9.917 12.4404 10 12.43C10.083 12.4404 10.167 12.4404 10.25 12.43H10.34C10.3931 12.4188 10.4438 12.3985 10.49 12.37L15 9.86996V13.5ZM10 10.36L3.06 6.49996L10 2.63996L16.94 6.49996L10 10.36Z" fill="#ef801a" />
         <path d="M20.49 6.18996L19.49 5.63996L10.49 0.639956H10.38C10.3187 0.614035 10.2551 0.593946 10.19 0.579956H10H9.82C9.75168 0.593955 9.68474 0.614035 9.62 0.639956H9.51L0.51 5.63996C0.356102 5.72717 0.228094 5.85365 0.139034 6.00649C0.0499749 6.15933 0.00305176 6.33306 0.00305176 6.50996C0.00305176 6.68685 0.0499749 6.86058 0.139034 7.01342C0.228094 7.16626 0.356102 7.29274 0.51 7.37996L3 8.75996V13.5C3 14.2956 3.31607 15.0587 3.87868 15.6213C4.44129 16.1839 5.20435 16.5 6 16.5H14C14.7956 16.5 15.5587 16.1839 16.1213 15.6213C16.6839 15.0587 17 14.2956 17 13.5V8.75996L19 7.63996V10.5C19 10.7652 19.1054 11.0195 19.2929 11.2071C19.4804 11.3946 19.7348 11.5 20 11.5C20.2652 11.5 20.5196 11.3946 20.7071 11.2071C20.8946 11.0195 21 10.7652 21 10.5V7.05996C20.9997 6.88276 20.9523 6.70883 20.8627 6.55596C20.7731 6.40309 20.6445 6.27678 20.49 6.18996ZM15 13.5C15 13.7652 14.8946 14.0195 14.7071 14.2071C14.5196 14.3946 14.2652 14.5 14 14.5H6C5.73478 14.5 5.48043 14.3946 5.29289 14.2071C5.10536 14.0195 5 13.7652 5 13.5V9.86996L9.51 12.37L9.66 12.43H9.75C9.833 12.4404 9.917 12.4404 10 12.43C10.083 12.4404 10.167 12.4404 10.25 12.43H10.34C10.3931 12.4188 10.4438 12.3985 10.49 12.37L15 9.86996V13.5ZM10 10.36L3.06 6.49996L10 2.63996L16.94 6.49996L10 10.36Z" fill="#ff5f2d" />
      </svg>
   )
};

import React from "react";
import { status } from "../../../constants/status";
import { Link } from "react-router-dom";
import { Avatar } from "../../Avatar/Avatar";

export const CompaniesList = ({ companiesList, match }) =>
   companiesList.map((company) => {
      return (
         <Link
            to={`${match.url}/${company.id}`}
            className="card-preview flex-element"
            key={company.id}
         >
            <div className="card-preview__img card-preview__img--border">
               <Avatar photo={company.photo} alt={company.name} />
            </div>
            <div className="card-preview__content">
               <div className="card-preview__group flex-element">
                  <div className="card-preview__title title-inner-bold">
                     <div>{company.name}</div>
                     <span>• {company.city}</span>
                     {company.status === status.DISABLED && (
                        <span className="text-red blocked">• заблокирован</span>
                     )}
                  </div>
               </div>
               {company.description && (
                  <div className="card-preview__info">
                     {company.description.length > 250
                        ? company.description.slice(0, 250) + `.....`
                        : company.description}
                  </div>
               )}
               <div className="card-preview__foot">
                  <div className="badge badge--info">
                     Вакансии:{" "}
                     <span className="title-inner-bold">
                        {company.vacancy_count}
                     </span>
                  </div>
               </div>
            </div>
         </Link>
      );
   });

export const feedbackConstants = {
  FEEDBACK_INVITATION_DELETE: "FEEDBACK_INVITATION_DELETE",
  CHANGE_INVITE_SUCCESS: "CHANGE_INVITE_SUCCESS",
  FEEDBACK_INVITATION_REQUEST: "FEEDBACK_INVITATION_REQUEST", //post

  /*LIST*/
  FEEDBACK_INVITATION_LOADING: "FEEDBACK_INVITATION_LOADING",
  FEEDBACK_INVITATION_REQUEST_GET_SUCCESS: "FEEDBACK_INVITATION_REQUEST_GET_SUCCESS",
  FEEDBACK_INVITATION_REQUEST_GET_FAILURE: "FEEDBACK_INVITATION_REQUEST_GET_FAILURE",

  /* card invite */
  GET_INVITATION_INFO_SUCCESS: "GET_INVITATION_INFO_SUCCESS",
  GET_INVITATION_INFO_FAILURE: "GET_INVITATION_INFO_FAILURE",
  LOADING_INVITATION_INFO: 'LOADING_INVITATION_INFO',

  ADD_FAVORITES_SUCCESS: "ADD_FAVORITES_SUCCESS",
  IS_LOADING_FAVORITES: "IS_LOADING_FAVORITES",
  ADD_FAVORITES_FAILURE: "ADD_FAVORITES_FAILURE",
  GET_FAVORITES_SUCCESS: "GET_FAVORITES_SUCCESS",
  GET_FAVORITES_FAILURE: "GET_FAVORITES_FAILURE",
  FAVORITES_DELETE_SUCCESS: "FAVORITES_DELETE_SUCCESS"
};
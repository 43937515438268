import React from 'react';
import { Link } from "react-router-dom";
import {ButtonDelete} from "../../ButtonOptions/ButtonOptions";

export const SpecialtyList = ({ data, handleClickDelete, url }) => (
    data.map((specialty) => {
      return <tr key={specialty.id}>
        <td>{specialty.code}</td>
        <td>
          <Link to={`${url}/${specialty.id}/groups`}>{specialty.name}</Link>
        </td>
        <td>{specialty.infoCount.groups}</td>
        <td>{specialty.infoCount.students}</td>
        <td>
           <ButtonDelete onClick={() => handleClickDelete(specialty.id)} />
        </td>
      </tr>
    })
);

import React from 'react';
import {useSelector} from "react-redux";
import PersonalDataStudentFormPost from "./Form/PersonalDataStudentFormPost";
import {SubjectsContainer} from "../Subjecs/SubjectsContainer";
import {Helmet} from "react-helmet/es/Helmet";
import {useRouteMatch} from "react-router-dom";

export const NewPersonalDataStudent = () => {
  const {params} = useRouteMatch();
  const groupId  = params.groupsId;
  const user = useSelector(state => state.authentication.user);
  const studentInfo = useSelector(state => state.student);

  const initialsState = {
    groups_id: groupId,
    uid: user.university_local.uid
  };

  return (
      <>
      <Helmet>
          <title>Добавление нового студента</title>
      </Helmet>
        <h1 className="title-bold dark display-large">{params.groupsName}</h1>
        <div className="container-content">
          <div className="bg-card bg-card--inner card-personal">
            <PersonalDataStudentFormPost
                initialValues={initialsState} />
          </div>

          {Object.keys(studentInfo.student).length !== 0 &&
            <div className="bg-card block-card bg-card-mt">
              <div className="block-card__head flex-element flex-element--align-center">
                <h3 className='mb-none'>Оценки</h3>
                <p className="mb-none" >Заполните оценки по каждому курсу</p>
              </div>
              <div className="block-card__foot">
                  <SubjectsContainer studentId={studentInfo.student.id}/>
              </div>
            </div>
          }
        </div>
      </>
  )
};

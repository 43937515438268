import React from 'react';
import {Link} from "react-router-dom";
import {Keywords} from "../../Keywords/Keywords";
import {Avatar} from "../../Avatar/Avatar";
import {BookIcon} from "../../Icons/Book";
import {HeaderIcon} from "../../Icons/Header";

export const MainResumeList = ({resumeList, match}) => {
  return (
      resumeList.filter(status => status.status === 10).map(resume => {
        return <Link to={`${match.url}/${resume.id}`} target="e_target" className="card-preview flex-element" key={resume.id}>
          <div className="card-preview__img card-preview__img--custom">
            <Avatar photo={resume.user.photo} alt={resume.post} />
          </div>
          <div className="card-preview__content">
            <div className="card-preview__group flex-element">
              <div className="card-preview__title card-preview__title--primary title-inter-medium"><div>{resume.post}</div> <span> {`${resume.user.surname} ${resume.user.username}`}</span></div>
            </div>
            {resume.user.student &&
                <div className="card-preview__info">
                  <ul className="flex-element card-preview__list">
                    <li>
                      <HeaderIcon />
                      <span>{resume.user.student.details.university_abbreviation} , {resume.user.student.details.speciality_name}, <b>{resume.user.student.course}</b> курс</span>
                    </li>

                    <li>
                      <BookIcon />
                      <span>Средний балл: <b>{resume.user.student.details.average_ball}</b></span>
                    </li>
                  </ul>
                </div>
            }
            <div className="card-preview__foot flex-element flex-element--align-center">
              <span className="salary title-semi-bold">{resume.salary}</span>
              <div className="group">
                {resume.keywords ? <Keywords keywords={resume.keywords.split(", ")} classBadge="badge--secondary" /> : null }
              </div>
            </div>
          </div>
        </Link>
      })
  )
};

import API from "../helpers/api";
import {toast} from "react-toastify";
import {feedbackConstants} from '../constants/feedbackConstants'
import {getResumeMedia} from "./UploadPhoto";

export function invitationRequestSuccess(invite) {
  return {
    type: feedbackConstants.FEEDBACK_INVITATION_REQUEST,
    invite
  };
}

export function invitationRequest(values) {
  return (dispatch) => {
    return API.post(`/invitations`, values)
      .then(invite => {
        dispatch(invitationRequestSuccess(invite.data.model));
        toast.success("Отправлено");
      })
      .catch(error => {
        console.log(error)
      })
  }
}

/* students/invitation */
export function invitationRequestGetSuccess(invite) {
  return {
    type: feedbackConstants.FEEDBACK_INVITATION_REQUEST_GET_SUCCESS,
    invite
  };
}

export function invitationRequestGetFailure(error) {
  return {
    type: feedbackConstants.FEEDBACK_INVITATION_REQUEST_GET_FAILURE,
    error
  };
}

export function invitationRequestLoading(isLoading) {
  return {
    type: feedbackConstants.FEEDBACK_INVITATION_LOADING,
    isLoading
  };
}

export function invitationRequestGet(params) {
  return (dispatch) => {
    dispatch(invitationRequestLoading(true));
    return API.get(`/invitations/search`, {params})
      .then(invite => {
        dispatch(invitationRequestGetSuccess(invite.data.items));
      })
      .catch(error => {
        dispatch(invitationRequestGetFailure(error))
      })
  }
}


/* delete */
export function invitationDeleteSuccess(invite) {
  return {
    type: feedbackConstants.FEEDBACK_INVITATION_DELETE,
    invite
  };
}

export function invitationDelete(id) {
  return (dispatch) => {
    return API.delete(`/invitations/${id}`)
      .then(invite => {
        dispatch(invitationDeleteSuccess(invite.data.id));
        toast.success('Удаление');
      })
      .catch(error => {
        console.log(error);
      })
  }
}

/* change */
export function changeInvitationSuccess(invite) {
  return {
    type: feedbackConstants.CHANGE_INVITE_SUCCESS,
    invite
  };
}

export function invitationPatch(id, values) {
  return (dispatch) => {
    return API.patch(`/invitations/${id}`, values)
      .then(invite => {
        dispatch(changeInvitationSuccess(invite.data));
        toast.success(values.title);
      })
      .catch(error => {
        console.log(error)
      })
  }
}


/* CARD */

/* students/my-feedback/card */
export function getInvitationInfoEmployerSuccess(inviteInfo, userInfo) {
  return {
    type: feedbackConstants.GET_INVITATION_INFO_SUCCESS,
    inviteInfo,
    userInfo
  };
}

export function getInvitationInfoFailure(error) {
  return {
    type: feedbackConstants.GET_INVITATION_INFO_FAILURE,
    error
  };
}

export function LoadingInvitationInfo(isLoading) {
  return {
    type: feedbackConstants.LOADING_INVITATION_INFO,
    isLoading
  };
}

export function getInvitationInfoEmployer(id) {
  return async dispatch => {
    dispatch(LoadingInvitationInfo(true));
    try {
      const inviteInfo = await API.get(`/invitations/search?id=${id}`);
      const userInfo = await API.get(`/user/${inviteInfo.data.items[0].from_uid}`);
      dispatch(getInvitationInfoEmployerSuccess(inviteInfo.data.items[0], userInfo.data));
      dispatch(getResumeMedia(
          {
             link: "hirer-portfolio",
             title: "portfolio"
          },
          {uid: inviteInfo.data.items[0].from_uid}
       ));
    } catch (error) {
      dispatch(getInvitationInfoFailure(error))
    }
  }
}

/* employer invite*/
export function getInvitationInfoSuccess(inviteInfo) {
  return {
    type: feedbackConstants.GET_INVITATION_INFO_SUCCESS,
    inviteInfo,
  };
}

export function getInvitationInfoStudent(id) {
  return async dispatch => {
    try {
      const inviteInfo = await API.get(`/invitations/search?id=${id}`);
      dispatch(getInvitationInfoSuccess(inviteInfo.data.items[0]));

      dispatch(getResumeMedia(
         {
            link: "user-portfolio",
            title: "portfolio"
         },
         {resume_id: inviteInfo.data.items[0].resume_data.id}
      ));

      dispatch(getResumeMedia(
       {
          link: "user-documents",
          title: "documents"
       },
       {resume_id: inviteInfo.data.items[0].resume_data.id}
      ));
    } catch (error) {
      dispatch(getInvitationInfoFailure(error))
    }
  }
}


import React from "react";
import {Field, reduxForm} from "redux-form";
import validate from "../../FormElement/validate";
import {FieldInput} from "../../FormElement/Field/FieldInput";
import {FieldSelect} from "../../FormElement/Field/FieldSelect";
import {FieldDaData} from "../../FormElement/Field/FieldDaData";
import {FieldImage} from "../../FormElement/Field/FieldImage";
import FieldTextarea from "../../FormElement/Field/FieldTextarea";
import {FieldContainerThreeSelect} from "../../FormElement/Field/FieldContainerThreeSelect";

const companyType = [
  { value: 'ООО', label: 'ООО' },
  { value: 'ИП', label: 'ИП' },
];

const OrganizationDataForm = ({handleSubmit, data}) => {
  return (
      <form className="form flex-element" onSubmit={e => handleSubmit(e)}>
        <label htmlFor="avatar" className="visually-hidden">Аватар</label>
        <Field
            id="avatar"
            name="photo"
            type="file"
            component={FieldImage}
        />

        <fieldset>
          <legend>Общая информация</legend>

          <div className="form__item">
            <label className="form__label" htmlFor="company_type">Тип организации </label>

            <Field
                id='company_type'
                name="company_type"
                placeholder="Выберите"
                options={companyType}
                component={FieldSelect}
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="name">Наименование организации</label>
            <Field
                component={FieldInput}
                id="name"
                name="name"
                type="text"
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="start_year">Год основания</label>
            <Field
                component={FieldInput}
                id="start_year"
                name="start_year"
                type="number"
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="city">Город</label>
            <Field
                component={FieldDaData}
                id="city"
                name="city"
                type="text"
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="main_address">Основной адрес</label>
            <Field
                component={FieldDaData}
                id="main_address"
                name="main_address"
                type="text"
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="legal_address">Юридический адрес</label>
            <Field
                component={FieldInput}
                id="legal_address"
                name="legal_address"
                type="text"
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="site">Сайт</label>
            <Field
                component={FieldInput}
                id="site"
                name="site"
                type="text"
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="category">Отрасль компании</label>

            <Field
                id='category'
                name="category"
                data={data}
                placeholder="Введите"
                component={FieldContainerThreeSelect}
            />
          </div>


          <div className="form__item form__item--width">
            <label className="form__label" htmlFor="description">Описание</label>
            <Field
                component={FieldTextarea}
                id="description"
                name="description"
                type="text"
                className="form-control-textarea"
            />
          </div>

          <button className="button button-green-gradient button-indent button-bold button-save">Сохранить</button>
        </fieldset>
      </form>
  )
};



export default reduxForm({
  form: 'settings-employer',
  validate,
  enableReinitialize: true,
})(OrganizationDataForm)

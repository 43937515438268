import React from "react";
import { Field, reduxForm } from "redux-form";
import { FieldInput } from "../FormElement/Field/FieldInput";
import validate from "../FormElement/validate";
import { UploadFile } from "../FormElement/Field/UploadDile";

function UploadFileAssessments({ handleSubmit }) {
   return (
      <form className="form form--upload" onSubmit={(e) => handleSubmit(e)}>
         <div className="upload">
            <label htmlFor="file" aria-label="Загрузить из файла">
               <Field
                  id="file"
                  name="file"
                  type="file"
                  accept=".xlsx"
                  component={UploadFile}
               />
            </label>
         </div>

         <div className="flex-element form__group">
            <div className="item">
               <label htmlFor="ticket_start">
                  Введите номер ячейки с зачетной книжкой
               </label>
               <Field
                  name="ticket_start"
                  id="ticket_start"
                  component={FieldInput}
                  type="text"
                  placeholder="например, A3"
               />
            </div>

            <div className="item">
               <label htmlFor="name_start">
                  Введите номер ячейки с предметом
               </label>
               <Field
                  name="name_start"
                  id="name_start"
                  component={FieldInput}
                  type="text"
                  placeholder="например, B3"
               />
            </div>

            <div className="item">
               <label htmlFor="mark_start">
                  Введите номер ячейки с оценкой
               </label>
               <Field
                  name="mark_start"
                  id="mark_start"
                  component={FieldInput}
                  type="text"
                  placeholder="например, C3"
               />
            </div>
         </div>

         <button className="button button-gradient-primary button-indent button-submit">
            Сохранить
         </button>
      </form>
   );
}

export default reduxForm({
   form: "file",
   validate,
})(UploadFileAssessments);

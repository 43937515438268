import React from 'react';
import {Field, reduxForm} from "redux-form";
import validate from "../FormElement/validate";
import FieldTextarea from "../FormElement/Field/FieldTextarea";
import {FieldSelect} from "../FormElement/Field/FieldSelect";

const InvitationForm = ({handleSubmit, vacancy}) => {
  const vacancyList = vacancy.map(item => {
    return {
      label: item.name,
      value: item.id
    }
  });

  return (
      <form className="form" onSubmit={e => handleSubmit(e)}>
        <div className="form__item">
          <label htmlFor="vacancy_id" className="form__label">Выберите вакансию</label>
          <Field
              id="vacancy_id"
              name="vacancy_id"
              type="text"
              placeholder="Выберите вакансию"
              options={vacancyList}
              component={FieldSelect} />
        </div>
        <div className="form__item form__item--bg">
          <label htmlFor="message" className="form__label">Ваше сообщение</label>
          <Field
              id="message"
              name="message"
              type="text"
              placeholder="Введите сообщение"
              className="height-textarea"
              component={FieldTextarea} />
        </div>

        <button className="button button-green-gradient button-upper button-small button-bold button-submit">Отправить приглашение</button>
      </form>
  )
};

export default reduxForm({
  form: 'invitation',
  validate
})(InvitationForm)

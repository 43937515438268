import API from "../helpers/api";
import {studentsConstants} from "../constants/resumeConstats";

/* gallery for employer and university */
export function gallery(file, toSend) {
  const formData = new FormData();
  formData.append('file', file);

  return (dispatch) => {
    return API.post(`/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then(res => {
        if (toSend) {
          dispatch(postMedia(
             {
                link: "university-portfolio",
                title: "portfolio"
             },
             {university_id: toSend, path: res.data[0]}
          ));
        } else {
           dispatch(postMedia(
              {
                 link: "hirer-portfolio",
                 title: "portfolio"
              },
              {path: res.data[0]}
           ))
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}

/* ----------------------------------- */

/* загрузка фотографий и документов */
export function mediaResumes(file) {
   const formData = new FormData();
   formData.append('file', file);

   return (dispatch) => {
      dispatch({type: 'IS_LOADING_MEDIA', isLoading: true});
      return new Promise((resolve, reject) => {
         API.post(`/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((files) => {
               resolve({
                  name: file.name,
                  path: files.data[0],
                  size: String(file.size)
               });
               dispatch({type: 'IS_LOADING_MEDIA', isLoading: false});
            }).catch((error) => {
            console.log(error, 'ошибка');
            reject();
         });
      });
   }
}

export function postDocumentsResumesSuccess(file) {
   return {
      type: studentsConstants.POST_DOCUMENTS,
      file
   }
}

export function postPortfolioResumesSuccess(file) {
   return {
      type: studentsConstants.POST_PORTFOLIO,
      file
   }
}

export function postMedia(type, params) {
   return (dispatch) => {
      return API.post(`${type.link}`, {...params})
         .then(file => {
            if (type.title === 'portfolio') {
               dispatch(postPortfolioResumesSuccess(file.data.model));
            } else {
               dispatch(postDocumentsResumesSuccess(file.data.model));
            }
         })
         .catch(error => {
            console.log(error);
         })
   }
}


/* Получение файлов */
export function getPortfolioSuccess(files) {
   return {
      type: studentsConstants.GET_PORTFOLIO_SUCCESS,
      files,
   };
}

export function getDocumentsSuccess(files) {
   return {
      type: studentsConstants.GET_DOCUMENTS_SUCCESS,
      files,
   };
}

export function getPortfolioFailure(error) {
   return {
      type: studentsConstants.GET_PORTFOLIO_FAILURE,
      error,
   };
}

export function getDocumentsFailure(error) {
   return {
      type: studentsConstants.GET_DOCUMENTS_FAILURE,
      error,
   };
}

export function getResumeMedia(type, params) {
   return dispatch => {
      dispatch({type: 'IS_LOADING_MEDIA', isLoading: true});
      return API.get(`${type.link}/search`, {params})
         .then(res => {
            if (type.title === 'portfolio') {
               dispatch(getPortfolioSuccess(res.data.items))
            } else {
               dispatch(getDocumentsSuccess(res.data.items))
            }
         })
         .catch(error => {
            if (type.title === 'portfolio') {
               dispatch(getPortfolioFailure(error))
            } else {
               dispatch(getDocumentsFailure(error))
            }
         })

   }
}


/* del photo */
function deletePortfolioSuccess(file) {
   return {
      type: studentsConstants.DELETE_PORTFOLIO,
      file
   }
}

function deleteDocumentsSuccess(file) {
   return {
      type: studentsConstants.DELETE_DOCUMENTS,
      file
   }
}

export function deleteMedia(type) {
   return (dispatch) => {
      return API.delete(`${type.link}/${type.id}`)
         .then(file => {
            if (type.title === 'portfolio') {
               dispatch(deletePortfolioSuccess(file.data.id))
            } else {
               dispatch(deleteDocumentsSuccess(file.data.id))
            }
         })
         .catch(error => {
            console.log(error)
         })
   }
}

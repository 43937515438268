import API from "../helpers/api";
import {changeSettingStudentSuccess} from "./PersonalData";
import {toast} from "react-toastify";

export function getUser(id) {
   return (dispatch) => {
      return API.get(`/user/${id}`)
         .then(user => {
            localStorage.setItem('user', JSON.stringify(user.data));
            dispatch(changeSettingStudentSuccess(user.data));
            toast.success("Данные сохранены");
         })
         .catch(error => {
            console.log(error);
         })
   }
}

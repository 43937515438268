import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {invitationRequestGet, invitationDelete, invitationPatch} from "../../actions/FeedbackAction";
import {InvitationsList} from "./InvitationsComponentEmployer/InvitationsList";
import {Preloader} from "../Preloader/Preloader";
import {Helmet} from "react-helmet/es/Helmet";
import {TabsContent} from "../Tabs/TabsContent";

export const InvitationsEmployer = ({match}) => {
    const url = match.url;

    const user = useSelector(state => state.authentication);
    const feedback = useSelector(state => state.feedback);
    const dispatch = useDispatch();

    useEffect(() => {
        const userId = user.user.id;
        dispatch(invitationRequestGet({
           from_uid: userId
        }))
    },[dispatch, user.user.id]);

    const handleClickReject = (id) => {
        const value =  {status: 5, title: 'Вакансия отклонена'};
        dispatch(invitationPatch(id, value))
    };

    const handleClickDelete = (id) => {
        dispatch(invitationDelete(id))
    };

    const InviteListAll = feedback.invite.filter(status => status.vacancy_data.status !== 50);
    const InviteListReject = feedback.invite.filter(status => status.status === 5 || status.status === -1);
    const InviteListAccept = feedback.invite.filter(status => status.status === 10 || status.status === -2);

   const tabs = [
      {
         label: 'Мои приглашения',
         components: <InvitationsContent
            list={InviteListAll}
            text="Приглашения отсутствуют"
            url={url}
            handleClickReject={handleClickReject}
            handleClickDelete={handleClickDelete}
         />
      },
      {
         label: 'Принятые',
         components: <InvitationsContent
            list={InviteListAccept}
            text="Принятых предложений нет"
            url={url}
            handleClickReject={handleClickReject}
            handleClickDelete={handleClickDelete}
         />
      },
      {
         label: 'Отклоненные',
         components: <InvitationsContent
            list={InviteListReject}
            text="Отклоненных нет"
            url={url}
            handleClickReject={handleClickReject}
            handleClickDelete={handleClickDelete}
         />
      }
   ];

   return (
        <>
            <Helmet>
                <title>Мои приглашения</title>
            </Helmet>

            {feedback.isLoading ? <Preloader/> :
                <div className="bg-card bg-card--inner">
                    <TabsContent tabs={tabs} />
                </div>
            }
        </>

    )
};

const InvitationsContent = ({list, text, url, handleClickReject, handleClickDelete}) => (
   list.length === 0 ?
      <p className="mb-none txt-info">{text}</p>
      :
      <div className="responsive-custom table-responsive">
         <table
            className="table table--shadow-none table--upper table-small-size table--head-transparent">
            <thead>
            <tr>
               <th scope="col">Дата</th>
               <th scope="col">Вакансия</th>
               <th scope="col">Кандидат</th>
               <th scope="col">Статус</th>
               <th scope="col">Действие</th>
            </tr>
            </thead>
            <tbody>
            <InvitationsList
               data={list}
               url={url}
               handleClickReject={handleClickReject}
               handleClickDelete={handleClickDelete}
            />
            </tbody>
         </table>
      </div>
);


import { homeConstants } from '../constants/homeConstants';
import API from "../helpers/api";

//универы
export function getUniversitiesSuccess(universities, pagination) {
  return {
    type: homeConstants.GET_LIST_UNIVERSITIES_SUCCESS,
    universities,
    pagination
  };
}

export function getUniversitiesFailure(error) {
  return {
    type: homeConstants.GET_LIST_UNIVERSITIES_FAILURE,
    error
  };
}

export function isLoadingUniversities(isLoading) {
  return {
    type: homeConstants.IS_LOADING_UNIVERSITIES,
    isLoading
  };
}


export function getUniversities() {
  return (dispatch) => {
    dispatch(isLoadingUniversities(true));
    return API.get(`/university`)
      .then(universities => {
        dispatch(getUniversitiesSuccess(universities.data.items, universities.data._meta))
      })
      .catch(error => {
        console.log(error);
        dispatch(getUniversitiesFailure())
      })
  }
}

//универ
export function getUniversityInfoSuccess(university) {
  return {
    type: homeConstants.GET_UNIVERSITY_INFO,
    university
  };
}

export function isLoadingUniversity(isLoading) {
  return {
    type: homeConstants.IS_LOADING_UNIVERSITY_INFO,
    isLoading
  };
}


export function getUniversityInfo(id) {
  return (dispatch) => {
    dispatch(isLoadingUniversity(true));
    return API.get(`/university/${id}`)
      .then(university => {
        dispatch(getUniversityInfoSuccess(university.data))
      })
      .catch(error => {
        console.log(error)
      })
  }
}

/* поиск по студентом */
export function filteredStudentListSuccess(students) {
  return {
    type: homeConstants.GET_LIST_STUDENTS_SEARCH_SUCCESS,
    students
  };
}

export function filteredStudentListFailure(error) {
  return {
    type: homeConstants.GET_LIST_STUDENTS_SEARCH_FAILURE,
    error
  };
}

export function filteredStudentList(values) {
  return (dispatch) => {
    return API.get(`/search/students`,{params:values})
      .then(answer => {
        dispatch(filteredStudentListSuccess(answer.data.items))
      })
      .catch(error => {
        console.log(error);
        dispatch(filteredStudentListFailure(error))
      })
  }
}

// список городов для универов
export function getCitiesSuccess(cities) {
  return {
    type: homeConstants.GET_CITIES_SUCCESS,
    cities
  };
}

export function getCitiesFailure(error) {
  return {
    type: homeConstants.GET_CITIES_FAILURE,
    error
  };
}

export function getCities() {
  return (dispatch) => {
    return API.get(`/university/city`)
        .then(city => {
          dispatch(getCitiesSuccess(city.data))
        })
        .catch(error => {
          console.log(error);
          dispatch(getCitiesFailure(error))
        })
  }
}


export function getUniversitiesFilterSuccess(universities) {
  return {
    type: homeConstants.GET_LIST_UNIVERSITIES_FILTER_SUCCESS,
    universities
  };
}

export function getUniversitiesFilterFailure(error) {
  return {
    type: homeConstants.GET_LIST_UNIVERSITIES_FILTER_FAILURE,
    error
  };
}


export function getUniversitiesByFilterCity(city) {
  return (dispatch) => {
    return API.get(`/university/find-by-city?city=${city}`)
      .then(university => {
        dispatch(getUniversitiesFilterSuccess(university.data));
      })
      .catch(error => {
        dispatch(getUniversitiesFilterFailure(error));
      })
  }
}

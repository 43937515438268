import { articleConstants } from "../constants/articleConstants";

const stateThemes = {
   isLoading: false,
   themes: [],
};

export function themes(state = stateThemes, action) {
   switch (action.type) {
      case articleConstants.IS_LOADING_THEMES:
         return {
            ...state,
            isLoading: action.isLoading,
         };
      case articleConstants.GET_THEMES_SUCCESS:
         return {
            ...state,
            themes: action.themes,
            isLoading: false,
         };
      case articleConstants.GET_THEMES_FAILURE:
         return {
            ...state,
            themes: [],
            isLoading: false,
         };
      default:
         return state;
   }
}

const stateArticle = {
   article: {},
};

export function article(state = stateArticle, action) {
   switch (action.type) {
      case articleConstants.GET_ARTICLE_SUCCESS:
         return {
            ...state,
            article: action.article,
         };

      default:
         return state;
   }
}

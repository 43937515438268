import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import FormEmployer from "./OrganizationDataForm";
import {changeSettingEmployer, photoChange} from '../../../actions/PersonalData'
import {getFilter} from "../../../reducers/ClassifiersRedicer";
import {mapArrayToString} from "../../../helpers/conversion";

export const OrganizationData = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);
  const classifier = useSelector(state => state.filterClassifier.filterOrganization);

  const idHirer = user.hirer_local.id;
  const hirerInfo = user.hirer_local;

  let companyIndustry;
  if (hirerInfo) {
     companyIndustry = hirerInfo.category;
  }

  useEffect(() => {
     dispatch(getFilter('industry', 'organization', companyIndustry))
  },[companyIndustry, dispatch]);

  const handleSubmit = (values) => {
    const newObject = Object.assign({}, values);
    mapArrayToString(newObject, ['category']);
    newObject.company_type = values.company_type.label;

    if (values.photo && typeof values.photo === 'object') {
      const promise = dispatch(photoChange(values.photo[0]));

      promise.then((newPhoto) => {
        newObject.photo = newPhoto;
        dispatch(changeSettingEmployer(idHirer, newObject))
      })
    } else {
      dispatch(changeSettingEmployer(idHirer, newObject))
    }
  };


  let  initialValues = {
    ...hirerInfo,
    company_type: {value: hirerInfo.company_type, label: hirerInfo.company_type},
  };

  return (
     <div className="flex-element">
       <FormEmployer
           data={classifier}
           initialValues={initialValues}
           onSubmit={(values) => {
             handleSubmit(values)
           }}
       />
     </div>
  )
};

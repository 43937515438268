import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {invitationRequestGet, invitationPatch} from "../../../actions/FeedbackAction";
import {InvitationsList} from "../InvitationsComponentStudent/InvitationsList";
import {Preloader} from "../../Preloader/Preloader";
import {Helmet} from "react-helmet/es/Helmet";
import {TabsContent} from "../../Tabs/TabsContent";

export const GetInvitationsContainer = ({match}) => {
   const dispatch = useDispatch();
   const url = match.url;
   const user = useSelector(state => state.authentication);
   const feedback = useSelector(state => state.feedback);

   useEffect(() => {
      const userId = user.user.id;
      dispatch(invitationRequestGet({
         to_uid: userId
      }))
   }, [dispatch, user.user.id]);


   const handleClickRejectInvitations = (id) => {
      const value = {status: 5, title: 'Вакансия отклонена'};
      dispatch(invitationPatch(id, value))
   };

   const handleClickDeleteInvitationsReject = (id) => {
      const value = {status: -1, title: 'Вакансия удалена с вашего списка'};
      /*status - 1: удаление у студента; роботодатель значение отказ*/
      dispatch(invitationPatch(id, value))
   };

   const handleClickDeleteInvitationsAccept = (id) => {
      /*status - 2: удаление у студента; роботодатель значение принято*/
      const value = {status: -2, title: 'Вакансия удалена с вашего списка'};
      dispatch(invitationPatch(id, value))
   };


   const handleClickAcceptInvitations = (id) => {
      const value = {status: 10, title: 'Вы приняли вакансию'};
      dispatch(invitationPatch(id, value))
   };

   const InviteListReject = feedback.invite.filter(status => status.status === 5);
   const InviteListArchive = feedback.invite.filter(status => status.vacancy_data.status === 50);
   const InviteListOffer = feedback.invite.filter(status => status.status === 10);
   const InviteListAll = feedback.invite.filter(status => status.status === 5 || status.status === 10 || status.status === 0);


   const tabs = [
      {
         label: 'Все приглашения',
         components: <InvitationsContent
            list={InviteListAll}
            text="У вас нет приглашений"
            url={url}
            handleClickRejectInvitations={handleClickRejectInvitations}
            handleClickDeleteInvitationsReject={handleClickDeleteInvitationsReject}
            handleClickDeleteInvitationsAccept={handleClickDeleteInvitationsAccept}
            handleClickAcceptInvitations={handleClickAcceptInvitations}

         />
      },
      {
         label: 'Принятые',
         components: <InvitationsContent
            list={InviteListOffer}
            text="Принятых приглашений нет"
            url={url}
            handleClickRejectInvitations={handleClickRejectInvitations}
            handleClickDeleteInvitationsReject={handleClickDeleteInvitationsReject}
            handleClickDeleteInvitationsAccept={handleClickDeleteInvitationsAccept}
            handleClickAcceptInvitations={handleClickAcceptInvitations}

         />
      },
      {
         label: 'Отклоненные',
         components: <InvitationsContent
            list={InviteListReject}
            text="Отклоненных приглашений нет"
            url={url}
            handleClickRejectInvitations={handleClickRejectInvitations}
            handleClickDeleteInvitationsReject={handleClickDeleteInvitationsReject}
            handleClickDeleteInvitationsAccept={handleClickDeleteInvitationsAccept}
            handleClickAcceptInvitations={handleClickAcceptInvitations}

         />
      },
      {
         label: 'Архивные приглашения',
         components: <InvitationsContent
            list={InviteListArchive}
            text="Нет архивных приглашений"
            url={url}
            handleClickRejectInvitations={handleClickRejectInvitations}
            handleClickDeleteInvitationsReject={handleClickDeleteInvitationsReject}
            handleClickDeleteInvitationsAccept={handleClickDeleteInvitationsAccept}
            handleClickAcceptInvitations={handleClickAcceptInvitations}

         />
      }
   ];


   return (
      <>
         <Helmet>
            <title>Приглашения</title>
         </Helmet>
         {
            feedback.isLoading ? <Preloader/> :
               <>
                  <div className="bg-card bg-card--inner">
                     <TabsContent tabs={tabs} />
                  </div>
               </>
         }
      </>
   )
};

const InvitationsContent = ({list, text, url, handleClickRejectInvitations, handleClickAcceptInvitations, handleClickDeleteInvitationsAccept, handleClickDeleteInvitationsReject}) => (
   list.length === 0 ?
      <p className="mb-none">{text}</p>
      :
      <div className="table-responsive">
         <table
            className="table table--shadow-none table--upper table-small-size table--head-transparent">
            <thead>
            <tr>
               <th scope="col">Дата</th>
               <th scope="col">Резюме</th>
               <th scope="col">Вакансия</th>
               <th scope="col">Статус</th>
               <th scope="col">Действие</th>
            </tr>
            </thead>
            <tbody>
            <InvitationsList data={list} url={url}
                             handleClickRejectInvitations={handleClickRejectInvitations}
                             handleClickAcceptInvitations={handleClickAcceptInvitations}
                             handleClickDeleteInvitationsAccept={handleClickDeleteInvitationsAccept}
                             handleClickDeleteInvitationsReject={handleClickDeleteInvitationsReject}
            />
            </tbody>
         </table>
      </div>
);

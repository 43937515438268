import React from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from "@material-ui/core/Tab";
import {withStyles} from "@material-ui/core";

export const AntTabs = withStyles(theme => ({
    root: {
        borderBottom: '1px solid #d0d9dc',
        minHeight: 'auto'
    },
    indicator: {
        backgroundColor: '#2c71f6',
    },
    scroller: {
       [theme.breakpoints.down('md')]: {
          overflow: 'scroll!important'
       }
    },
}))(Tabs);


export const AntTab = withStyles(theme => ({
    root: {
        color: '#6b7686',
        textDecoration: 'none',
        textTransform: 'none',
        fontSize: '16px',
        letterSpacing: 'normal',
        padding: '0 5px 10px 5px',
        minHeight: 'auto',
        opacity: 1,
        fontFamily: [
            'Inter-Medium'
        ],
        '&:hover': {
            color: "#286ef5",
        },
        '&$selected': {
            color: '#286ef5',
        },
        '&:focus': {
            color: '#286ef5',
            outline: 'none'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            minWidth: 'auto',
            marginRight: '20px'
        },
    },
    selected: {},
    '@global': {
        '.MuiBox-root': {
            padding: '22px 0 0 0',
        },
    },
}))(props => <Tab disableRipple {...props} />);

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { getUniversities } from '../../actions/HomeAction';
import {UniversitiesList} from './UniversitesList';
import {Pagination} from "../Pagination/Pagination";
import {getListResume} from "../../actions/ResumeAction";
import {Preloader} from "../Preloader/Preloader";

export const UniversitiesContainer = ({match}) => {
  const dispatch = useDispatch();
  const universities = useSelector(state => state.universities);

  useEffect(() => {
    dispatch(getUniversities());
  }, [dispatch]);

  const onSetPage = (page) => {
    dispatch(getListResume(page))
  };

  return (
      universities.isLoading ? <Preloader/> :
      <>
        <h1 className="dark title-semi-bold title-bold display-large">Университеты</h1>
        {
           universities.universities.length === 0 ?
           <p>Ничего не найдено</p> :

           <div className="table-responsive">
              <table className="table table--head-transparent table--shadow-none table--spacing">
                 <thead>
                 <tr>
                    <th scope="col">Название</th>
                    <th scope="col">Студентов</th>
                 </tr>
                 </thead>
                 <tbody>
                 <UniversitiesList data={universities.universities} url={match.url} />
                 </tbody>
              </table>
           </div>
        }

        <Pagination
            pageCount={universities.pagination.pageCount}
            currentPage={universities.pagination.currentPage}
            onSetPage={onSetPage}
        />
      </>
  )
};

import React from 'react';
import bg from '../../../assets/img/bg.jpg';
import {Box} from './box';
import BoxImageStudent from '../../../assets/img/box/1.png';
import BoxImageEmployer from '../../../assets/img/box/2.png';
import BoxImageUniversity from '../../../assets/img/box/3.png';

export function InformationForPortal() {
  return(
    <section className="section-white section-page">
      <div className="container">
        <h2 className="big-size dark title-semi-bold">
          Для кого этот портал?
        </h2>
        <div className="flex-element flex-element--options box-wrap">
          <figure>
            <img src={bg} alt=""/>
            <figcaption>Помогаем студентам найти работу</figcaption>
          </figure>

          <div className="box-card-wrap flex-element flex-element--justify-sb">
            <Box title="Для студентов" icon={BoxImageStudent} text="Позволяет студентам быстро найти постоянную/временную работу по специальности, а также места прохождения учебно-производственной практики. К системе подключены как ключевые региональные и национальные предприятия, так и перспективные start-upы." />
            <Box title="Для предприятий" icon={BoxImageEmployer} text="Дает возможность удовлетворить спрос на молодых специалистов за счет доступа к национальной базе студентов и обучающихся" />
            <Box title="Для университетов" icon={BoxImageUniversity} text="Позволяет трудоустроить студентов, повысить эффективность коммуникаций с работодателями, сформировать новые точки интеграции образовательных программ с бизнес-потребностями, а также наладить контакт по вопросу хоздоговорных отношений."/>
          </div>
        </div>
      </div>
    </section>
  )
}

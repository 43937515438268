import { studentsConstants } from "../constants/resumeConstats";

const initialState = {
   resume: [],
   isLoading: false,
   pagination: {
      pageCount: 0,
      currentPage: 0,
   },
};

export function resume(state = initialState, action) {
   switch (action.type) {
      case studentsConstants.ADD_RESUME:
         return {
            ...state,
            resume: [...state.resume, action.resume],
         };

      case studentsConstants.IS_LOADING_RESUMES:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case studentsConstants.GET_RESUME_SUCCESS:
         return {
            ...state,
            resume: action.resume,
            isLoading: false,
            pagination: action.pagination || state.pagination,
         };

      case studentsConstants.GET_LIST_RESUME_SEARCH_SUCCESS:
         return {
            ...state,
            resume: action.resume,
            isLoading: false,
         };

      case studentsConstants.GET_RESUME_FAILURE:
      case studentsConstants.GET_LIST_RESUME_SEARCH_FAILURE:
         return {
            ...state,
            resume: [],
            isLoading: false,
         };

      case studentsConstants.DELETE_RESUME_SUCCESS:
         return {
            ...state,
            resume: state.resume.filter((id) => id.id !== action.resume),
         };

      case studentsConstants.CHANGE_RESUME_STATUS_SUCCESS:
         return {
            ...state,
            resume: state.resume.map((item) => {
               if (item.id === action.resume.id) {
                  return {
                     ...item,
                     status: action.resume.status,
                  };
               }
               return item;
            }),
         };

      default:
         return state;
   }
}

const initialStateInfoResume = {
   resumeInfo: null,
   isLoading: false,
};

export function resumesInfo(state = initialStateInfoResume, action) {
   switch (action.type) {
      case studentsConstants.GET_INFO_RESUME_SUCCESS:
         return {
            ...state,
            resumeInfo: action.resumeInfo,
            isLoading: false,
         };

      case studentsConstants.GET_INFO_RESUME_FAILURE:
         return {
            ...state,
            resumeInfo: null,
            isLoading: false,
         };

      case studentsConstants.IS_LOADING_RESUME_INFO:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case studentsConstants.CHANGE_RESUME_SUCCESS:
         return {
            ...state,
            resumeInfo: action.resumeInfo,
         };

      default:
         return state;
   }
}

import {studentsConstants} from "../../constants/studentsConstatns";
import API from "../../helpers/api";
import {toast} from "react-toastify";
import {SubmissionError} from "redux-form";
import {isLoadingGroups} from "./GroupsAction";

/* Кабинет University */
export const addStudentSuccess = (students) => ({
    type: studentsConstants.ADD_STUDENT_SUCCESS,
    students
});

export function addStudent(values) {
    return async dispatch => {
      try {
        const student = await API.post(`/students`, values);
        dispatch(addStudentSuccess(student.data.model));
        dispatch(studentInformation(student.data.model.id));
        toast.success("Студент добававлен");

      } catch (error) {
        throw new SubmissionError(error.response.data.message);
      }
    }
}

/* PATCH */
export const patchStudentSuccess = (student) => ({
  type: studentsConstants.PATCH_STUDENT_SUCCESS,
  student
});


export function patchStudent(id, values) {
  return dispatch => {
    return API.patch(`/students/${id}`, values)
        .then(res => {
          dispatch(patchStudentSuccess(res.data));
          toast.success("Изменение сохранены");
        })
        .catch(error => {
          throw new SubmissionError({
            student_ticket: error.response.data[0].message
          });
        });
  }
}

/* DELETE */
export function deleteStudentSuccess(id) {
    return {
        type: studentsConstants.DELETE_STUDENT_SUCCESS,
        id
    }
}

export function deleteStudent(id) {
    return (dispatch) => {
        return API.delete(`/students/${id}`)
            .then(group => {
                dispatch(deleteStudentSuccess(group.data.id));
                toast.success("Студент удален");
            })
            .catch(error => {
                console.log(error)
            })
    }
}


/* использьуется на странице students -> card и кабинете university -> students */
export const studentInformationSuccess = (infoStudent, userInfo = null) => ({
    type: studentsConstants.GET_INFORMATION_STUDENT_SUCCESS,
    infoStudent,
    userInfo
});

export const studentInformationFailure = (error) => ({
    type: studentsConstants.GET_INFORMATION_STUDENT_FAILURE,
    error
});

export const isLoadingStudentInformation = (isLoading) => ({
    type: studentsConstants.IS_LOADING_STUDENTS_INFORMATION,
    isLoading
});

export function studentInformation(id) {
    return async dispatch => {
        dispatch(isLoadingStudentInformation(true));
        try {
            const studentInformation = await API.get(`/students/${id}`);
            if (studentInformation.data.uid) {
                const userInformation = await API.get(`/user/${studentInformation.data.uid}`);
                dispatch(studentInformationSuccess(studentInformation.data, userInformation.data));
            } else {
                dispatch(studentInformationSuccess(studentInformation.data));
            }

        } catch (error) {
            dispatch(studentInformationFailure(error))
        }
    }
}

/* ALL LIST STUDENTS */
export const getStudentsSuccess = (students, pagination) => ({
  type: studentsConstants.GET_STUDENTS_SUCCESS,
  students,
  pagination
});

export const getStudentsFailure = (error) => ({
  type: studentsConstants.GET_STUDENTS_FAILURE,
  error
});

export const isLoadingStudents = (isLoading) => ({
  type: studentsConstants.IS_LOADING_STUDENTS,
  isLoading
});

//список всех студентов, использутся только в одном есте, поэтому прописала жестко
export function getListStudents() {
  return dispatch => {
    dispatch(isLoadingStudents('true'));
    return API.get(`/students/search?visible=true`)
        .then(res => {
          dispatch(getStudentsSuccess(res.data.items, res.data._meta));
        }).catch(error => dispatch(getStudentsFailure(error)));
  }
}

export function getStudentsById(id, page) {
  return dispatch => {
    dispatch(isLoadingStudents(true));
    return API.get(`/students/search?groups_id=${id}&page=${page}`)
        .then(res => {
          dispatch(getStudentsSuccess(res.data.items, res.data._meta));
        }).catch(error => dispatch(getStudentsFailure(error)));
  }
}

export function addStudentsFile(file, values, university_id) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("surname_start", values.surname_start);
  formData.append("name_start", values.name_start);
  formData.append("patronymic_start", values.patronymic_start);
  formData.append("ticket_start", values.ticket_start);
  formData.append("groups_start", values.groups_start);
  formData.append("university_id", university_id);

  return dispatch => {
    dispatch(isLoadingGroups(true));
    return API.post(`/students/upload-excel`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
       dispatch(isLoadingGroups(false))
    }).catch(error => {
      console.log(error, error)
    });
  }
}


export function addStudentsAssessments(file, values) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("ticket_start", values.ticket_start);
  formData.append("name_start", values.name_start);
  formData.append("mark_start", values.mark_start);

  return dispatch => {
    dispatch(isLoadingStudents(true));
    return API.post(`/students-assessments/upload-excel`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
      toast.success('Оценки загружены');
       dispatch(isLoadingStudents(false));
      // dispatch(getStudentsById(groups_id))
    }).catch(error => {
      console.log(error)
    });
  }
}

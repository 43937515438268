import React from 'react';
import {Link} from "react-router-dom";
import {ButtonDelete} from "../ButtonOptions/ButtonOptions";
import {CardPreviewResume} from "../Layouts/CardPreviewResume";
import {status} from "../../constants/status";

export const FavoritesList = ({date, removeFavorites}) => (
   date.map(favorite => {
      return <tr key={favorite.id}>
         <td>
            {new Date(favorite.created_at * 1000).toLocaleString('ru', {
               year: 'numeric',
               month: 'long',
               day: 'numeric'
            })}
         </td>
         <td>
            {favorite.vacancy_id ?
               (favorite.vacancy_data.status === status.DISABLED ?
                  <span>
                     {favorite.vacancy_data.name} / <span className="text-red">Вакансия заблокирована</span>
                  </span>
                  :
                  <Link to={{pathname: `/vacancies/${favorite.vacancy_id}`}}>
                     {favorite.vacancy_data.name}
                  </Link>
               )
               :
               (favorite.resume_data.status === status.DISABLED ?
                  <span>
                     {favorite.resume_data.post} / <span className="text-red">Резюме заблокировано</span>
                  </span>
                  :
                  <Link to={{pathname: `/resumes/${favorite.resume_id}`}}>
                     {favorite.resume_data.post}
                  </Link>
               )
            }
         </td>
            {favorite.resume_id &&
               <td>
                  {favorite.resume_data.status !== status.DISABLED &&
                     <Link to={{pathname: `/resumes/${favorite.resume_id}`}}>
                        <CardPreviewResume data={favorite.resume_data}/>
                     </Link>
                  }
               </td>
            }
         <td>
            <ButtonDelete onClick={() => removeFavorites(favorite.id)} />
         </td>
      </tr>
   })
);

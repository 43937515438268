import { feedbackConstants } from "../constants/feedbackConstants";

const initialState = {
   isLoading: false,
   favorite: [],
};

export function favorites(state = initialState, action) {
   switch (action.type) {
      case feedbackConstants.IS_LOADING_FAVORITES:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case feedbackConstants.GET_FAVORITES_SUCCESS:
         return {
            ...state,
            favorite: action.favorite,
            isLoading: false,
         };

      case feedbackConstants.GET_FAVORITES_FAILURE:
         return {
            ...state,
            favorite: [],
            isLoading: false,
         };

      case feedbackConstants.ADD_FAVORITES_SUCCESS:
         return {
            ...state,
            favorite: [...state.favorite, action.favorites],
         };

      case feedbackConstants.FAVORITES_DELETE_SUCCESS:
         return {
            ...state,
            favorite: state.favorite.filter((id) => id.id !== action.favorites),
         };

      default:
         return state;
   }
}

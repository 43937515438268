import React from 'react';

export const GeneralInformation = ({list, checkAuth, ...props}) => {
   return list
         .filter(item => ((checkAuth !== undefined) ||  item.show))
         .filter((i) => {
            if (props.condition === undefined) {
               return true
            } else {
               return i.show || (!i.show && props.condition())
            }
         })
         .map((item, index) => {
            return <li key={index}>
               <span>{item.name} </span>
               <span>{item.intent}</span>
            </li>
         })
};

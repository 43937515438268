import React, {Component} from "react";
import isEqual from "lodash/isEqual";
import DropdownTreeSelect from "react-dropdown-tree-select";
import 'react-dropdown-tree-select/dist/styles.css'


class FieldDropdownTreeSelect extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data: props.data,
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      this.setState({
         data: this.props.data
      })
   }

   shouldComponentUpdate = (nextProps) => {
      return !isEqual(nextProps.data, this.state.data);
   };

   render() {
      const {data, ...rest} = this.props;
      return (
         <>
            <DropdownTreeSelect className="custom-drop"
                                name="category"
                                id="category"
                                {...rest}
                                texts={{ placeholder: 'Введите название' }}
                                data={this.state.data}
                                keepTreeOnSearch={true}
            />
         </>
      )
   }
}

export const FieldContainerThreeSelect  = ({data, input, meta: { touched, error }}) => {
   const onChange = (currentNode, selectedNodes) => {
      input.onChange(selectedNodes);
   };

   return (
      <>
         <FieldDropdownTreeSelect {...input} data={data} onChange={onChange} />
         {touched && ((error && <span className='hasError'>{error}</span>))}
      </>
   )
};


import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getVacancyInfo} from "../actions/VacancyAction";

export const useVacancyCard = (vacancyId) => {
    const dispatch = useDispatch();
    const vacancy = useSelector(state => state.vacancyInfo);

    useEffect(() => {
        dispatch(getVacancyInfo(vacancyId));
    }, [dispatch, vacancyId]);

    return {vacancy}
};

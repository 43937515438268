import React from 'react';

export const Team = ({img, name, list}) => {
  return (
    <div className="card-employee">
      <div className="card-employee__img">
        <img src={img} alt="" />
      </div>
      <div className="card-employee__content">
        <div className="card-employee__name title-inter-medium">{name}</div>
        <div className="card-employee__position">
          <ul>
            {list.map((position,index) => {
              return <li key={index}>{position}</li>
            })}
          </ul>
        </div>
      </div>
    </div>
  )
};

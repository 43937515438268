import React from 'react';
import {userActions} from "../../actions/AuthAction";
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validate from "../FormElement/validate";
import {FieldInput} from "../FormElement/Field/FieldInput";

const VerificationForm = ({handleSubmit}) => {
   return (
      <form className="form" onSubmit={handleSubmit}>
         <div className="form__item">
            <label className="form__label" htmlFor="token">Введите код:</label>
            <Field
               id="token"
               name="token"
               type="text"
               component={FieldInput}
            />
         </div>
         <div className="form__item">
            <label className="form__label" htmlFor="password">Новый пароль:</label>
            <Field
               id="password"
               name="password"
               type="password"
               component={FieldInput}
            />
         </div>
         <button className="button button-green-gradient button-small button-upper button-indent button-bold">Изменить</button>
      </form>
   )
};

export default connect(null, {
   onSubmit: userActions.verificationPassword,
})(reduxForm({
   form: 'restore-password',
   validate
})(VerificationForm))

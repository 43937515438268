export const vacancyConstant = {
    ADD_VACANCY: 'ADD_VACANCY',
    DELETE_VACANCY: 'DELETE_VACANCY',
    CHANGE_VACANCY: 'CHANGE_VACANCY',
    COUNTER_VACANCY: 'COUNTER_VACANCY',

    GET_VACANCY_SUCCESS: 'GET_VACANCY_SUCCESS',
    GET_VACANCY_FAILURE: "GET_VACANCY_FAILURE",
    IS_LOADING_VACANCIES: 'IS_LOADING_VACANCIES',

    GET_INFO_VACANCY_SUCCESS: 'GET_INFO_VACANCY_SUCCESS',
    GET_INFO_VACANCY_FAILURE: 'GET_INFO_VACANCY_FAILURE',
    IS_LOADING_INFO_VACANCIES: 'IS_LOADING_INFO_VACANCIES',

    GET_LIST_VACANCY_SEARCH_SUCCESS: 'GET_LIST_VACANCY_SEARCH_SUCCESS',
    GET_LIST_VACANCY_SEARCH_FAILURE: 'GET_LIST_VACANCY_SEARCH_FAILURE',
};
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getInvitationInfoEmployer, invitationDelete} from "../../../actions/FeedbackAction";
import {Redirect} from 'react-router-dom';
import {Preloader} from "../../Preloader/Preloader";
import {VacancyCard} from "../../VacancyCard/VacancyCard";
import {status} from "../../../constants/status";

export const SendFeedbackCard = ({match}) => {
   const inviteID = match.params.feedbackId;
   const dispatch = useDispatch();
   const informationInvite = useSelector(state => state.inviteInformationForCard);
   const gallery = useSelector(state => state.media.portfolio);
   const user = useSelector(state => state.authentication);

   useEffect(() => {
      dispatch(getInvitationInfoEmployer(inviteID))
   }, [dispatch, inviteID]);

   const [del, setDelete] = useState(false);
   const handleClickDeleteFeedback = (id) => {
      dispatch(invitationDelete(id));
      setDelete(true)
   };

   if (del === true) {
      return <Redirect to="/student/my-feedback"/>
   }

   return (
      informationInvite.isLoading || informationInvite.inviteInfo === null ? <Preloader/> :
         <>
            {informationInvite.inviteInfo.vacancy_data.status === status.DISABLED && <Redirect to="/student/my-feedback/" />}
            <VacancyCard vacancy={{
                         vacancyInfo: informationInvite.inviteInfo.vacancy_data,
                         userInfo: informationInvite.userInfo
                     }}
                         gallery={gallery}
                         inviteInfo={informationInvite.inviteInfo}
                         myFeedback={true}
                         handleClickDeleteFeedback={handleClickDeleteFeedback}
                         checkAuth={user}
            />
         </>
   )
};

import React from "react";
import { Link } from "react-router-dom";
import { status } from "../../../constants/status";
import {
   ButtonAccept,
   ButtonArchive,
   ButtonDelete,
} from "../../ButtonOptions/ButtonOptions";

export const MyResumeList = ({
   data,
   url,
   handleClickDelete,
   handleClickArchive,
   handleClickPublished,
}) =>
   data.map((resume) => {
      return (
         <tr key={resume.id}>
            <td>
               {new Date(resume.created_at * 1000).toLocaleString("ru", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
               })}
            </td>
            <td>
               {resume.status === status.DISABLED ? (
                  <span>{resume.post}</span>
               ) : (
                  <Link to={`${url}/${resume.id}`}>{resume.post}</Link>
               )}
            </td>
            <td>{resume.num_views}</td>
            <td>
               {resume.status === status.ARCHIVE ? (
                  <span className="text-light">В архиве</span>
               ) : resume.status === status.DISABLED ? (
                  <span className="text-red">Заблокировано</span>
               ) : (
                  <span className="text-green">Активный</span>
               )}
            </td>
            <td>
               {resume.status !== status.DISABLED && (
                  <div className="btn-group">
                     <ButtonDelete
                        onClick={() => handleClickDelete(resume.id)}
                     />

                     {resume.status === 50 && (
                        <ButtonAccept
                           onClick={() => handleClickPublished(resume.id)}
                        />
                     )}

                     {resume.status !== 50 && (
                        <ButtonArchive
                           onClick={() => handleClickArchive(resume.id)}
                        />
                     )}
                  </div>
               )}
            </td>
         </tr>
      );
   });

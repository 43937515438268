import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import { addSubject, getSubjects, deleteSubject, editSubject } from "../../../actions/UniversitiesAction";
import {Subjects} from "./Subjects";
import FormCourse from "./FormCourse";
import {flattenObjHelper} from "../../Filter/flatteronObjHelper";
import {Plus} from "../../Icons/Plus";

export const SubjectsContainer = ({studentId}) => {
  const dispatch = useDispatch();
  const assessments = useSelector(state => state.assessments);

  useEffect(() => {
    dispatch(getSubjects(studentId))
  }, [dispatch, studentId]);

  const handleSubjectDelete = (id) => {
    dispatch(deleteSubject(id))
  };

  const handleEditSubjectSubmit = (values) => {
   dispatch(editSubject(values))
  };

  const handleSubmitNewSubject = (values) => {
     const objCopy = flattenObjHelper(values);
     if (objCopy.type === '') {
        objCopy.mark = 0;
     }

     dispatch(addSubject(objCopy));
     setNewSubject(false);
  };

  const [newSubject, setNewSubject] = useState(false);
  const objectSubject = {
    student_id: studentId
  };

  return (
      <>
        <div className="add-subject">
          <div className="add-subject__head">
            <h4 className="mb-none min text-upper title-inter-medium">Добавить предмет</h4>
            <button className="button button-green button-modal" type="button" aria-label="Добавить оценки" onClick={() => setNewSubject(true)}>
               <Plus />
            </button>
          </div>
        </div>

        <div className="list-subject">
          {newSubject && (
              <div className="list-subject__item flex-element">
                <FormCourse initialValues={objectSubject} onSubmit={handleSubmitNewSubject} />
              </div>
          )}

          <Subjects assessments={assessments}
                    handleSubjectDelete={handleSubjectDelete}
                    handleEditSubjectSubmit={handleEditSubjectSubmit} />
        </div>
      </>
  );
};

import React, {useEffect, useState} from 'react';
import FilterStudents from "../Filter/FilterStudents";
import {useDispatch, useSelector} from "react-redux";
import {filteredStudentList} from "../../actions/HomeAction";
import {flattenObjHelper} from "../Filter/flatteronObjHelper";
import {StudentsList} from "./StudentsList";
import {useListForSelect} from "../Filter/useListForSelect";
import {getListStudents} from "../../actions/Universtiy/StudentsAction";
import queryString from "query-string";
import {Preloader} from "../Preloader/Preloader";
import {Helmet} from "react-helmet/es/Helmet";
import {FilterIcon} from "../Icons/Filter";

export const StudentsListContainer = ({match, location}) => {
  const dispatch = useDispatch();
  const url = match.url;
  const user = useSelector(state => state.authentication.user);
  const studentsList = useSelector(state => state.students);
  const optionListForSelect = useListForSelect();

  const specialitiesOptions = optionListForSelect.specialitiesOptions.specialties;
  const universityOptions = optionListForSelect.universityOptions;
  const parseLocation = queryString.parse(location.search);

  useEffect(() => {
    dispatch(getListStudents());
    dispatch(filteredStudentList(queryString.parse(location.search)))
  }, [dispatch, location.search]);

  const [filterOpen, setFilterOpen] = useState(false);
  const handleOpenFilter = (e) => {
    e.preventDefault();
    filterOpen ? setFilterOpen(false) : setFilterOpen(true);
  };

  const handleSubmit = (values) => {
    const newObj = flattenObjHelper(values);
    if (values.average_ball) {
      newObj.average_from = values.average_ball[0];
      newObj.average_up = values.average_ball[1];
    }

    if (values.category) {
      newObj.category = values.category.map(item => item.value).join(', ');
    }

    dispatch(filteredStudentList(newObj));
    setFilterOpen(false)
  };

  let initialValues = {
    ...parseLocation,
    university_id: universityOptions.filter(el => el.id === Number(parseLocation.university_id)).map(universities => {
      return ({value: universities.id, label: universities.abbreviation})
    }),
     specialty_name: parseLocation.speciality_name && parseLocation.speciality_name.split(", ").map(value => {
      return ({value: value, label: value})
    }),
    training_form: parseLocation.training_form && parseLocation.training_form.split(", ").map(value => {
      return ({value: value, label: value})
    }),
    course: parseLocation.course && parseLocation.course.split(", ").map(value => {
      return ({value: value, label: value})
    }),
  };

  return (
      <>
        <Helmet>
          <title>Студенты</title>
        </Helmet>
        {
          studentsList.isLoading ? <Preloader/> :
            <>
               <h1 className="dark title-semi-bold title-bold display-large">Студенты</h1>
               <div className="inner-block inner-block--primary flex-element flex-element--justify-sb">
                 <aside className="aside-outside aside-outside--secondary relative">
                   <button aria-label="Открыть фильтр" className="filter-button" onClick={handleOpenFilter}>
                     <FilterIcon />
                   </button>
                   <div className={`bg-card filter-block ${filterOpen ? 'active' : ''}`}>
                     <FilterStudents
                         initialValues={initialValues}
                         universityOptions={universityOptions}
                         specialitiesOptions={specialitiesOptions}
                         onSubmit={(values) => {
                           handleSubmit(values)
                         }}
                     />
                   </div>
                 </aside>
                 <div className="inner-block__content">
                   {studentsList.students.length === 0 ?
                       <p>Ничего не найдено</p> :
                       <StudentsList studentsList={studentsList.students} url={url} checkAuth={user}/>
                   }
                 </div>
               </div>
            </>
        }
      </>

  )
};

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getInvitationInfoEmployer, invitationPatch, invitationDelete} from "../../../actions/FeedbackAction";
import {VacancyCard} from "../../VacancyCard/VacancyCard";
import {Redirect} from "react-router-dom";
import {Preloader} from "../../Preloader/Preloader";
import {status} from "../../../constants/status";

export const InvitationsCardStudent = ({match}) => {
   const dispatch = useDispatch();
   const inviteID = match.params.invitationsID;
   const informationInvite = useSelector(state => state.inviteInformationForCard);
   const gallery = useSelector(state => state.media.portfolio);
   const user = useSelector(state => state.authentication);

   useEffect(() => {
      dispatch(getInvitationInfoEmployer(inviteID))
   }, [dispatch, inviteID]);

   const [del, setDelete] = useState(false);


   const handleClickTake = (id) => {
      const value = {status: 10, title: 'Вы приняли вакансию'};
      dispatch(invitationPatch(id, value))
   };

   const handleClickDelete = (id) => {
      dispatch(invitationDelete(id));
      setDelete(true)
   };

   const handleClickReject = (id) => {
      const value = {status: 5, title: 'Вакансия отклонена'};
      dispatch(invitationPatch(id, value))
   };

   if (del === true) {
      return <Redirect to="/student/invitations"/>
   }


   return (
      informationInvite.isLoading || informationInvite.inviteInfo === null ? <Preloader/> :
      <>
         {informationInvite.inviteInfo.vacancy_data.status === status.DISABLED || informationInvite.inviteInfo.resume_data.status === status.DISABLED && <Redirect to="/student/invitations/" />}
         <VacancyCard
            vacancy={
               {
                  vacancyInfo: informationInvite.inviteInfo.vacancy_data,
                  userInfo: informationInvite.userInfo
               }
            }
            gallery={gallery}
            inviteInfo={informationInvite.inviteInfo}
            handleClickReject={handleClickReject}
            handleClickTake={handleClickTake}
            handleClickDelete={handleClickDelete}
            checkAuth={user}
         />
      </>
   )
};


export const studentsConstants = {
  //список студентов
  GET_STUDENTS_SUCCESS: 'GET_STUDENTS_SUCCESS',
  GET_STUDENTS_FAILURE: 'GET_STUDENTS_FAILURE',
  IS_LOADING_STUDENTS: 'IS_LOADING_STUDENTS',

  GET_INFORMATION_STUDENT_SUCCESS: 'GET_INFORMATION_STUDENT_SUCCESS',
  GET_INFORMATION_STUDENT_FAILURE: 'GET_INFORMATION_STUDENT_FAILURE',
  IS_LOADING_STUDENTS_INFORMATION: 'IS_LOADING_STUDENTS_INFORMATION',

  DELETE_STUDENT_SUCCESS: 'DELETE_STUDENT_SUCCESS',
  ADD_STUDENT_SUCCESS: 'ADD_STUDENT_SUCCESS',
  PATCH_STUDENT_SUCCESS: 'PATCH_STUDENT_SUCCESS',
};
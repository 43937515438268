import React from "react";
import { Link } from "react-router-dom";
import { ButtonDelete } from "../../ButtonOptions/ButtonOptions";
import { CardPreviewResume } from "../../Layouts/CardPreviewResume";
import { status } from "../../../constants/status";

export const InvitationsList = ({ data, handleClickDelete, url }) => (
   <>
      {data.map((invite) => {
         return (
            <tr key={invite.id}>
               <td>
                  {new Date(invite.created_at * 1000).toLocaleString("ru", {
                     year: "numeric",
                     month: "long",
                     day: "numeric",
                  })}
               </td>
               <td>
                  <span>{invite.vacancy_data.name}</span>
               </td>
               <td>
                  {invite.resume_data.status === status.DISABLED ? (
                     <span>
                        <CardPreviewResume data={invite.resume_data} />
                     </span>
                  ) : (
                     <Link to={`${url}/${invite.id}`}>
                        <CardPreviewResume data={invite.resume_data} />
                     </Link>
                  )}
               </td>
               <td>
                  {invite.resume_data.status === status.DISABLED ? (
                     <span className="text-red">
                        Резюме студента заблокировано
                     </span>
                  ) : invite.vacancy_data.status === status.DISABLED ? (
                        <span className="text-red">
                           Ваша вакансия заблокирована
                        </span>
                  )
                     : invite.status === 50 ? (
                     <span className="text-light">В архиве</span>
                  ) : invite.status === 5 || invite.status === -1 ? (
                     <span className="text-red">Отказано</span>
                  ) : invite.status === 10 || invite.status === -2 ? (
                     <span className="text-green">Принятые</span>
                  ) : (
                     <span className="text-blue">Ожидание ответа</span>
                  )}
               </td>
               <td>
                  <div className="btn-group flex-element">
                     {invite.status === -1 ||
                     invite.status === -2 ||
                     invite.status === 10 ? (
                        <ButtonDelete
                           onClick={() => handleClickDelete(invite.id)}
                        />
                     ) : null}
                  </div>
               </td>
            </tr>
         );
      })}
   </>
);

import React from "react";
import { Switch } from "react-router-dom";
import { routesUniversity } from "../config/RoutesUniversity";
import { RouteWithSubRoutes } from "../config/RouteWithSubRoutes";

export const PersonalAreaUniversity = () => {
   return (
      <div className="content">
         <div className="container">
            <Switch>
               {routesUniversity.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
               ))}
            </Switch>
         </div>
      </div>
   );
};

import React from 'react';
import {Field, reduxForm} from "redux-form";
import {FieldSelect} from "../FormElement/Field/FieldSelect";
import FieldTextarea from "../FormElement/Field/FieldTextarea";
import {FieldInput} from "../FormElement/Field/FieldInput";
import validate from "../FormElement/validate";

const questionList = [
  {value: "Регистрация и восстановление доступа", label: "Регистрация и восстановление доступа"},
  {value: "Предложения по работе сайта", label: "Предложения по работе сайта"},
  {value: "Предложения о сотрудничестве", label: "Предложения о сотрудничестве"},
  {value: "Проблемы с работой сайта", label: "Проблемы с работой сайта"},
  {value: "Прочее", label: "Прочее"},
];

const TechnicalSupportForm = ({handleSubmit, lengthText, handleLengthChange}) => {
  return (
      <form className="form" onSubmit={e => handleSubmit(e)}>
        <div className="form__item">
          <label htmlFor="category" className="form__label">Выберите тему вопроса</label>
          <Field
              id="category"
              name="category"
              placeholder="Вопрос по сайту"
              options={questionList}
              component={FieldSelect}
          />
        </div>

        <div className="form__item">
          <div className="item-group flex-element flex-element--options">
            <label className="mb-none form__label" htmlFor="text">Описание</label>
            <p className="mb-none min text-red">{lengthText}/300</p>
          </div>

          <Field
              id="text"
              name="text"
              placeholder="Опишите проблему"
              options={questionList}
              className="form-control-textarea"
              maxlength="300"
              component={FieldTextarea}
              onChange={handleLengthChange}
          />
        </div>

        <div className="form__item">
          <label className="form__label" htmlFor="email">E-mail для связи</label>
          <Field
              id="email"
              name="email"
              component={FieldInput}
          />
        </div>

        <button className="button button-green-gradient button-indent button-bold">Отправить</button>
      </form>
  )
};

export default reduxForm({
  form: 'technical-support',
  validate,
})(TechnicalSupportForm)

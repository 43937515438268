import React, {useEffect, useState} from "react";
import LoginStudentForm from "./LoginStudentForm";
import {useSelector, useDispatch} from "react-redux";
import {getCities, getUniversitiesByFilterCity} from "../../../actions/HomeAction";

export const LoginStudent = () => {
  const dispatch = useDispatch();
  const cities = useSelector(state => state.cities);
  const universities = useSelector(state => state.universities.universities);
  const [show, setShow] = useState(false);

  useEffect( () => {
    dispatch(getCities());
  }, [dispatch]);

  const handleChange = (value) => {
    const newValue = value && value.label;
    dispatch(getUniversitiesByFilterCity(newValue));
    setShow(true)
  };

  return (
      <LoginStudentForm
          cities={cities}
          universities={universities}
          handleChange={handleChange}
          show={show}
      />
  )
};

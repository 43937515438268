import React from "react";
import { InformationForPortal } from "../components/Home/InformationForPortal/InformationFor";
import { HeroSection } from "../components/Home/HeroSection/heroSection";
import { Helmet } from "react-helmet/es/Helmet";

export function Home() {
   return (
      <>
         <Helmet>
            <title>UpLift</title>
         </Helmet>
         <HeroSection />
         <InformationForPortal />
      </>
   );
}

import React from 'react';
import {Plus} from "../../Icons/Plus";

export const SpecialityTableHead = ({ handleOpen }) => (
    <tr>
      <th>Код</th>
      <th scope="col">
        <div className="line">
            <span>Специальность                                                                                    / Направление</span>
            <button type="button" className="button button-green button--modal" aria-label="Добавить Специалность" onClick={handleOpen}>
               <Plus />
            </button>
        </div>
      </th>
      <th scope="col">Групп</th>
      <th scope="col">Студентов</th>
      <th scope="col">
        <span className="visually-hidden">Удалить</span>
      </th>
    </tr>
);

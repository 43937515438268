import React, {useState} from 'react';
import {history} from "../../../helpers/history";
import {Statistic} from "./statistic";
import {flattenObjHelper} from "../../Filter/flatteronObjHelper";
import FilterMain from '../../Filter/FilterMain';
import MacBook from '../../../assets/img/Macbook3.png';

export function HeroSection() {
  const [sendType, setSendType] = useState('resumes');

  const handleSubmit = (values) => {
    const objCopy = flattenObjHelper(values);

    let res = [];
    for (let [key, value] of Object.entries(objCopy)) {
      res.push(`${key}=${value}`);
    }

    history.push({
      pathname: `/${sendType}`,
      search: res.join('&')
    })
  };

  const updateSelect = (type) => {
     setSendType(type)
  };

  return (
        <section className='home-intro'>
          <div className="home-intro__container relative">
            <div className="home-intro__information">
              <h1 className="title-bold dark display-large">Мы помогаем студентам найти интересную работу</h1>
              <span className="home-intro__sub title-bold dark">Поиск молодых специалистов</span>
              <FilterMain
                  updateSelect={updateSelect}
                  onSubmit={(values) => {
                    handleSubmit(values)
                  }}
              />
              <Statistic/>
            </div>
            <div className="home-intro__area relative">
              <div className="hero-bg" aria-hidden="true"></div>
              <div className="home-intro__area-img">
                <figure>
                  <img className="hero-figure__img" src={MacBook} alt="Сервис UpLift" />
                </figure>
              </div>
            </div>
          </div>
        </section>
  )
}

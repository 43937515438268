import React from 'react';
import {Field, reduxForm} from "redux-form";
import {FieldInput} from "../FormElement/Field/FieldInput";
import {FieldDaData} from "../FormElement/Field/FieldDaData";
import {FieldContainerThreeSelect} from "../FormElement/Field/FieldContainerThreeSelect";

const FilterCompany = ({handleSubmit, city, data}) => {
   return (
      <form className="form" onSubmit={e => handleSubmit(e)}>
         <div className="form__item">
            <label htmlFor="name" className="form__label">Введите название компании</label>
            <Field
               name="name"
               id="name"
               type="text"
               component={FieldInput}
               placeholder="Введите название"
            />
         </div>

         <div className="form__item form__item--custom">
            <label className="form__label">
               <span>Город</span>

               <Field
                  name="city"
                  type="text"
                  component={FieldDaData}
               />
            </label>
         </div>

         <div className="form__item form__item--custom">
            <span className="form__label form__span">Отрасль компании</span>
            <Field
               id="category"
               name="category"
               type="text"
               placeholder="Выберите отрасль компании"
               data={data}
               component={FieldContainerThreeSelect}
            />
         </div>

         <button className="button button-green-gradient button-bold button-small button-upper button-indent">Найти
         </button>
      </form>
   )
};

export default reduxForm({
   form: 'filter'
})(FilterCompany)

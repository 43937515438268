import React, { useState } from "react";
import { Placemark, YMaps, Map } from "react-yandex-maps";

export const YandexMap = ({ address }) => {
   const [coordinates, setCoordinates] = useState([0, 0]);

   const geoCode = (ymaps) => {
      ymaps.geocode(address).then((res) => {
         let getObject = res.geoObjects.get(0),
            coords = getObject.geometry.getCoordinates();

         setCoordinates(coords);
      });
   };

   return (
      <YMaps
         query={{
            apikey: "349f630e-f1e8-4ded-84be-bfc912d81272",
         }}
      >
         <Map
            onLoad={(ymaps) => {
               geoCode(ymaps);
            }}
            state={{
               center: coordinates,
               zoom: 12,
               controls: ["zoomControl", "fullscreenControl"],
               behaviors: ["default", "scrollZoom"],
            }}
            width="100%"
            modules={[
               "control.ZoomControl",
               "control.FullscreenControl",
               "geocode",
            ]}
         >
            <Placemark geometry={coordinates} />
         </Map>
      </YMaps>
   );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {patchVacancy, getVacancyInfo} from "../../../actions/VacancyAction";
import VacancyForm from './VacancyForm';
import {Helmet} from "react-helmet/es/Helmet";
import {Preloader} from "../../Preloader/Preloader";
import {mapArrayToString, fromStringIntoArray} from "../../../helpers/conversion";
import {status} from "../../../constants/status";
import {Redirect} from "react-router-dom";

export const ChangeVacancy = ({match}) => {
  const vacancy = useSelector(state => state.vacancyInfo);
  const classifier = useSelector(state => state.filterClassifier.filterCategory);
  const dispatch = useDispatch();
  const vacancyId = match.params.vacancyId;
  const vacancyInfoElement = vacancy.vacancyInfo;

  useEffect(() => {
    dispatch(getVacancyInfo(vacancyId));
  },[dispatch, vacancyId]);

  const handleSubmit = (values) => {
    let copyValues = Object.assign({}, values);
    mapArrayToString(copyValues, ['type_employment', 'work_time', 'category', 'keywords']);

    console.log(copyValues)
     // if (values.salary_undefined) {
     //    copyValues.salary_undefined = '1';
     //    copyValues.salary_from = 0;
     //    copyValues.salary_up = 100;
     //
     //    dispatch(patchVacancy(vacancyId, copyValues));
     // } else {
     //    copyValues.salary_undefined = '0';
     //    dispatch(patchVacancy(vacancyId, copyValues));
     // }
  };

  let initialValues;
  if (vacancyInfoElement) {

    initialValues = {
      ...vacancyInfoElement
    };

    fromStringIntoArray(initialValues, ["keywords", "type_employment", "work_time"])
  }

  return (
     <div className="bg-card create-block">
        <Helmet>
           <title>Редактировать вакансию</title>
        </Helmet>
        {
           vacancy.isLoading || vacancy.vacancyInfo === null ? <Preloader/> :
           <>
              <h1 className="title-bold dark display-average">Редактировать вакансию</h1>

              {vacancy.vacancyInfo.status === status.DISABLED &&
               <Redirect to="/employer/vacancy" />
              }

              <VacancyForm
                 data={classifier}
                 initialValues={initialValues}
                 onSubmit={(values) => {
                    handleSubmit(values)
                 }}
              />
           </>
        }
     </div>
  )
};

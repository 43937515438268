import React from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

export const QuillEditor = ({ id, input, }) => {
  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}],
      [{size: []}],
      ['bold', 'italic', 'underline','strike', 'blockquote', 'code-block'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link', 'image', 'video'],
    ],
  };

  return (
    <ReactQuill
        {...input}
        id={id}
        onChange={(newValue) => {
          input.onChange(newValue);
        }}
        modules={modules}
        onBlur={(range, source, quill) => {
          input.onBlur(quill.getHTML());
        }}
    />
  )
};

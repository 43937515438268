import React from 'react';

const Thead = () => (
    <thead>
       <tr>
           <th scope="col">Дата</th>
           <th scope="col">Резюме</th>
           <th scope="col">Просмотров</th>
           <th scope="col">Статус</th>
           <th scope="col">Действия</th>
       </tr>
    </thead>
);

export default Thead;

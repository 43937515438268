import DadataSuggestions  from 'react-dadata-suggestions'
import React from 'react';

export const FieldDaData = ({input, placeholder, meta: { touched, error }}) => {

   return (
      <>
         <DadataSuggestions
            {...input}
            token="43f15ab410d6571b969a605a353f467b8d1db1b8"
            placeholder="Введите"
            query={input.value}
            minChars={2}
            hint={true}
            deferRequestBy={0}
            onChange={(value) => {
               input.onChange(value.value)
            }}
            onSelect={suggestion => console.log(suggestion)}
         />
         {touched && ((error && <span className="hasError">{error}</span>))}
      </>
   )
};

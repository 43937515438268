import {Field, reduxForm} from "redux-form";
import React from "react";
import validate from "../../FormElement/validate";
import {FieldInput} from "../../FormElement/Field/FieldInput";

const FormSpeciality = ({handleSubmit}) => (
    <form className="form" onSubmit={e => handleSubmit(e)}>
      <div className="flex-element form__line">
        <div className="form__item">
          <label htmlFor="enterCode" className="visually-hidden">Введите код</label>
          <Field
              id="enterCode"
              name="code"
              type="text"
              placeholder="Введите код"
              component={FieldInput} />
        </div>

        <div className="form__item">
          <label htmlFor="enterTitle" className="visually-hidden">Введите название</label>
          <Field
              id="enterTitle"
              name="name"
              type="text"
              placeholder="Введите название"
              component={FieldInput} />
        </div>

      </div>
      <button className="button button-green-gradient button-bold button-upper button-small button-indent">добавить</button>
    </form>
);
export default reduxForm({
  form: 'specialty',
  validate
})(FormSpeciality)

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
   invitationPatch,
   invitationRequestGet,
} from "../../../actions/FeedbackAction";
import { GetFeedbackList } from "./GetFeedbackList";
import { status } from "../../../constants/status";
import { TabsContent } from "../../Tabs/TabsContent";

export const GetFeedbackListContainer = ({ vacancyId, userId, match }) => {
   const dispatch = useDispatch();
   const feedback = useSelector((state) => state.feedback);

   useEffect(() => {
      dispatch(
         invitationRequestGet({
            vacancy_id: vacancyId,
            to_uid: userId,
         })
      );
   }, [dispatch, vacancyId]);

   const handleClickFeedbackReject = (id) => {
      const value = { status: 5, title: "Вы откланили отклик" };
      dispatch(invitationPatch(id, value));
   };

   const handleClickFeedbackAccept = (id) => {
      const value = { status: 10, title: "Вы приняли отклик" };
      dispatch(invitationPatch(id, value));
   };

   const handleClickDeleteFeedbackReject = (id) => {
      const value = { status: -1, title: "Отклик удален с вашего списка" };
      /*status - 1: удаление с откликов ; студент значение отказ*/
      dispatch(invitationPatch(id, value));
   };

   const handleClickDeleteFeedbackAccept = (id) => {
      const value = { status: -2, title: "Отклик удален с вашего списка" };
      /*status - 2: удаление с откликов ; студент значение принято*/
      dispatch(invitationPatch(id, value));
   };

   const FeedbackListPublished = feedback.invite.filter(
      (statusSub) => statusSub.status === status.PUBLISHED
   );
   const FeedbackListReject = feedback.invite.filter(
      (statusSub) => statusSub.status === 5
   );
   const FeedbackListAll = feedback.invite.filter(
      (statusSub) =>
         statusSub.status === 5 ||
         statusSub.status === 10 ||
         statusSub.status === 0
   );

   const tabs = [
      {
         label: "Все",
         components: (
            <FeedbackContent
               list={FeedbackListAll}
               match={match}
               handleClickFeedbackReject={handleClickFeedbackReject}
               handleClickFeedbackAccept={handleClickFeedbackAccept}
               handleClickDeleteFeedbackReject={handleClickDeleteFeedbackReject}
               handleClickDeleteFeedbackAccept={handleClickDeleteFeedbackAccept}
            />
         ),
      },
      {
         label: "Принятые",
         components: (
            <FeedbackContent
               list={FeedbackListPublished}
               text="У вас нет принятых откликов"
               match={match}
               handleClickFeedbackReject={handleClickFeedbackReject}
               handleClickFeedbackAccept={handleClickFeedbackAccept}
               handleClickDeleteFeedbackReject={handleClickDeleteFeedbackReject}
               handleClickDeleteFeedbackAccept={handleClickDeleteFeedbackAccept}
            />
         ),
      },
      {
         label: "Отклоненные",
         components: (
            <FeedbackContent
               list={FeedbackListReject}
               text="У вас нет отклоненных откликов"
               match={match}
               handleClickFeedbackReject={handleClickFeedbackReject}
               handleClickFeedbackAccept={handleClickFeedbackAccept}
               handleClickDeleteFeedbackReject={handleClickDeleteFeedbackReject}
               handleClickDeleteFeedbackAccept={handleClickDeleteFeedbackAccept}
            />
         ),
      },
   ];

   return (
      FeedbackListAll.length !== 0 && (
         <div className="feedback-vacancy">
            <div className="feedback-wrap">
               <h3 className="title-bold">Отклики</h3>

               <div className="bg-card bg-card--inner">
                  <TabsContent tabs={tabs} />
               </div>
            </div>
         </div>
      )
   );
};

const FeedbackContent = ({
   list,
   match,
   text,
   handleClickFeedbackAccept,
   handleClickFeedbackReject,
   handleClickDeleteFeedbackReject,
   handleClickDeleteFeedbackAccept,
}) =>
   list.length === 0 ? (
      <p className="mb-none">{text} </p>
   ) : (
      <div className="responsive-custom table-responsive">
         <table className="table table--shadow-none table--upper table--small-size table--head-transparent">
            <thead className="change-size">
               <tr>
                  <th>Дата</th>
                  <th>Отклик</th>
                  <th>Действие</th>
               </tr>
            </thead>
            <tbody>
               <GetFeedbackList
                  data={list}
                  handleClickFeedbackAccept={handleClickFeedbackAccept}
                  handleClickFeedbackReject={handleClickFeedbackReject}
                  handleClickDeleteFeedbackReject={
                     handleClickDeleteFeedbackReject
                  }
                  handleClickDeleteFeedbackAccept={
                     handleClickDeleteFeedbackAccept
                  }
                  match={match}
               />
            </tbody>
         </table>
      </div>
   );

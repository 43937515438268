import {useDispatch, useSelector} from "react-redux";
import {useResumeCard} from "../../hooks/useResumeCard";
import React, {useEffect} from "react";
import {getVacancyByParams} from "../../actions/VacancyAction";
import {resumeCounter} from "../../actions/ResumeAction";
import {getResumeMedia} from '../../actions/UploadPhoto'
import {addFavorites, favoritesDelete, getFavorites} from "../../actions/FavoritesAction";
import {invitationRequest, invitationRequestGet} from "../../actions/FeedbackAction";
import {Preloader} from "../Preloader/Preloader";
import {ResumeCard} from "./RecumeCard";
import Modal from "@material-ui/core/Modal";
import InvitationForm from './InvitationForm'
import {CloseIcon} from "../Icons/Close";
import {getModalStyle} from "../Modals/getModalStyle";

export const MainResumeCardContainer = ({match}) => {
   const resumeId = match.params.resumeId;
   const dispatch = useDispatch();
   const resultResume = useResumeCard(resumeId);

   const user = useSelector(state => state.authentication);
   const vacancyList = useSelector(state => state.vacancy);
   const files = useSelector(state => state.media);
   const favorites = useSelector(state => state.favorites.favorite);
   const feedback = useSelector(state => state.feedback.invite);

   let typeUser;
   let getVacancyById;
   let userId;

   if (user.loggedIn && user.user.type === 'hirer') {
      typeUser = user.user.type;
      getVacancyById = user.user.hirer_local.id;
      userId = user.user.id;
   }

   useEffect(() => {
      dispatch(getResumeMedia(
         {
            link: "user-portfolio",
            title: "portfolio"
         },
         {resume_id: resumeId}
      ));

      dispatch(getResumeMedia(
         {
            link: "user-documents",
            title: "documents"
         },
         {resume_id: resumeId}
      ));
      dispatch(resumeCounter(resumeId));

      if (user.loggedIn && user.user.type === 'hirer') {
         dispatch(getFavorites({
            resume_id: resumeId,
            uid: userId
         }));
         dispatch(getVacancyByParams({
            hirer_id: getVacancyById,
            status: 10
         }));
         dispatch(invitationRequestGet({
            resume_id: resumeId,
            from_uid: userId
         }))
      }
   },[dispatch, resumeId, getVacancyById, userId]);

   const [open, setOpen] = React.useState(false);
   const handleOpen = () => {
      setOpen(true)
   };
   const handleClose = () => {
      setOpen(false)
   };

   const handleAddFavorites = () => {
      const values = {resume_id: resumeId};
      dispatch(addFavorites(values))
   };

   const handleRemoveFavorites = () => {
      dispatch(favoritesDelete(favorites[0].id))
   };

   const handleSubmit = (values) => {
      const options = {
         to_uid: resultResume.resume.resumeInfo.uid,
         sender_type: user.user.type,
         message: values.message,
         vacancy_id: values.vacancy_id.value,
         resume_id: resumeId
      };

      dispatch(invitationRequest(options));
      setOpen(false);
   };

   return (
      <>
         {(resultResume.resume.isLoading || resultResume.resume.resumeInfo === null)
            ? <Preloader/>
            : <ResumeCard resume={resultResume.resume.resumeInfo}
                          files={files}
                          typeUser={typeUser}
                          checkAuth={user.user}
                          handleOpen={handleOpen}
                          handleAddFavorites={handleAddFavorites}
                          handleRemoveFavorites={handleRemoveFavorites}
                          favorites={favorites.length}
                          feedback={feedback.length} />
         }

         <Modal aria-labelledby="modal-title" open={open} onClose={handleClose} className="modal-feedback">
            <div style={getModalStyle} className="modal-content">
               <div className="modal-header">
                  <h5 id="modal-title " className="dark title-semi-bold">Приглашение на собеседование</h5>
                  <button type="button" className="button button-transparent" onClick={handleClose}
                          aria-label="Закрыть модальное окно">
                           <CloseIcon />
                  </button>
               </div>

               <div className="modal-body">
                  <InvitationForm
                     vacancy={vacancyList.vacancies}
                     onSubmit={(values) => {
                        handleSubmit(values)
                     }}
                  />
               </div>
            </div>
         </Modal>
      </>
   )
};

import {FacultiesContainer} from "../components/PersonalAreaUniversity/Faculties/FacultiesContainer";
import {SpecialityContainer} from "../components/PersonalAreaUniversity/Speciality/SpecialityContainer";
import {GroupsContainer} from "../components/PersonalAreaUniversity/Groups/Groups";
import {StudentsContainer} from "../components/PersonalAreaUniversity/Students/StudentsContainer";
import {NewPersonalDataStudent} from "../components/PersonalAreaUniversity/PersonalDataStudent/NewPersonalDataStudent";
import {EditPersonalDataStudent} from "../components/PersonalAreaUniversity/PersonalDataStudent/EditPersonalDataStudents";
import {SettingsUniversityContainer} from "../components/Settings/SettingsJustUniversity/SettingsUniversityContainer";
import PasswordForStudents from "../components/PersonalAreaUniversity/PasswordForStudents/PasswordForStudents";

export const routesUniversity = [
   {
      path: '/university/faculties',
      exact: true,
      component: FacultiesContainer
   },
   {
      path: '/university/faculties/:id/speciality',
      exact: true,
      component: SpecialityContainer
   },
   {
      path: '/university/faculties/:id/speciality/:id/groups',
      exact: true,
      component: GroupsContainer
   },
   {
      path: '/university/faculties/:id/speciality/:id/groups/:groupsId',
      exact: true,
      component: StudentsContainer
   },
   {
      path: '/university/faculties/:id/speciality/:id/groups/:groupsId/new-student',
      exact: true,
      component: NewPersonalDataStudent
   },
   {
      path: '/university/faculties/:id/speciality/:id/groups/:groupsId/student:studentId',
      exact: true,
      component: EditPersonalDataStudent
   },
   {
      path: '/university/settings',
      exact: true,
      component: SettingsUniversityContainer
   },
   {
      path: '/university/password-for-student',
      exact: true,
      component: PasswordForStudents
   },
];

import React from "react";
import { Link } from "react-router-dom";
import { ButtonDelete } from "../../ButtonOptions/ButtonOptions";
import { status } from "../../../constants/status";

export const MySendFeedbackList = ({ data, url, handleClickDelete }) =>
   data.map((feedback) => {
      return (
         <tr key={feedback.id}>
            <td>
               {new Date(feedback.created_at * 1000).toLocaleString("ru", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
               })}
            </td>

            <td>
               {feedback.resume_data.status === status.DISABLED ? (
                  <span>{feedback.resume_data.post}</span>
               ) : (
                  <Link
                     to={{
                        pathname: `/student/resume/${feedback.resume_data.id}`,
                     }}
                  >
                     {feedback.resume_data.post}
                  </Link>
               )}
            </td>

            <td>
               {feedback.vacancy_data.status === status.DISABLED ? (
                  <span>{feedback.vacancy_data.name}</span>
               ) : (
                  <Link to={`${url}/${feedback.id}`}>
                     {feedback.vacancy_data.name}
                  </Link>
               )}
            </td>
            <td>
               {feedback.vacancy_data.status === status.DISABLED ? (
                  <span className="text-red">Вакансия заблокирована</span>
               ) : feedback.resume_data.status === status.DISABLED ? (
                  <span className="text-red">Ваше резюме заблокировано</span>
               ) : feedback.status === 50 ? (
                  <span className="text-light">В архиве</span>
               ) : feedback.status === 5 || feedback.status === -1 ? (
                  <span className="text-red">Отказано</span>
               ) : feedback.status === 10 || feedback.status === -2 ? (
                  <span className="text-green">Принятые</span>
               ) : (
                  <span className="text-blue">Отклик отправлен</span>
               )}
            </td>
            <td>
               {feedback.vacancy_data.status !== status.DISABLED &&
                  feedback.resume_data.status !== status.DISABLED && (
                     <div className="btn-group">
                        <ButtonDelete
                           onClick={() => handleClickDelete(feedback.id)}
                        />
                     </div>
                  )}
            </td>
         </tr>
      );
   });

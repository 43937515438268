import React from 'react';
import {Plus} from "../../Icons/Plus";

export const FacultiesTableHead = ({ handleOpen }) => (
    <tr>
      <th scope="col">
        <div className="line">
            <span>Факультет</span>
            <button type="button" className="button button-green button--modal" aria-label="Добавить факультет" onClick={handleOpen}>
                <Plus />
            </button>
        </div>
      </th>
      <th scope="col">Специальность / направление</th>
      <th scope="col">Групп</th>
      <th scope="col">Студентов</th>
      <th scope="col">
        <span className="visually-hidden">Удалить</span>
      </th>
    </tr>
);

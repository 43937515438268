import React, {useState} from 'react';
import {DeleteIcon} from "../../../Icons/Delete";

export const EditPortfolio = ({portfolio, input}) => {
  const [AllPortfolio, setPortfolio] = useState(portfolio);
  const [allPortfolioToDeleted, setAllPortfolioToDeleted] =  useState([]);

  const handleDelete = (e, id) => {
    e.preventDefault();

     let deleteFromState = AllPortfolio.filter(item => item.id !== id);
     let deletedPortfolio = AllPortfolio.filter(item => item.id === id);
     let allDeletedPortfolio = [...allPortfolioToDeleted, ...deletedPortfolio]

     setAllPortfolioToDeleted(allDeletedPortfolio);
     input.onChange(allDeletedPortfolio);
     setPortfolio(deleteFromState);
  };

  return (
     AllPortfolio.map((image) => {
        return <div className="img-wrap relative" key={image.id}>
          <img src={image.path} alt=""/>
          <button aria-label="Удалить" className="button-transparent" onClick={(e) => handleDelete(e, image.id)}>
            <DeleteIcon />
          </button>
        </div>
      })
  )
};

import React from 'react';
import {Field, reduxForm} from "redux-form";
import validate from "../../FormElement/validate";
import {FieldSelect} from "../../FormElement/Field/FieldSelect";
import {FieldInput} from "../../FormElement/Field/FieldInput";
import {optionsTraining, optionsStudy} from "../../FormElement/Options";

const GroupsForm = ({handleSubmit}) => (
    <form className="form" onSubmit={e => handleSubmit(e)}>
        <div className="item item-mb">
            <label className="form__label" htmlFor="name">Группа</label>
            <Field
                id="name"
                name="name"
                type="text"
                placeholder="Введите название"
                component={FieldInput} />
        </div>

        <div className="item item-mb">
            <label className="form__label" htmlFor="training_form">Квалификация</label>
            <Field
                name="training_form"
                placeholder="Квалификация"
                options={optionsTraining}
                component={FieldSelect}
                id="training_form"
            />
        </div>

        <div className="item item-mb">
            <label className="form__label" htmlFor="study_form">Форма обучения</label>
            <Field
                name="study_form"
                placeholder="Форма обучения"
                options={optionsStudy}
                component={FieldSelect}
                id="study_form"
            />
        </div>


        <div className="item item-mb">
            <label className="form__label" htmlFor="start">Год начала обучения</label>
            <Field
                id="start"
                name="start"
                type="number"
                placeholder="Год"
                component={FieldInput} />
        </div>

        <div className="item">
            <label className="form__label" htmlFor="end">Конец обучения</label>
            <Field
                id="end"
                name="end"
                type="number"
                placeholder="Год"
                component={FieldInput} />
        </div>

        <button className={"button button-green-gradient button-bold button-upper button-small button-indent "}>добавить / сохранить </button>
    </form>
);

export default reduxForm({
  form: 'groups',
  validate
})(GroupsForm)

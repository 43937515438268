import React from "react";

const FieldTextarea = ({ id, input, placeholder, disabled, maxlength, meta: { touched, error } }) => (
    <>
      <textarea
             className="form-control-textarea"
             {...input}
             id={id}
             maxLength={maxlength}
             disabled={disabled}
             placeholder={placeholder} />

      { touched && error && <span className='hasError'>{error}</span> }
    </>

);

export default FieldTextarea;

import { studentsConstants } from "../constants/resumeConstats";

const initialStateMedia = {
   isLoading: false,
   files: [],
   documents: [],
   portfolio: [],
};

export function media(state = initialStateMedia, action) {
   switch (action.type) {
      case "IS_LOADING_MEDIA":
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case "RETURN_MEDIA":
         return {
            ...state,
            isLoading: false,
            files: [...state.files, action.files],
         };

      case studentsConstants.GET_PORTFOLIO_SUCCESS:
         return {
            ...state,
            isLoading: false,
            portfolio: action.files,
         };

      case studentsConstants.GET_DOCUMENTS_SUCCESS:
         return {
            ...state,
            isLoading: false,
            documents: action.files,
         };

      case studentsConstants.GET_DOCUMENTS_FAILURE:
         return {
            ...state,
            isLoading: false,
            documents: [],
         };

      case studentsConstants.GET_PORTFOLIO_FAILURE:
         return {
            ...state,
            isLoading: false,
            portfolio: [],
         };

      case studentsConstants.DELETE_PORTFOLIO:
         return {
            ...state,
            portfolio: state.portfolio.filter(
               (item) => item.id !== action.file
            ),
         };

      case studentsConstants.DELETE_DOCUMENTS:
         return {
            ...state,
            documents: state.documents.filter(
               (item) => item.id !== action.file
            ),
         };

      case studentsConstants.POST_DOCUMENTS:
         return {
            ...state,
            documents: [...state.documents, action.file],
         };

      case studentsConstants.POST_PORTFOLIO:
         return {
            ...state,
            portfolio: [...state.portfolio, action.file],
         };

      default:
         return state;
   }
}

import React, {Component} from "react";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {customStyles} from "./FieldSelect";

const createOption = (label) => ({
   label,
   value: label,
});

const components = {
   DropdownIndicator: null,
};

class FieldCreate extends Component {
   constructor(props) {
      super(props);
      this.state = {
         inputValue: '',
         value: props.input.value,
      }

   }

   handleChange = (value) => {
      this.setState({value});
      console.log('change ',this.state)



   };

   handleInputChange = (inputValue) => {
      this.setState({inputValue});
      const value = this.state.value;
      const {input} = this.props;
      input.onChange(value)
      console.log('inpput change ',this.state)

   };

   handleKeyDown = (event) => {
      const {inputValue, value} = this.state;
      if (!inputValue) return;
      switch (event.key) {
         case 'Enter':
         case 'Tab':
            this.setState({
               inputValue: '',
               value: value !== null ? [...value, createOption(inputValue)] : [createOption(inputValue)],
            });
            event.preventDefault();
      }

   };

   onBlur = (event) => {
      // createOption(event.target.value);
      
      const {inputValue, value} = this.state;
      const {input} = this.props;


      if (!inputValue) return;
      this.setState({
         inputValue: '',
         value: value !== null ? [...value, createOption(inputValue)] : [createOption(inputValue)],
      });

      input.onBlur(value);


      console.log('blur ',this.state)
   };

   render() {
      const {inputValue, value} = this.state;
      const {id, input, meta: {touched, error}} = this.props;

      return (
         <>
            <CreatableSelect
               styles={customStyles}
               inputId={id}
               {...input}
               components={components}
               inputValue={inputValue}
               isMulti
               menuIsOpen={false}
               onChange={this.handleChange}
               onInputChange={this.handleInputChange}
               onKeyDown={this.handleKeyDown}
               onBlur={() => input.onBlur(input.value)}
               // onBlur={this.onBlur}
               // onBlur={event => event.preventDefault()}
               placeholder="Начните вводить"
               value={value}
            />
            {touched && error && <span className='hasError'>{error}</span>}
         </>
      )
   }
}

export default FieldCreate;

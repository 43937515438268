import React from "react";
import {Field, reduxForm, FieldArray} from 'redux-form';
import {SelectFieldMulti} from '../../FormElement/Field/SelectFieldMulti';
import {FieldPortfolioDrop} from "../../FormElement/Field/FieldPortfolioDrop";
import {FieldDocumentsDrop} from "../../FormElement/Field/FieldDocumentsDrop";
import {FieldDaData} from "../../FormElement/Field/FieldDaData";
import {FieldInput} from '../../FormElement/Field/FieldInput';
import FieldCreate from '../../FormElement/Field/FieldCreate';
import {LayoutsWorkExperience} from "./WorkExperiences/LayoutsWorkExperience";
import validate from "../../FormElement/validate";
import {typeEmployment} from "../../FormElement/Options";
import FieldTextarea from "../../FormElement/Field/FieldTextarea";
import {EditPortfolio} from "./EditResume/EditPortfolio";
import {EditDocuments} from "./EditResume/EditDocuments";
import {FieldContainerThreeSelect} from "../../FormElement/Field/FieldContainerThreeSelect";

const ResumeForm = ({data, handleSubmit, files, edit}) => {
   return (
      <form className="form" onSubmit={e => handleSubmit(e)}>
         <div className="form__item form__item--custom">
            <span className="form__label form__span">Профессиональная область</span>
            <Field
               name="category"
               data={data}
               component={FieldContainerThreeSelect}
            />
         </div>

         <div className="form__item form__item--custom">
            <label className="form__label">
               <span>Город проживания</span>
               <Field
                  name="city"
                  type="text"
                  component={FieldDaData}
               />
            </label>
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="university_id">Университет</label>
            <Field
               id='university_id'
               name="university_id"
               component={FieldInput}
               props={{disabled: true}}
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="post">Должность</label>

            <Field
               id="post"
               name="post"
               type="text"
               text={true}
               component={FieldInput}
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="salary">Зарплата</label>
            <Field
               id="salary"
               label="Зарплата"
               name="salary"
               type="number"
               placeholder="от"
               component={FieldInput}
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="type_employment">Тип занятости</label>
            <Field
               placeholder="Выберите"
               id='type_employment'
               name="type_employment"
               options={typeEmployment}
               component={SelectFieldMulti}
            />
         </div>

         <fieldset>
            <legend className="title-inter-medium">Готовность к командировкам</legend>

            <div className="flex-element form__line">
               <div className="form__item form__item--mr">
                  <Field
                     name="trip"
                     type="radio"
                     component="input"
                     value="Не готов"
                     id="not-ready"
                     className="radio-custom"
                  />
                  <label htmlFor="not-ready" className="radio-label mb-none"><span>Не готов</span></label>
               </div>

               <div className="form__item form__item--mr">
                  <Field
                     name="trip"
                     type="radio"
                     component="input"
                     value="Готов"
                     id="ready"
                     className="radio-custom"
                  />
                  <label htmlFor="ready" className="radio-label mb-none"><span>Готов</span></label>
               </div>

               <div className="form__item form__item--mr">
                  <Field
                     name="trip"
                     type="radio"
                     component="input"
                     value="Иногда"
                     id="smt"
                     className="radio-custom"
                  />
                  <label htmlFor="smt" className="radio-label mb-none"><span>Иногда</span></label>
               </div>

            </div>
         </fieldset>

         <fieldset>
            <legend className="title-inter-medium">Переезд</legend>

            <div className="flex-element form__line">
               <div className="form__item form__item--mr">
                  <Field
                     name="relocation"
                     type="radio"
                     component="input"
                     value="Невозможен"
                     id="impossible"
                     className="radio-custom"
                  />
                  <label htmlFor="impossible" className="radio-label mb-none"><span>Невозможен</span></label>
               </div>

               <div className="form__item form__item--mr">
                  <Field
                     name="relocation"
                     type="radio"
                     component="input"
                     value="Возможен"
                     id="available"
                     className="radio-custom"
                  />
                  <label htmlFor="available" className="radio-label mb-none"><span>Возможен</span></label>
               </div>

            </div>
         </fieldset>

         <div className="form__item">
            <label className="form__label" htmlFor="citizenship">Гражданство</label>

            <Field
               id="citizenship"
               name="citizenship"
               type="text"
               component={FieldInput}
            />
         </div>

         <div className="form__item">
            <div className="work-experience">
               <fieldset>
                  <legend>Опыт работы</legend>

                  <FieldArray name="work" component={LayoutsWorkExperience}/>

               </fieldset>
            </div>

         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="about">Расскажите о себе</label>
            <Field
               id="about"
               name="about"
               component={FieldTextarea}
               type="text"
               placeholde="Описание"
               className="form-control-textarea"
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="keywords">Ключевые навыки</label>
            <Field
               placehoder="Начните вводить"
               id='keywords'
               name="keywords"
               component={FieldCreate}
            />
         </div>

         <div className="form__item">
            <div className="wrap-media wrap-media--border">
               <div>
                  <h3 className="mb title-inter-medium">Документы</h3>
                  <p className="text min dark">Формат: pptx, doc, docx, pdf</p>
                  <p className="text min dark">Общее количество документов не больше 15. Каждый файл не должен превышать
                     10 мб</p>
               </div>

               <div className="documents-content">
                  <Field
                     name="docs"
                     component={FieldDocumentsDrop}
                  />

                  {
                     edit &&
                     <Field
                        documents={files.documents}
                        name="delete_documents"
                        component={EditDocuments}/>
                  }
               </div>
            </div>
         </div>

         <div className="form__item">
            <div className="wrap-media">
               <h3 className="mb title-inter-medium">Портфолио</h3>
               <p className="text min dark">Общее количество фотографий не больше 15. Каждая фотография должна быть не
                  больше 10 мб</p>
            </div>
            <div className="wrapper-images flex-element">
               <Field
                  name="portfolio"
                  component={FieldPortfolioDrop}
               />
               {edit &&
                  <Field
                     portfolio={files.portfolio}
                     name="delete_portfolio"
                     component={EditPortfolio}/>
               }
            </div>
         </div>

         <button className="button button-green-gradient button-indent button-bold">Продолжить</button>
      </form>
   )
};

export default reduxForm({
   form: 'resume',
   validate,
   enableReinitialize: true
})(ResumeForm)



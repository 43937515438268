import React from "react";
import {Field} from "redux-form";
import {FieldInput} from "../../../FormElement/Field/FieldInput";

export const FormStudent = ({handleSubmit, disabled, error}) => {
  return (
      <form className="form" onSubmit={handleSubmit}>
        <fieldset>
          <legend>Личные данные</legend>
          <div className="form__line flex-element">

            <Field name="groups_id" component="input" type="hidden" />
            <Field name="student_id" component="input" type="hidden" />
            <Field name="uid" component="input" type="hidden" />

            <div className="form__item">
              <label className='visually-hidden' htmlFor="student_ticket">Номер зачетки</label>
              <Field
                  id="student_ticket"
                  name="student_ticket"
                  type="text"
                  placeholder="Номер зачетки"
                  component={FieldInput} />
            </div>


            <div className="form__item">
              <label className='visually-hidden' htmlFor="username">Имя</label>
              {disabled ?
                  <Field
                      props={{disabled: true}}
                      id="username"
                      name="username"
                      type="text"
                      placeholder="Имя"
                      component={FieldInput} />
                  :
                  <Field
                      id="username"
                      name="username"
                      type="text"
                      placeholder="Имя"
                      component={FieldInput} />
              }


            </div>

            <div className="form__item">
              <label className='visually-hidden' htmlFor="surname">Фамилия</label>
              {disabled ?
                  <Field
                      props={{disabled: true}}
                      id="surname"
                      name="surname"
                      type="text"
                      placeholder="Фамилия"
                      component={FieldInput}/>
                  :
                  <Field
                      id="surname"
                      name="surname"
                      type="text"
                      placeholder="Фамилия"
                      component={FieldInput}/>
              }
            </div>

            <div className="form__item">
              <label className='visually-hidden' htmlFor="patronymic">Отчество</label>
              {disabled ?
                  <Field
                      props={{disabled: true}}
                      id="patronymic"
                      name="patronymic"
                      type="text"
                      placeholder="Отчество"
                      component={FieldInput}/>
                  :
                  <Field
                      id="patronymic"
                      name="patronymic"
                      type="text"
                      placeholder="Отчество"
                      component={FieldInput}/>
              }
            </div>

            <div className="form__item">
              <label className='visually-hidden' htmlFor="course">Курс</label>
              <Field
                  props={{disabled: true}}
                  id="course"
                  name="course"
                  type="number"
                  placeholder='Курс'
                  component={FieldInput} />
            </div>

            {error && <span className="hasError hasError--pb">{error}</span>}

            <div className="form__item">
              <button type="submit" className="button button-green-gradient button-bold button-indent">Сохранить</button>
            </div>
          </div>
        </fieldset>
      </form>
  )
};

import React from 'react';
import {userActions} from "../../actions/AuthAction";
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validate from "../FormElement/validate";
import {FieldInput} from "../FormElement/Field/FieldInput";

const RestorePasswordForm = ({handleSubmit, error}) => {
  return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__item">
          <label className="form__label" htmlFor="email">Введите e-mail:</label>
          <div className="item">
            <div className="item__group">
              <Field
                 id="email"
                 name="email"
                 type="text"
                 component={FieldInput}  />
            </div>
          </div>
          {error && <strong>{error}</strong>}
        </div>
        <button
            className="button button-green-gradient button-small button-upper button-indent button-bold">Продолжить
        </button>
      </form>
  )
};

export default connect(null, {
  onSubmit: userActions.restorePassword,
})(reduxForm({
  form: 'restore-password',
  validate
})(RestorePasswordForm))

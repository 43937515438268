import React, {Component} from 'react';
import {getTemporalPassword} from "../../../actions/Universtiy/getTemporalPassword";

import {connect} from "react-redux";
import {PasswordForStudentsList} from "./PasswordForStudentsList";
import {Helmet} from "react-helmet/es/Helmet";

class PasswordForStudents extends Component {
  componentDidMount() {
    let {user} = this.props;
    let university_id = user.user.university_local.id;

    this.props.getTemporalPassword(university_id);
  }

  render() {
    const temporalPassword = this.props.temporalPassword;
    return (
       <>
          <Helmet>
             <title>Получение паролей</title>
          </Helmet>
          <div className="table-responsive">
             <table className="table table--upper">
               <thead>
                 <tr>
                   <th scope="col">Фамилия</th>
                   <th scope="col">Номер зачетной книжки</th>
                   <th scope="col">Временный пароль</th>
                 </tr>
               </thead>
               <tbody>
                 <PasswordForStudentsList data={temporalPassword} />
               </tbody>
             </table>
           </div>
       </>
    )
  }
}


const mapDispatchToProps = {
  getTemporalPassword
};

const mapStateToProps = state => {
  return {
    user: state.authentication,
    temporalPassword: state.temporalPassword
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForStudents);

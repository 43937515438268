import React from 'react';
import {Plus} from "../../Icons/Plus";

export const GroupTableHead = ({ handleOpen }) => (
   <tr>
     <th scope="col">
       <div className="line">
           <span>Группа</span>
           <button type="button" className="button button-green button--modal" aria-label="Добавить Группу" onClick={handleOpen}>
              <Plus />
           </button>
       </div>
     </th>
     <th scope="col">Квалификация</th>
     <th scope="col">Форма обучения</th>
     <th scope="col">Курс</th>
     <th scope="col">Студентов</th>
     <th scope="col">
       <span className="visually-hidden">Удалить</span>
     </th>
   </tr>
);

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Обязательное поле'
  }

  if (!values.username) {
    errors.username = 'Обязательное поле'
  }

  if (!values.surname) {
    errors.surname = 'Обязательное поле'
  }

  if (!values.course) {
    errors.course = 'Обязательное поле'
  }

  if (!values.company_type) {
    errors.company_type = 'Обязательное поле'
  }

  if (!values.about) {
    errors.about = 'Обязательное поле'
  }

  if (!values.start_year) {
    errors.start_year = 'Обязательное поле'
  }

  if (!values.message) {
    errors.message = 'Обязательное поле'
  }

  if (!values.university_id) {
    errors.university_id = 'Обязательное поле'
  }

  if (!values.phone) {
    errors.phone = 'Обязательное поле'
  }

  if (!values.rector) {
    errors.rector = 'Обязательное поле'
  }

  if (!values.ticket) {
    errors.ticket = 'Обязательное поле'
  }

  if (!values.old) {
    errors.old = 'Обязательное поле'
  }

  if (!values.city) {
    errors.city = 'Обязательное поле'
  }

  if (!values.since) {
    errors.since = 'Обязательное поле'
  }

  if (!values.location) {
    errors.location = 'Обязательное поле'
  }

  if (!values.legal_address) {
    errors.legal_address = 'Обязательное поле'
  }

  if (!values.reception_phone) {
    errors.reception_phone = 'Обязательное поле'
  }

  if (!values.main_address) {
    errors.main_address = 'Обязательное поле'
  }

  if (!values.site) {
    errors.site = 'Обязательное поле'
  }

  if (!values.code) {
    errors.code = 'Обязательное поле'
  }

  if (!values.start) {
    errors.start = 'Поле обязательное'
  }

  if (!values.end) {
    errors.end = 'Поле обязательное'
  }

  if (!values.study_form) {
    errors.study_form = 'Поле обязательное'
  }

  if (!values.training_form) {
    errors.training_form = 'Поле обязательное'
  }

  if (!values.student_ticket) {
    errors.student_ticket = 'обязательное поле'
  }

  if (!values.patronymic) {
    errors.patronymic = 'Обязательное поле'
  }

  if (!values.post) {
    errors.post = 'Обязательное поле'
  }

  if (!values.address) {
    errors.address = 'Обязательное поле'
  }

  if (!values.work_time) {
    errors.work_time = 'Обязательное поле'
  }

  if (!values.type_employment) {
    errors.type_employment = 'Обязательное поле'
  }

  if (!values.description) {
    errors.description = 'Обязательное поле'
  }

  if (!values.citizenship) {
    errors.citizenship = 'Обязательное поле'
  }

  if (!values.relocation) {
    errors.relocation = 'Обязательное поле'
  }

  if (!values.trip) {
    errors.relocation = 'Обязательное поле'
  }

  if (!values.salary) {
    errors.salary = 'Обязательное поле'
  }

  if (!values.birthday) {
    errors.birthday = 'Обязательное поле'
  }

  if (!values.keywords) {
    errors.keywords = 'Обязательное поле'
  }

  if (!values.password) {
    errors.password = 'Обязательное поле'
  }

  if (!values.pass) {
    errors.pass = 'Обязательное поле'
  }

  if (!values.new_pass) {
    errors.new_pass = 'Обязательное поле'
  }

  if (!values.email) {
    errors.email = 'Обязательное поле'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Неверный e-mail'
  }

   if (!values.category) {
      errors.category = 'Обязательное поле';
   }

  if (!values.endowment_fund) {
    errors.endowment_fund = 'Обязательное поле';
  }

  /* excel file */

  if (!values.name_start) {
    errors.name_start = 'Обязательное поле';
  }

  if (!values.ticket_start) {
    errors.ticket_start = 'Обязательное поле';
  }

  if (!values.mark_start) {
    errors.mark_start = 'Обязательное поле';
  }

  if (!values.file) {
    errors.file = 'Обязательное поле';
  }

  if (!values.training_start) {
    errors.training_start = 'Обязательное поле';
  }

  if (!values.study_start) {
    errors.study_start = 'Обязательное поле';
  }

  if (!values.start_start) {
    errors.start_start = 'Обязательное поле';
  }

  if (!values.end_start) {
    errors.end_start = 'Обязательное поле';
  }

  if (!values.surname_start) {
    errors.surname_start = 'Обязательное поле';
  }

  if (!values.name_start) {
    errors.name_start = 'Обязательное поле';
  }

  if (!values.patronymic_start) {
    errors.patronymic_start = 'Обязательное поле';
  }

  if (!values.ticket_start) {
    errors.ticket_start = 'Обязательное поле';
  }

  if (!values.type) {
     errors.type = 'Обязательное поле'
  }

   if (!values.mark) {
      errors.mark = 'Обязательное поле'
   }

   if (!values.code_start) {
      errors.code_start = 'Обязательное поле'
   }

   if (!values.faculty_id) {
      errors.faculty_id = 'Обязательное поле'
   }

   if (!values.requirements) {
      errors.requirements = 'Обязательное поле'
   }

   if (!values.vacancy_id) {
      errors.vacancy_id = 'Обязательное поле'
   }

   if (!values.duties) {
      errors.duties = 'Обязательное поле'
   }

   if (!values.conditions) {
      errors.conditions = 'Обязательное поле'
   }

   if (!values.salary_from) {
      errors.salary_from = 'Обязательное поле'
   }

   if (!values.salary_up) {
      errors.salary_up = 'Обязательное поле'
   }

   if (values.work) {
      const workExperienceArrayErrors = [];

      values.work.forEach((experience, experienceIndex) => {
         const experienceErrors = {};

         if (!experience || !experience.organization_name) {
            experienceErrors.organization_name = 'Обязательное поле';
            workExperienceArrayErrors[experienceIndex] = experienceErrors;
         }

         if (!experience || !experience.post) {
            experienceErrors.post = 'Обязательное поле';
            workExperienceArrayErrors[experienceIndex] = experienceErrors;
         }

         if (!experience || !experience.responsibility) {
            experienceErrors.responsibility = 'Обязательное поле';
            workExperienceArrayErrors[experienceIndex] = experienceErrors;
         }

         if (!experience || !experience.start_month) {
            experienceErrors.startDate = 'Обязательное поле';
            workExperienceArrayErrors[experienceIndex] = experienceErrors;
         }

         if (!experience || !experience.start) {
            experienceErrors.start = 'Обязательное поле';
            workExperienceArrayErrors[experienceIndex] = experienceErrors;
         }

         if (!experience || !experience.start_month) {
            experienceErrors.start_month = 'Обязательное поле';
            workExperienceArrayErrors[experienceIndex] = experienceErrors;
         }
      });

      if (workExperienceArrayErrors.length) {
         errors.work = workExperienceArrayErrors;
      }
   }

  return errors;
};


export default validate;

import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import FormSpeciality from './FormSpeciality';
import {SpecialtyList} from "./SpecialityBodyList";
import {SpecialityTableHead} from "./SpecialityTableHead";
import {Pagination} from "../../Pagination/Pagination";
import {
  getSpeciality,
  addSpeciality,
  deleteSpecialty,
} from '../../../actions/Universtiy/SpecialtyAction';
import {Preloader} from "../../Preloader/Preloader";
import {CloseIcon} from "../../Icons/Close";
import {useRouteMatch} from "react-router-dom";
import {getModalStyle} from "../../Modals/getModalStyle";
import {Helmet} from "react-helmet/es/Helmet";

export const SpecialityContainer = () => {
  const {url, params} = useRouteMatch();

  const idSpeciality = params.id;
  const dispatch = useDispatch();
  const specialty= useSelector(state => state.specialty);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {setOpen(true)};
  const handleClose = () => {setOpen(false)};


  const handleClickDelete = (id) => {
    dispatch(deleteSpecialty(id));
  };

  const handleSubmit = (values) => {
    values.faculty_id = idSpeciality;
    dispatch(addSpeciality(values));
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getSpeciality(idSpeciality, 1))
  },[idSpeciality, dispatch]);

  const onSetPage = (page) => {
    dispatch(getSpeciality(idSpeciality, page))
  };

  return (
     <>
        <Helmet>
           <title>Специальности</title>
        </Helmet>
        {
           specialty.isLoading || specialty.pagination === undefined ? <Preloader/> :
              <>
                 <h1 className="title-bold dark display-large">База студентов</h1>
                 <div className="table-responsive">
                    <table className="table table--upper">
                       <thead>
                       <SpecialityTableHead handleOpen={handleOpen}/>
                       </thead>
                       <tbody>
                       <SpecialtyList url={url} data={specialty.specialties} handleClickDelete={handleClickDelete}/>
                       </tbody>
                    </table>
                 </div>

                 <Pagination pageCount={specialty.pagination.pageCount} currentPage={specialty.pagination.currentPage}
                             onSetPage={onSetPage}/>

                 <Modal
                    className="modal-base"
                    aria-labelledby="modal-title"
                    open={open}
                    onClose={handleClose}>

                    <div style={getModalStyle} className="modal-content">
                       <div className="modal-header">
                          <h5 id="modal-title" className="dark">Специальность/направление</h5>
                          <button type="button" className="button button-transparent" aria-label="Закрыть"
                                  onClick={handleClose}>
                             <CloseIcon/>
                          </button>
                       </div>

                       <div className="modal-body">
                          <FormSpeciality onSubmit={(values) => {
                             handleSubmit(values)
                          }}/>
                       </div>
                    </div>
                 </Modal>
              </>
        }
     </>
  )
};

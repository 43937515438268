import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";
import { MainResumeListContainer } from "../components/ResumeList/MainResumeList/MainResumeListContainer";
import { MainResumeCardContainer } from "../components/ResumeCard/MainResumeCardContainer";

export const ResumePage = ({ match }) => (
   <div className="inner-page inner-page--pb">
      <Helmet>
         <title>Резюме</title>
      </Helmet>
      <div className="container">
         <Route exact path={match.path} component={MainResumeListContainer} />
         <Route
            path={`${match.url}/:resumeId`}
            component={MainResumeCardContainer}
         />
      </div>
   </div>
);

import { universityConstants } from "../constants/universityConstants";
import { homeConstants } from "../constants/homeConstants";
import { studentsConstants } from "../constants/studentsConstatns";

export function temporalPassword(state = [], action) {
   switch (action.type) {
      case universityConstants.GET_TEMPORAL_SUCCESS:
         return action.students;
      case universityConstants.GET_TEMPORAL_FAILURE:
         return [];
      default:
         return state;
   }
}

const stateFaculties = {
   isLoading: false,
   faculties: [],
   pagination: {
      totalCount: 0,
      pageCount: 0,
      currentCount: 0,
   },
};

export function faculties(state = stateFaculties, action) {
   switch (action.type) {
      case universityConstants.IS_LOADING_FACULTIES:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case universityConstants.ADD_FACULTY_SUCCESS:
         return {
            ...state,
            faculties: [...state.faculties, action.faculty],
         };

      case universityConstants.DELETE_FACULTY_SUCCESS:
         return {
            ...state,
            faculties: state.faculties.filter((id) => id.id !== action.faculty),
         };

      case universityConstants.GET_FACULTIES_SUCCESS:
         return {
            ...state,
            isLoading: false,
            faculties: action.faculties,
            pagination: action.pagination,
         };

      case universityConstants.GET_FACULTIES_FAILURE:
         return {
            isLoading: false,
            faculties: [],
            pagination: {
               totalCount: 0,
               pageCount: 0,
               currentCount: 0,
            },
         };

      default:
         return state;
   }
}

const stateSpecialties = {
   isLoading: false,
   specialties: [],
   pagination: {
      totalCount: 0,
      pageCount: 0,
      currentCount: 0,
   },
};

export function specialty(state = stateSpecialties, action) {
   switch (action.type) {
      case universityConstants.IS_LOADING_SPECIALTIES:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case universityConstants.ADD_SPECIALTY_SUCCESS:
         return {
            ...state,
            specialties: [...state.specialties, action.specialty],
         };

      case universityConstants.DELETE_SPECIALTY_SUCCESS:
         return {
            ...state,
            specialties: state.specialties.filter(
               (id) => id.id !== action.specialty
            ),
         };

      case universityConstants.GET_SPECIALTIES_SUCCESS:
         return {
            isLoading: false,
            specialties: action.specialties,
            pagination: action.pagination,
         };

      case universityConstants.GET_SPECIALTIES_FAILURE:
         return {
            ...state,
            isLoading: false,
            specialties: [],
            pagination: {
               totalCount: 0,
               pageCount: 0,
               currentCount: 0,
            },
         };
      default:
         return state;
   }
}

// группы
const stateGroups = {
   isLoading: false,
   groups: [],
   pagination: {
      totalCount: 0,
      pageCount: 0,
      currentCount: 0,
   },
};
export function groups(state = stateGroups, action) {
   switch (action.type) {
      case universityConstants.IS_LOADING_GROUPS:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case universityConstants.ADD_GROUP_SUCCESS:
         return {
            ...state,
            groups: [...state.groups, action.group],
         };

      case universityConstants.DELETE_GROUP_SUCCESS:
         return {
            ...state,
            groups: state.groups.filter((id) => id.id !== action.group),
         };

      case universityConstants.GET_GROUPS_SUCCESS:
         return {
            ...state,
            groups: action.groups,
            isLoading: false,
            pagination: action.pagination,
         };

      case universityConstants.GET_GROUPS_FAILURE:
         return {
            ...state,
            isLoading: false,
            groups: [],
            pagination: {
               totalCount: 0,
               pageCount: 0,
               currentCount: 0,
            },
         };
      default:
         return state;
   }
}

// IS_LOADING_STUDENTS
const initialStateStudents = {
   isLoading: false,
   students: [],
   pagination: {
      pageCount: 0,
      currentCount: 0,
   },
};

//добавление студента и список студента
export function students(state = initialStateStudents, action) {
   switch (action.type) {
      case studentsConstants.IS_LOADING_STUDENTS:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case studentsConstants.ADD_STUDENT_SUCCESS:
         return state;

      case studentsConstants.GET_STUDENTS_SUCCESS:
         return {
            students: action.students,
            isLoading: false,
            pagination: action.pagination,
         };

      case homeConstants.GET_LIST_STUDENTS_SEARCH_SUCCESS:
         return {
            students: action.students,
            isLoading: false,
         };

      case studentsConstants.GET_STUDENTS_FAILURE:
      case homeConstants.GET_LIST_STUDENTS_SEARCH_FAILURE:
         return {
            students: [],
            isLoading: false,
            pagination: {
               pageCount: 0,
               currentCount: 0,
            },
         };

      case studentsConstants.DELETE_STUDENT_SUCCESS:
         return {
            ...state,
            students: state.students.filter((id) => id.id !== action.id),
         };

      default:
         return state;
   }
}

const initialStateStudent = {
   student: {},
   user: {},
   isLoading: false,
};

export function student(state = initialStateStudent, action) {
   switch (action.type) {
      case studentsConstants.IS_LOADING_STUDENTS_INFORMATION:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case studentsConstants.GET_INFORMATION_STUDENT_SUCCESS:
         return {
            student: action.infoStudent,
            user: action.userInfo,
            isLoading: false,
         };

      case studentsConstants.PATCH_STUDENT_SUCCESS:
         return {
            ...state,
            student: Object.assign({}, state.student, action.student),
         };

      case studentsConstants.GET_INFORMATION_STUDENT_FAILURE:
         return {};
      default:
         return state;
   }
}

//оценки
export function assessments(state = [], action) {
   switch (action.type) {
      case universityConstants.ADD_SUBJECT_SUCCESS:
         return [...state, action.subject];
      case universityConstants.GET_SUBJECTS_SUCCESS:
         return action.subjects;
      case universityConstants.GET_SUBJECTS_FAILURE:
         return [];
      case universityConstants.DELETE_SUBJECT:
         return state.filter((id) => id.id !== action.id);

      case universityConstants.EDIT_SUBJECTS_SUCCESS:
         return state.map((item) =>
            item.id === action.subject.id ? action.subject : item
         );
      default:
         return state;
   }
}

//настрйоки
export function settingUniversity(state = {}, action) {
   switch (action.type) {
      case universityConstants.GET_UNIVERSITY_SETTINGS:
         return action.settings;
      case universityConstants.CHANGE_UNIVERSITY_SETTINGS:
         return action.settings;
      default:
         return state;
   }
}

import React, {useState} from "react";
import {Field, reduxForm} from 'redux-form';
import validate from "../../FormElement/validate";
import {workTime, typeEmployment} from "../../FormElement/Options";
import {SelectFieldMulti} from "../../FormElement/Field/SelectFieldMulti";
import {FieldDaData} from "../../FormElement/Field/FieldDaData";
import {FieldInput} from '../../FormElement/Field/FieldInput';
import FieldCreate from "../../FormElement/Field/FieldCreate";
import FieldTextarea from "../../FormElement/Field/FieldTextarea";
import {FieldContainerThreeSelect} from "../../FormElement/Field/FieldContainerThreeSelect";

const VacancyForm = ({handleSubmit, data, initialValues}) => {
   const [salaryUndefined, setSalary] = useState(Boolean(initialValues.salary_undefined));

   const handleChange = (e) => {
      setSalary(e.target.checked);
   };

   return (
      <form className="form" onSubmit={e => handleSubmit(e)}>

         <div className="form__item form__item--custom">
            <span className="form__label form__span">Профессиональная область:</span>

            <Field
               name="category"
               placeholder="Введите"
               data={data}
               component={FieldContainerThreeSelect}
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="name">Название Вакансии</label>

            <Field
               id="name"
               name="name"
               type="text"
               component={FieldInput}
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="post">Должность</label>

            <Field
               id="post"
               name="post"
               type="text"
               text={true}
               component={FieldInput}
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="description">Описание</label>
            <Field
               id="description"
               name="description"
               component={FieldTextarea}
               type="text"
               placeholde="Описание"
               className="form-control-textarea"
            />

         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="duties">Обязанности</label>
            <Field
               id="duties"
               name="duties"
               component={FieldTextarea}
               type="text"
               placeholde="Обязанности"
               className="form-control-textarea"
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="requirements">Требования</label>
            <Field
               id="requirements"
               name="requirements"
               component={FieldTextarea}
               type="text"
               placeholde="Требования"
               className="form-control-textarea"
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="conditions">Условия</label>
            <Field
               id="conditions"
               name="conditions"
               component={FieldTextarea}
               type="text"
               placeholde="Требования"
               className="form-control-textarea"
            />
         </div>

         <fieldset>
            <legend className="title-inter-medium">Зарплата</legend>

            <div className="flex-element form__line flex-element--align-center">
               {salaryUndefined !== true  && (
                  <>
                     <div className="form__item form__item--w form__item--mr">
                        <label className="visually-hidden" htmlFor="salary_from">От</label>

                        <Field
                           name="salary_from"
                           id="salary_from"
                           component={FieldInput}
                           type="number"
                           placeholder="от"
                        />
                        <span className="icon" aria-hidden="true">₽</span>
                     </div>

                     <div className="form__item form__item--w form__item--mr">
                        <label className="visually-hidden" htmlFor="salary_up">До</label>
                        <Field
                           name="salary_up"
                           id="salary_up"
                           component={FieldInput}
                           type="number"
                           placeholder="до"

                        />
                        <span className="icon" aria-hidden="true">₽</span>
                     </div>
                  </>
               )}


               <div className="form__item">
                  <Field
                     name="salary_undefined"
                     id="salary_undefined"
                     component="input"
                     type="checkbox"
                     onChange={(e) => handleChange(e)}

                  />
                  <label className="label-custom mb-none" htmlFor="salary_undefined">
                     <span>Оплата по договоренности</span>
                  </label>
               </div>
            </div>

         </fieldset>

         <div className="form__item">
            <label className="form__label" htmlFor="keywords">Ключевые навыки</label>
            <Field
               placehoder="Начните вводить"
               id='keywords'
               name="keywords"
               component={FieldCreate}
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="type_employment">Тип занятости</label>
            <Field
               placeholder="Выберите"
               id='type_employment'
               name="type_employment"
               options={typeEmployment}
               component={SelectFieldMulti}
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="work_time">График работы</label>
            <Field
               placeholder="Выберите"
               id='work_time'
               name="work_time"
               options={workTime}
               component={SelectFieldMulti}
            />
         </div>

         <fieldset>
            <legend className="title-inter-medium">Требуемый опыт работы</legend>

            <div className="flex-element form__line">
               <div className="form__item form__item--mr">
                  <Field
                     name="work_experience"
                     type="radio"
                     component="input"
                     value="Нет опыта"
                     id="no-experience"
                     className="radio-custom"
                  />
                  <label htmlFor="no-experience" className="radio-label mb-none"><span>Нет опыта</span></label>
               </div>
               <div className="form__item form__item--mr">
                  <Field
                     name="work_experience"
                     type="radio"
                     component="input"
                     value="От 1 до 3 лет"
                     id="one-to-three-years"
                     className="radio-custom"
                  />
                  <label htmlFor="one-to-three-years" className="radio-label mb-none"><span>От 1 до 3 лет</span></label>
               </div>
               <div className="form__item form__item--mr">
                  <Field
                     name="work_experience"
                     type="radio"
                     component="input"
                     value="От 3 до 6 лет"
                     id="three-to-six-years"
                     className="radio-custom"
                  />
                  <label htmlFor="three-to-six-years" className="radio-label mb-none"><span>От 3 до 6 лет</span></label>
               </div>

               <div className="form__item">
                  <Field
                     name="work_experience"
                     type="radio"
                     component="input"
                     value="Более 6 лет"
                     id="more-than-six-years"
                     className="radio-custom"
                  />
                  <label htmlFor="more-than-six-years" className="radio-label mb-none"><span>Более 6 лет</span></label>
               </div>
            </div>
         </fieldset>


         <div className="form__item">
            <label className="form__label" htmlFor="city">Город</label>

            <Field
               id="city"
               name="city"
               type="text"
               component={FieldDaData}
            />
         </div>

         <div className="form__item">
            <label className="form__label" htmlFor="address">Адрес офиса</label>

            <Field
               id="address"
               name="address"
               type="text"
               component={FieldDaData}
            />
         </div>

         <button className="button button-green-gradient button-indent button-bold">
            Сохранить
         </button>
      </form>
   )
};

export default reduxForm({
   form: 'vacancy',
   validate,
   enableReinitialize: true,
})(VacancyForm)

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {typeEmployment, trip, relocation} from "../FormElement/Options";
import {FieldInput} from "../FormElement/Field/FieldInput";
import {FieldSelect} from "../FormElement/Field/FieldSelect";
import {FieldDaData} from "../FormElement/Field/FieldDaData";
import {FieldContainerThreeSelect} from "../FormElement/Field/FieldContainerThreeSelect";

const FilterResume = ({handleSubmit, data, universities}) => {
  const universitiesOption = universities.map(universities => {return ({value: universities.id, label: universities.abbreviation})});

  return (
      <form className="form" onSubmit={e => handleSubmit(e)}>
        <div className="form__group">
          <div className="form__item">
             <label className="form__label" htmlFor="name">Введите название резюме</label>
              <Field
                 id="name"
                  name="post"
                  type="text"
                  component={FieldInput}
                  placeholder="Введите название"
              />
          </div>

           <div className="form__item form__item--custom">
               <label className="form__label">
                  <span>Город проживания студента</span>

                  <Field
                      name="city"
                      type="text"
                      component={FieldDaData}
                  />
               </label>
          </div>

          <div className="form__item form__item--custom item--category">
            <span className="form__span form__label">Профессиональная область</span>
            <Field
                name="category"
                type="text"
                placeholder="Выберите проф область"
                data={data}
                component={FieldContainerThreeSelect}
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="university_id">Университет</label>
            <Field
                id='university_id'
                name="university_id"
                placeholder="Выберите"
                options={universitiesOption}
                component={FieldSelect}
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="type_employment">Тип занятости</label>
            <Field
                id="type_employment"
                name="type_employment"
                type="text"
                placeholder="Выберите"
                options={typeEmployment}
                component={FieldSelect}
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="trip">Готовность к командировкам</label>
            <Field
                id="trip"
                name="trip"
                type="text"
                placeholder="Выберите"
                options={trip}
                component={FieldSelect}
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="relocation">Переезд</label>
            <Field
                id="relocation"
                name="relocation"
                type="text"
                placeholder="Выберите"
                options={relocation}
                component={FieldSelect}
            />
          </div>

          <div className="form__item">
            <fieldset>
              <legend className="legend-custom">Зарплатные ожидания, руб</legend>
              <div className="flex-element form__inline">
                <div className="form__inline-item">
                  <label className="visually-hidden" htmlFor="salary_from">От</label>
                  <Field
                      id="salary_from"
                      name="salary_from"
                      type="number"
                      placeholder="От"
                      component={FieldInput}
                  />
                </div>
                <div className="form__inline-item">
                  <label className="visually-hidden" htmlFor="salary_up">До</label>
                  <Field
                      id="salary_up"
                      name="salary_up"
                      type="number"
                      placeholder="До"
                      component={FieldInput}
                  />
                </div>
              </div>

            </fieldset>
          </div>
        </div>

        <button className="button button-green-gradient button-bold button-small button-upper button-indent">Найти</button>
      </form>
  )
};

export default reduxForm({
  form: 'filter',
  enableReinitialize: true
})(FilterResume)

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PersonalDataStudentFormPatch from "./Form/PersonalDataStudentFormPatch";
import {SubjectsContainer} from "../Subjecs/SubjectsContainer";
import {studentInformation} from "../../../actions/Universtiy/StudentsAction";
import {Preloader} from "../../Preloader/Preloader";
import {Helmet} from "react-helmet/es/Helmet";
import {useRouteMatch} from "react-router-dom";

export const EditPersonalDataStudent = () => {
  const {params} = useRouteMatch();

  const dispatch = useDispatch();
  const studentId = params.studentId;
  const studentInfo = useSelector(state => state.student);

  useEffect(() => {
    dispatch(studentInformation(studentId))
  }, [dispatch, studentId]);

  const initialsState = {
    ...studentInfo.student.details,
    student_id: studentId,
    uid: studentInfo.student.uid,
    course: studentInfo.student.course
  };

  return (
      studentInfo.isLoading || Object.keys(studentInfo.student).length === 0 ? <Preloader/> :
      <>
        <Helmet>
          <title>Информация о студенте {`${studentInfo.student.details.surname} ${studentInfo.student.details.username}`}</title>
        </Helmet>
        <h1 className="title-bold dark display-large">{`${studentInfo.student.details.surname} ${studentInfo.student.details.username}`}</h1>

        <div className="container-content">
          <div className="bg-card bg-card--inner card-personal">
            <PersonalDataStudentFormPatch
                initialValues={initialsState}
            />
          </div>

          <div className="bg-card block-card bg-card-mt">
            <div className="block-card__head flex-element flex-element--align-center">
              <h3 className='mb-none'>Оценки</h3>
              <p className="mb-none" >Заполните оценки по каждому курсу</p>
            </div>
            <div className="block-card__foot">
              <SubjectsContainer studentId={studentId} />
            </div>
          </div>
        </div>
      </>
  )
};

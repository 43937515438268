import React from "react";
import { Route, NavLink, Redirect } from "react-router-dom";
import { RegistrationEmployee } from "../components/Registration/RegistrationEmployee/RegistrationEmployee";
import { RegistrationUniversity } from "../components/Registration/RegistrationUniversity/RegistrationUniversity";
import { useSelector } from "react-redux";

const Icon = () => (
   <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      fill="none"
   >
      <path
         d="M20.5 6.2l-1-.55-9-5h-.1a1.06 1.06 0 0 0-.19-.06h-.37a1.17 1.17 0 0 0-.2.06h-.1l-9 5a1 1 0 0 0-.507.87 1 1 0 0 0 .507.87L3 8.76v4.74a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8.76l2-1.12v2.86a1 1 0 0 0 1 1 1 1 0 0 0 1-1V7.06a1 1 0 0 0-.51-.87zM15 13.5a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V9.87l4.5 2.5.15.06h.1c.083.01.167.01.25 0 .083.01.167.01.25 0h.1a.47.47 0 0 0 .15-.06l4.5-2.5v3.63zm-5-3.14L3.06 6.5 10 2.64l6.94 3.86L10 10.36z"
         fill="#90a0b7"
      />
   </svg>
);

export const Registration = ({ match }) => {
   const user = useSelector((state) => state.authentication);
   return (
      <div className="container-fluid">
         <div className="home-page flex-element">
            <div className="home-page__left relative">
               <ul className="home-page-link">
                  <li>
                     <NavLink
                        exact
                        to={`${match.url}/employer`}
                        className="page-link flex-element flex-element--align-center title-inter-medium"
                        activeClassName="active"
                     >
                        <Icon />Я работодатель
                     </NavLink>
                  </li>
                  <li>
                     <NavLink
                        exact
                        to={`${match.url}/university`}
                        className="page-link flex-element flex-element--align-center title-inter-medium"
                     >
                        <Icon />Я университет
                     </NavLink>
                  </li>
               </ul>
            </div>
            <div className="home-page__right">
               <Redirect from="/university" to={`${match.url}/employer`} />
               {user.loggedIn && <Redirect from="/university" to="/" />}
               <Route
                  path={`${match.path}/employer`}
                  component={RegistrationEmployee}
               />
               <Route
                  path={`${match.path}/university`}
                  component={RegistrationUniversity}
               />
            </div>
         </div>
      </div>
   );
};

import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {patchVacancy, deleteVacancy} from "../../actions/VacancyAction";
import { VacancyCard } from "./VacancyCard";
import {Link, Redirect} from 'react-router-dom';
import {useVacancyCard} from "../../hooks/useVacancyCard";
import {Preloader} from "../Preloader/Preloader";
import {DeleteIcon} from "../Icons/Delete";
import {EditIcon} from "../Icons/Edit";
import {MinusIcon} from "../Icons/Minus";
import {status} from "../../constants/status";

export const MyVacancyCardContainer = ({match}) => {
   const dispatch = useDispatch();
   const vacancyId = match.params.vacancyId;

   const informationForVacancy = useVacancyCard(vacancyId);
   const vacancy = informationForVacancy.vacancy;

   const gallery = useSelector(state => state.media.portfolio);
   const user = useSelector(state => state.authentication.user);
   const userId = user.id;
   const typeUser = user.type;

   const handlePublish = (id) => {
      const value = {status: status.PUBLISHED, title: 'Вакансия опубликована'};
      dispatch(patchVacancy(id, value));
   };

   const [deleteItem, setDelete] = useState(false);
   const handleDelete = (id) => {
      dispatch(deleteVacancy(id));
      setDelete(true)
   };

   if (deleteItem === true) {
      return <Redirect to='/employer/vacancy'/>
   }

   return (
      vacancy.isLoading || vacancy.vacancyInfo === null ? <Preloader/> :
         <>
            {vacancy.vacancyInfo.status === status.DISABLED &&
               <Redirect to="/employer/vacancy"/>
            }

            <div className="btn-options btn-options--mb">
               <Link className="button btn-option btn-option--yellow" to={`${vacancyId}/edit`}>
                  <EditIcon/>
                  Редактировать Вакансию
               </Link>

               {vacancy.vacancyInfo.status !== 10 &&
               <button className="button btn-option btn-option--green title-inter-medium"
                       onClick={() => handlePublish(vacancyId)}>
                  <MinusIcon/>
                  Опубликовать
               </button>
               }

               <button className="button btn-option btn-option--gray-dark title-inter-medium"
                       onClick={() => handleDelete(vacancyId)}>
                  <DeleteIcon/>
                  Удалить
               </button>
            </div>

            <VacancyCard
               vacancy={informationForVacancy.vacancy}
               gallery={gallery}
               typeUser={typeUser}
               checkAuth={user}
               vacancyId={vacancyId}
               userId={userId}
               match={match}
            />
         </>
   )
};

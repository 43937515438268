import React from "react";

export const Assessments = ({ assessments }) => (
   <div className="scrollbar" style={{ height: 260 }}>
      <div className="assessments-content">
         <div className="assessments-list">
            {assessments.map((item, index) => {
               return (
                  <div
                     className="assessments-list__item flex-element flex-element--options"
                     key={index}
                  >
                     <span className="assessments-list__name">{item.name}</span>
                     <span className="assessments-list__mark title-inner-bold">
                        {item.mark === 0 ? "зачет" : item.mark}
                     </span>
                  </div>
               );
            })}
         </div>
      </div>
   </div>
);

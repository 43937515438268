/* студенты => резюме */
import {useDispatch, useSelector} from "react-redux";
import {useResumeCard} from "../../hooks/useResumeCard";
import React, {useEffect, useState} from "react";
import {patchResumeStatus} from "../../actions/ResumeAction";
import {getResumeMedia} from '../../actions/UploadPhoto'
import {Link, Redirect} from "react-router-dom";
import {Preloader} from "../Preloader/Preloader";
import {ResumeCard} from "./RecumeCard";
import {DeleteIcon} from "../Icons/Delete";
import {EditIcon} from "../Icons/Edit";
import {MinusIcon} from "../Icons/Minus";
import {status} from "../../constants/status";

export const MyResumeCardContainer = ({match}) => {
   const dispatch = useDispatch();
   const resumeId = match.params.resumeId;
   const resultResume = useResumeCard(resumeId);
   const files = useSelector(state => state.media);
   const user = useSelector(state => state.authentication.user);

   useEffect(() => {
      dispatch(getResumeMedia(
         {
            link: "user-portfolio",
            title: "portfolio"
         },
         {resume_id: resumeId}
         ));

      dispatch(getResumeMedia(
         {
            link: "user-documents",
            title: "documents"
         },
         {resume_id: resumeId}
      ));
   }, [dispatch, resumeId]);

   const [deleteResume, setDelete] = useState(false);

   const handleDelete = (id) => {
      dispatch(deleteResume(id));
      setDelete(true);
   };

   const handlePublish = (id) => {
      const value = {status: status.PUBLISHED, title: 'Вакансия опубликована'};
      dispatch(patchResumeStatus(id, value))
   };

   return (
      (resultResume.resume.isLoading || resultResume.resume.resumeInfo === null) || files.isLoading ?
      <Preloader/>
      :
      <>
         <div className="btn-options btn-options--mb">
            <Link className="button btn-option btn-option--yellow" to={`${match.url}/edit`}>
               <EditIcon/>
               Редактировать резюме
            </Link>


            {resultResume.resume.resumeInfo.status !== 10 &&
            <button className="button btn-option btn-option--green title-inter-medium"
                    onClick={() => handlePublish(resumeId)}>
               <MinusIcon/>
               Опубликовать
            </button>
            }

            <button className="button btn-option btn-option--gray-dark title-inter-medium"
                    onClick={() => handleDelete(resumeId)}>
               <DeleteIcon/>
               Удалить
            </button>
         </div>

         {resultResume.resume.resumeInfo.status === status.DISABLED && <Redirect to="/student/resume"/>}
         <ResumeCard
            resume={resultResume.resume.resumeInfo}
            files={files}
            checkAuth={user}
         />
      </>
   )
};

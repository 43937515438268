import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions/AuthAction";
import { HeaderMenuUniversity } from "./HeaderMenuUniversity";
import { HeaderMenuEmployer } from "./HeaderMenuEmployer";
import { HeaderMenuStudents } from "./HeaderMenuStudents";
import { Avatar } from "../Avatar/Avatar";
import indent from "../../assets/style/modules/index.module.scss";
import Logo from '../../assets/img/logo.svg';

const menu = [
   {
      name: "Найти сотрудников",
      path: "/resumes",
   },
   {
      name: "Найти вакансию",
      path: "/vacancies",
   },
   {
      name: "Компании",
      path: "/companies",
   },
   {
      name: "Университеты",
      path: "/universities",
   },

   {
      name: "Студенты",
      path: "/students",
   },
   {
      name: "Помощь",
      path: "/help",
   },

   {
      name: "О проекте",
      path: "/about",
   },
];

export const Header = () => {
   const dispatch = useDispatch();
   const user = useSelector((state) => state.authentication.user);
   const [isOpen, setOpen] = useState(false);

   const handleLogout = () => {
      dispatch(userActions.logout());
   };

   const openMenu = () => {
      setOpen(!isOpen);
      document.body.style.overflow = isOpen === false ? "hidden" : "unset";
   };

   const handleNav = () => {
      setOpen(!isOpen);
      document.body.style.overflow = "unset";
   };

   const loggedIn = localStorage.getItem("loggedIn");

   return (
      <header className="header">
         <div className="container">
            <div className="header-inner header-top flex-element flex-element--options">
               <div className="header-inner__group flex-element flex-element--align-center">
                  <button
                     className={`hamburger ${isOpen && "hamburger--active"}`}
                     type="button"
                     aria-label="Открыть меню"
                     aria-controls="navigation"
                     aria-expanded={isOpen}
                     onClick={(e) => openMenu(e)}
                  >
                     <span className="hamburger__box">
                        <span className="hamburger__inner"></span>
                     </span>
                  </button>
                     <NavLink
                        className="logo"
                        to="/"
                        title="Вернуться на главную"
                        aria-label="Вернуться на главную"
                     >
                        <img src={Logo} alt="Uplift"/>
                     </NavLink>
               </div>
               <nav className="nav" aria-label="Основное меню" id="navigation">
                  <div
                     className={`nav__dropdown ${
                        isOpen && "nav__dropdown--active"
                     }`}
                  >
                     <ul className="nav__list">
                        {menu.map((item, index) => {
                           return (
                              <li className="nav__item" key={index}>
                                 <NavLink
                                    className="nav__link"
                                    exact
                                    to={item.path}
                                    onClick={() => handleNav()}
                                 >
                                    {item.name}
                                 </NavLink>
                              </li>
                           );
                        })}
                     </ul>
                  </div>
               </nav>

               {loggedIn === "true" ? (
                  <div className="header-block">
                     <button
                        className="button button-link button-small"
                        title="Выйти из личного кабинета"
                        aria-label="Выйти из личного кабинета"
                        onClick={handleLogout}
                     >
                        <svg
                           aria-hidden="true"
                           width="16"
                           height="20"
                           fill="none"
                        >
                           <path
                              d="M0 10a1 1 0 0 0 1 1h7.6l-2.3 2.3a1 1 0 0 0-.296.71 1 1 0 0 0 .296.71 1 1 0 0 0 .71.296 1 1 0 0 0 .71-.296l4-4a1 1 0 0 0 .21-.33 1 1 0 0 0 0-.76 1 1 0 0 0-.21-.33l-4-4c-.093-.093-.204-.167-.326-.218S7.132 4.996 7 4.996s-.262.026-.384.076-.233.124-.326.218-.167.204-.218.326-.076.252-.076.384.026.262.076.384.124.233.218.326L8.6 9H1a1 1 0 0 0-1 1zM13 0H3a3 3 0 0 0-3 3v3a1 1 0 0 0 1 1 1 1 0 0 0 1-1V3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-3a1 1 0 0 0-1-1 1 1 0 0 0-.707.293A1 1 0 0 0 0 14v3a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3z"
                              fill="#90a0b7"
                           />
                        </svg>
                        <span>Выйти</span>
                     </button>
                  </div>
               ) : (
                  <div className="header-block">
                     <NavLink
                        exact
                        to="/login"
                        className="button button-link button-small button-bold button-upper"
                        title="Войти"
                        aria-label="Войти"
                     >
                        <svg
                           width="20"
                           height="22"
                           fill="none"
                        >
                           <path
                              d="M13.7 11.7a6 6 0 0 0 1.96-6.679A6 6 0 0 0 10 .995a6 6 0 0 0-5.67 4.037 6 6 0 0 0 1.96 6.679 10 10 0 0 0-6.22 8.18c-.014.13-.003.264.034.39s.098.245.18.348c.167.208.4.342.675.37s.53-.048.74-.215.342-.4.37-.675a8 8 0 0 1 7.95-7.107 8 8 0 0 1 7.95 7.107 1 1 0 0 0 1 .89h.1a1 1 0 0 0 .88-1.1 10 10 0 0 0-6.25-8.19zM10 11a4 4 0 0 1-3.696-2.469 4 4 0 0 1 .867-4.359 4 4 0 0 1 4.359-.867A4 4 0 0 1 14 7a4 4 0 0 1-4 4z"
                              fill="#99aac3"
                           />
                        </svg>
                        <span>Войти</span>
                     </NavLink>
                     <NavLink
                        exact
                        to="/registration/employer"
                        className={
                           "button button-small button-gradient-primary button-bold button-upper button-registration " +
                           indent.indent
                        }
                        title="Регистрация"
                        aria-label="Регистрация"
                     >
                        Регистрация
                     </NavLink>
                  </div>
               )}
            </div>
            {loggedIn === "true" && (
               <div className="header-inner header-bottom flex-element flex-element--options">
                  {user.type === "university" ? (
                     <HeaderMenuUniversity />
                  ) : user.type === "hirer" ? (
                     <HeaderMenuEmployer />
                  ) : user.type === "student" ? (
                     <>
                        <HeaderMenuStudents user={user} />
                     </>
                  ) : (
                     ""
                  )}

                  <div className="header-block">
                     <div className="user">
                        <div className="user__information">
                           <div className="user__name">{`${user.username} ${user.surname}`}</div>
                        </div>
                        <Avatar
                           photo={user.photo}
                           alt={`${user.username} ${user.surname}`}
                           class="avatar"
                        />
                     </div>
                  </div>
               </div>
            )}
         </div>
      </header>
   );
};

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FacultiesBodyList} from "./FacultiesBodyList";
import {FacultiesTableHead} from "./FacultiesTableHead";
import UploadFile from "../../UploadFile/UploadFileFaculties";
import {
   getFaculties,
   addFaculties,
   deleteFaculties,
   addFacultiesFile
} from '../../../actions/Universtiy/FacultiesAction';
import Form from './FormFaculties';
import Modal from "@material-ui/core/Modal";
import {Preloader} from "../../Preloader/Preloader";
import {Pagination} from "../../Pagination/Pagination";
import UploadFileSpecialties from "../../UploadFile/UploadFileSpecialties";
import {addSpecialitiesFile} from "../../../actions/Universtiy/SpecialtyAction";
import {CloseIcon} from "../../Icons/Close";
import {Helmet} from "react-helmet/es/Helmet";
import {useRouteMatch} from "react-router-dom";
import {getModalStyle} from "../../Modals/getModalStyle";

export const FacultiesContainer = () => {
   const {url} = useRouteMatch();

   const dispatch = useDispatch();
   const faculties = useSelector(state => state.faculties);
   const user = useSelector(state => state.authentication.user);
   let universityId = user.university_local.id;

   useEffect(() => {
      dispatch(getFaculties(universityId, 1))
   }, [dispatch, universityId]);

   const [open, setOpen] = useState(false);
   const handleOpen = () => {
      setOpen(true)
   };
   const handleClose = () => {
      setOpen(false);
   };

   const [openModalFaculty, setOpenModelFaculty] = useState(false);
   const handleOpenModal = () => {
      setOpenModelFaculty(true)
   };
   const handleCloseModal = () => {
      setOpenModelFaculty(false)
   };

   const [openModalSpecialties, setOpenModalSpecialties] = useState(false);
   const handleOpenModalSpecialties = () => {
      setOpenModalSpecialties(true)
   };
   const handleCloseModalSpecialties = () => {
      setOpenModalSpecialties(false)
   };

   const onSetPage = (page) => {
      dispatch(getFaculties(universityId, page))
   };

   const handleClickDelete = (id) => {
      dispatch(deleteFaculties(id));
   };

   const handleFacultySubmit = (value) => {
      let name = value.name;
      dispatch(addFaculties(universityId, name));
      setOpen(false);
   };

   const handleFacultyFileSubmit = (values) => {
      const file = values.file[0];
      dispatch(addFacultiesFile(universityId, file, values));
      setOpenModelFaculty(false);
   };

   const handleFileSpecialitySubmit = (values) => {
      const file = values.file[0];
      dispatch(addSpecialitiesFile(file, Number(universityId), values.faculty_id, values.name_start, values.code_start));
      setOpenModalSpecialties(false)
   };

   return (
      <>
         <Helmet>
            <title>Факультеты</title>
         </Helmet>

         {faculties.isLoading ? <Preloader/> :
            <>
               <h1 className="title-bold dark display-large">База студентов</h1>
               <div className="block-inner flex-element">
                  <button className="button button-gradient-primary button-mr" onClick={handleOpenModal}>Загрузить
                     факультеты из файла
                  </button>
                  <button className="button button-gradient-primary" onClick={handleOpenModalSpecialties}>Загрузить
                     специальности из файла
                  </button>
               </div>

               <div className="block-table">
                  <div className="table-responsive">
                     <table className="table table--upper">
                        <thead>
                        <FacultiesTableHead handleOpen={handleOpen}/>
                        </thead>

                        <tbody>
                        <FacultiesBodyList url={url}
                                           data={faculties.faculties}
                                           handleClickDelete={handleClickDelete}
                        />
                        </tbody>

                     </table>
                  </div>
                  <Pagination
                     pageCount={faculties.pagination.pageCount}
                     currentPage={faculties.pagination.currentPage}
                     onSetPage={onSetPage}
                  />
               </div>

               <Modal
                  className="modal-base"
                  aria-labelledby="modal-title"
                  open={open}
                  onClose={handleClose}>
                  <div style={getModalStyle} className="modal-content">
                     <div className="modal-header">
                        <h5 id="modal-title" className="dark">Факультет</h5>
                        <button type="button" className="button button-transparent" onClick={handleClose}
                                aria-label="Закрыть модальное окно">
                           <CloseIcon/>
                        </button>
                     </div>

                     <div className="modal-body">
                        <Form onSubmit={(values) => {
                           handleFacultySubmit(values)
                        }}/>
                     </div>
                  </div>
               </Modal>

               <Modal
                  className="modal-upload"
                  open={openModalFaculty}
                  onClose={handleCloseModal}>
                  <div style={getModalStyle} className="modal-content">
                     <div className="modal-header">
                        <button type="button" className="button button-transparent" onClick={handleCloseModal}
                                aria-label="Закрыть модальное окно">
                           <CloseIcon/>
                        </button>
                     </div>

                     <div className="modal-body">
                        <UploadFile
                           onSubmit={(values) => {
                              handleFacultyFileSubmit(values)
                           }}
                        />
                     </div>
                  </div>
               </Modal>

               <Modal
                  className="modal-upload"
                  aria-labelledby="modal-title"
                  open={openModalSpecialties}
                  onClose={handleCloseModalSpecialties}>
                  <div style={getModalStyle} className="modal-content">
                     <div className="modal-header">
                        <button type="button" className="button button-transparent"
                                onClick={handleCloseModalSpecialties}
                                aria-label="Закрыть модальное окно">
                           <CloseIcon/>
                        </button>
                     </div>

                     <div className="modal-body">
                        <UploadFileSpecialties
                           onSubmit={(values) => {
                              handleFileSpecialitySubmit(values)
                           }}
                        />
                     </div>
                  </div>
               </Modal>
            </>
         }
      </>

   )
};


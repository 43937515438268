import API from "../helpers/api";
import {getUser} from "./userAction";

export function settingsNotify(status, idUser) {
   return dispatch => {
      return API.post(`/user/notifications`, {invitations_notify: status})
         .then(() => {
            dispatch(getUser(idUser));
         })
         .catch(error => {
            console.log(error)
         })
   }
}


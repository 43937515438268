import React, {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import ResumeForm from "../ResumeForm";
import {deleteMedia, getResumeMedia, mediaResumes, postMedia} from '../../../../actions/UploadPhoto'
import {
   getResumeInfo, patchResume,
} from "../../../../actions/ResumeAction";
import {Preloader} from "../../../Preloader/Preloader";
import {Helmet} from "react-helmet/es/Helmet";
import {fromStringIntoArray, mapArrayToString} from "../../../../helpers/conversion";
import {status} from "../../../../constants/status";
import {Redirect} from "react-router-dom";

export const ResumeEdit = ({match}) => {
   const dispatch = useDispatch();
   const resumeId = match.params.resumeId;
   const user = useSelector(state => state.authentication.user);
   const aboutUniversity = user.student_info.details;
   const userId = user.id;

   const classifier = useSelector(state => state.filterClassifier.filterCategory);
   const informationResume = useSelector(state => state.resumesInfo);
   const resumeInfo = informationResume.resumeInfo;
   const isLoading = informationResume.isLoading;
   const files = useSelector(state => state.media);

   useEffect(() => {
      dispatch(getResumeInfo(resumeId));

      dispatch(getResumeMedia(
         {
            link: "user-portfolio",
            title: "portfolio"
         },
         {resume_id: resumeId}
      ));

      dispatch(getResumeMedia(
         {
            link: "user-documents",
            title: "documents"
         },
         {resume_id: resumeId}
      ));
   }, [dispatch, resumeId]);

   const handleSubmit = (values) => {
      let valuesNewObj = Object.assign({}, values);

      mapArrayToString(valuesNewObj, ['type_employment', 'category', 'keywords']);
      valuesNewObj.university_id = aboutUniversity.university_id;
      valuesNewObj.work = null;

      if (values.delete_documents) {
         values.delete_documents.map(item =>
            dispatch(deleteMedia(
               {
                  link: 'user-documents',
                  title: "documents",
                  id: item.id
               }
            ))
         )
      }

      if (values.delete_portfolio) {
         values.delete_portfolio.map(item =>
            dispatch(deleteMedia(
               {
                  link: 'user-portfolio',
                  title: "portfolio",
                  id: item.id
               },
            )
         ))
      }

      if (values.work) {
         valuesNewObj.work = values.work.map(experience => {
            return {
               ...experience,
               uid: userId,
               start_month: experience.start_month.value,
               end_month: experience.end_month && experience.end_month.value,
               until_now: experience.until_now === true ? '1' : '0'
            }
         })
      }

      let filesDocuments = values.docs || [];
      let filesPortfolio = values.portfolio || [];
      let concatFiles = filesDocuments.concat(filesPortfolio);
      let promises = concatFiles.map(file => dispatch(mediaResumes(file)));

      Promise.all(promises).then((files) => {
         const regexImg = new RegExp(/\.(jpg|jpeg|png)$/i);
         files.filter(file => file.path.match(regexImg)).map(item =>
            dispatch(postMedia(Object.assign({}, item,
               {
                  link: "user-portfolio",
                  title: "portfolio",
               },
               {
                  id: resumeId
               }
            )))
         );

         const regexFiles = new RegExp(/.+(.doc|.docx|.PPTX)$/i);
         files.filter(file => file.path.match(regexFiles)).map(item =>
            dispatch(postMedia(Object.assign({}, item,
               {
                  link: "user-documents",
                  title: "documents",
               },
               {
                  id: resumeId
               }
            )))
         );
      });

     dispatch(patchResume(resumeId, valuesNewObj));
   };

   let initialValues;

   if (Object.keys(resumeInfo).length !== 0) {
      initialValues = {
         ...resumeInfo,
         university_id: aboutUniversity.university_name,
         work: resumeInfo.work.map(work => {
            return {
               ...work,
               start_month: {value: work.start_month, label: work.start_month},
               end_month: work.end_month !== "" ? {value: work.end_month, label: work.end_month} : null,
               end: work.end !== 0 ? work.end : null
            }
         })
      };

      fromStringIntoArray(initialValues, ["keywords", "type_employment"])
   }

   if (resumeInfo.status === status.DISABLED) {
      return <Redirect to="/student/resume"/>
   }

   return (
      <div className="bg-card create-block">
         <Helmet>
            <title>Редактировать резюме</title>
         </Helmet>

         <h1 className="title-bold dark display-average">Редактировать резюме</h1>

         {(isLoading || Object.keys(resumeInfo).length === 0) || (files.isLoading) ? <Preloader/> :

            <ResumeForm
               initialValues={initialValues}
               data={classifier}
               edit="true"
               files={files}
               onSubmit={(values) => {
                  handleSubmit(values)
               }}
            />
         }
      </div>
   )
};

export const studentsConstants = {
  ADD_RESUME: 'ADD_RESUME',
  DELETE_RESUME_SUCCESS: 'DELETE_RESUME_SUCCESS',
  CHANGE_RESUME_STATUS_SUCCESS: 'CHANGE_RESUME_STATUS_SUCCESS',
  CHANGE_RESUME_SUCCESS: 'CHANGE_RESUME_SUCCESS',
  COUNTER_RESUME: 'COUNTER_VACANCY',

  GET_RESUME_SUCCESS: 'GET_RESUME_SUCCESS',
  GET_RESUME_FAILURE: 'GET_RESUME_FAILURE',
  IS_LOADING_RESUMES: 'IS_LOADING_RESUMES',

  GET_INFO_RESUME_SUCCESS: 'GET_INFO_RESUME_SUCCESS',
  GET_INFO_RESUME_FAILURE: 'GET_INFO_RESUME_FAILURE',
  IS_LOADING_RESUME_INFO: 'IS_LOADING_RESUME_INFO',

  GET_PORTFOLIO_SUCCESS: 'GET_PORTFOLIO_SUCCESS',
  GET_PORTFOLIO_FAILURE: 'GET_PORTFOLIO_FAILURE',
  GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_FAILURE: 'GET_DOCUMENTS_FAILURE',

  DELETE_PORTFOLIO: 'DELETE_PORTFOLIO',
  DELETE_DOCUMENTS: 'DELETE_DOCUMENTS',
  POST_DOCUMENTS: 'POST_DOCUMENTS',
  POST_PORTFOLIO: 'POST_PORTFOLIO',

  GET_LIST_RESUME_SEARCH_SUCCESS: 'GET_LIST_RESUME_SEARCH_SUCCESS',
  GET_LIST_RESUME_SEARCH_FAILURE: 'GET_LIST_RESUME_SEARCH_FAILURE'
};

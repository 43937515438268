import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {deleteResume, patchResumeStatus, getResumeByParams} from '../../../actions/ResumeAction';
import {MyResumeList} from "./MyResumeList";
import Thead from "./Thead";
import {Preloader} from "../../Preloader/Preloader";
import {Helmet} from "react-helmet/es/Helmet";
import {status} from "../../../constants/status";
import {TabsContent} from "../../Tabs/TabsContent";

export const MyResumeListContainer = ({match}) => {
  const url = match.url;
  const resumes = useSelector(state => state.resume);
  const user = useSelector(state => state.authentication.user);
  const userId =  user.id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getResumeByParams({uid: userId}));
  },[dispatch, userId]);

  const handleClickDelete = (id) => {
    dispatch(deleteResume(id));
  };

  const handleClickArchive = (id) => {
    const value =  {status: status.ARCHIVE, title: 'Вакансия добавлена в архив'};
    dispatch(patchResumeStatus(id, value));
  };

  const handleClickPublished= (id) => {
     const value =  {status: status.PUBLISHED, title: 'Вакансия опубликована'};
     dispatch(patchResumeStatus(id, value))
  };

  const resumeList = resumes.resume.filter(resStatus => resStatus.status === status.PUBLISHED);
  const resumeDisabled = resumes.resume.filter(resStatus => resStatus.status === status.DISABLED);
  const resumeListArchive = resumes.resume.filter(resStatus =>  resStatus.status === status.ARCHIVE);

  const tabs = [
     {
        label: 'Активные',
        components: <ResumeContent
            list={resumeList}
            text="У вас нет активных резюме"
            url={url}
            handleClickArchive={handleClickArchive}
            handleClickDelete={handleClickDelete}
            handleClickPublished={handleClickPublished}
        />
     },
     {
        label: 'В архиве',
        components: <ResumeContent
           list={resumeListArchive}
           text="Архив с резюме пуст"
           url={url}
           handleClickArchive={handleClickArchive}
           handleClickDelete={handleClickDelete}
           handleClickPublished={handleClickPublished}
        />
     },
     {
        label: 'Заблокированные',
        components: <ResumeContent
           list={resumeDisabled}
           text="Заблокированные резюме"
           url={url}
           handleClickArchive={handleClickArchive}
           handleClickDelete={handleClickDelete}
           handleClickPublished={handleClickPublished}
        />
     },

  ];

  return (
      <>
         <Helmet>
            <title>Мои резюме</title>
         </Helmet>

        {resumes.isLoading ? <Preloader/>
            :
            <>
              <Link className="button button-green-gradient button-bold button-create" to={`create-resume`}>Создать резюме</Link>

              <div className="bg-card bg-card--mt bg-card--inner">
                 <TabsContent tabs={tabs} />
              </div>
            </>
        }
      </>
  )
};


const ResumeContent = ({list, text, url, handleClickArchive, handleClickDelete, handleClickPublished}) => (
   <div className="table-responsive">
      {list.length === 0 ?
         <p className="mb-none txt-info">{text}</p>
         :
         <table className="table table--shadow-none table--upper table-small-size table--head-transparent">
            <Thead/>
            <tbody>
            <MyResumeList data={list}
                          url={url}
                          handleClickArchive={handleClickArchive}
                          handleClickDelete={handleClickDelete}
                          handleClickPublished={handleClickPublished}
            />
            </tbody>
         </table>
      }
   </div>
);

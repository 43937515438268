import {universityConstants} from "../../constants/universityConstants";
import API from "../../helpers/api";
import {toast} from "react-toastify";

export const addGroupSuccess = (group) => ({
    type: universityConstants.ADD_GROUP_SUCCESS,
    group
});

export function addGroup(values) {
    return dispatch => {
    return API.post(`/groups`, values)
        .then(res => {
            dispatch(addGroupSuccess(res.data.model));
            toast.success("Группа добавлена");
        }).catch(error => console.log(error));
    }
}

export function isLoadingGroups(isLoading) {
  return {
    type: universityConstants.IS_LOADING_GROUPS,
    isLoading
  }
}
export function getGroupsSuccess(groups, pagination) {
    return {
        type: universityConstants.GET_GROUPS_SUCCESS,
        groups,
        pagination
    };
}

export const getGroupsFailure = (error) => ({
    type: universityConstants.GET_GROUPS_FAILURE,
    error
});

export function getGroups(id, page) {
    return (dispatch) => {
        dispatch(isLoadingGroups(true));
        return API.get(`/groups/search?speciality_id=${id}&page=${page}`)
            .then(groups => {
                dispatch(getGroupsSuccess(groups.data.items, groups.data._meta))
            })
            .catch(error => {
                dispatch(getGroupsFailure(error))
            })
    }
}


export function deleteGroupsSuccess(group) {
    return {
        type: universityConstants.DELETE_GROUP_SUCCESS,
        group
    }
}

export function deleteGroup(id) {
    return (dispatch) => {
      isLoadingGroups(true);
      return API.delete(`/groups/${id}`)
          .then(group => {
              dispatch(deleteGroupsSuccess(group.data.id));
              toast.success("Группа удалена");
          })
          .catch(error => {
              console.log(error)
          })
    }
}


export function addGroupsFile(file, university_id, speciality_id, values) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("university_id", university_id);
  formData.append("speciality_id", speciality_id);
  formData.append("name_start", values.name_start);
  formData.append("training_start", values.training_start);
  formData.append("study_start", values.study_start);
  formData.append("start_start", values.start_start);
  formData.append("end_start", values.end_start);

  return dispatch => {
    isLoadingGroups(true);
    return API.post(`/groups/upload-excel`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
      dispatch(getGroups(speciality_id))
    }).catch(error => {
      console.log(error.response.data)
    });
  }
}

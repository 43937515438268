import React from 'react';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import {FieldMask} from "../../FormElement/Field/FieldMask";
import {FieldSelect} from "../../FormElement/Field/FieldSelect";
import {FieldInput} from "../../FormElement/Field/FieldInput";
import validate from "../../FormElement/validate";
import {userActions} from "../../../actions/AuthAction";
import {companyType} from '../../FormElement/Options'
import {FieldDaData} from "../../FormElement/Field/FieldDaData";
import {Link} from "react-router-dom";

const RegistrationEmployerForm = ({ cities, handleSubmit }) => (
  <>
    <form className="form" onSubmit={handleSubmit}>
      <fieldset>
        <legend>Контактное лицо</legend>

        <Field
            name="type"
            component="input"
            type="hidden"
            value="hirer"
        />

        <div className="form__line flex-element">
          <div className="form__item">
            <label className="form__label" htmlFor="username">Имя</label>

            <Field
              id='username'
              placeholder="Введите Имя"
              name="username"
              type="text"
              component={FieldInput}
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="surname">Фамилия</label>

            <Field
                id='surname'
                placeholder="Введите Фамилию"
                name="surname"
                type="text"
                component={FieldInput}
            />
          </div>
        </div>

        <div className="form__line flex-element">

          <div className="form__item">
            <label className="form__label" htmlFor="company_type">Тип компании</label>
            <Field
                placeholder="Выберите"
                id='company_type'
                name="company_type"
                options={companyType}
                component={FieldSelect}
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="name">Название компании</label>

            <Field
                id='name'
                placeholder="Укажите название компании"
                name="name"
                type="text"
                component={FieldInput}
            />
          </div>

        </div>

        <div className="form__line flex-element">
          <div className="form__item form__item--custom">
            <label className="form__label" htmlFor="city">
               <span>Город</span>
               <Field
                  placeholder="Укажите город"
                  name="city"
                  type="text"
                  options={cities}
                  component={FieldDaData}
               />
            </label>
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="start_year">На рынке с</label>

            <Field
                id='name'
                name="start_year"
                type="number"
                component={FieldInput}
            />
          </div>
        </div>

        <div className="form__line flex-element">
          <div className="form__item">
            <label className="form__label" htmlFor="email">Email</label>

            <Field
                id='email'
                name="email"
                type="text"
                placeholder="Email"
                component={FieldInput}
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="phone">Телефон</label>
            <Field
                id="phone"
                label="Телефон"
                name="phone"
                type="text"
                placeholder="+7 (___) ___-__-__"
                component={FieldMask}
                mask="+7 (999) 999-99-99"
            />
          </div>
        </div>

        <div className="form__line flex-element">
          <div className="form__item">
            <label className="form__label" htmlFor="password">Пароль</label>

            <Field
                id='password'
                name="pass"
                type="password"
                placeholder="Введите пароль"
                component={FieldInput}
            />
          </div>

          <div className="form__item">
            <label className="form__label" htmlFor="new_pass">Повторите пароль</label>

            <Field
                id='new_pass'
                name="new_pass"
                type="password"
                placeholder="Введите пароль"
                component={FieldInput}
            />
          </div>
        </div>
         <p className="mb-none">Нажимая «Зарегистрироваться», вы подтверждаете, что ознакомлены, полностью согласны и принимаете условия <Link to={{pathname: `../file/Пользовательское соглашение.docx`}} target="e_blank">«Пользовательского соглашения»</Link> и <Link to={{pathname: `../file/Политика_конфиденциальности_25.01.2020.docx`}} target="e_blank">«Политики конфиденциальности»</Link></p>

        <button type="submit" className="button button-green-gradient button-small button-bold button-upper">Зарегистрироваться</button>
      </fieldset>
    </form>
  </>
);

export default connect(null, {
  onSubmit: (values) => {
    let valuesNewObj = Object.assign({}, values);
    valuesNewObj.company_type = values.company_type.value;
    return userActions.register(valuesNewObj)
  }
})(reduxForm({
  form: 'RegistrationEmployer',
  initialValues: {
    type: 'hirer'
  },
  validate
  })(RegistrationEmployerForm))

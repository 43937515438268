import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { Field, reduxForm } from 'redux-form';
import validate from '../../FormElement/validate';
import {FieldSelect} from "../../FormElement/Field/FieldSelect";
import {userActions} from "../../../actions/AuthAction";
import {FieldInput} from "../../FormElement/Field/FieldInput";

export const LoginStudentForm = ({cities, universities, handleSubmit, error, handleChange, show}) => {
  let optionsCity = cities.map(city => {return ({value: city.city, label: city.city})});
  let optionsUniversity =  universities.map(universities => {return ({value: universities.id, label: universities.abbreviation})});

  return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__item">
          <label className="form__label" htmlFor="city">Город в котором вы учились</label>
          <Field
              id='city'
              name="city"
              placeholder="Выберите"
              options={optionsCity}
              onChange={(value) => {handleChange(value)}}
              component={FieldSelect}
          />
        </div>

        <div className={show ? 'form__item item-hidden item-hidden--active' : `form__item item-hidden`}>
          <label className="form__label" htmlFor="university_id">Университет</label>
          <Field
              id='university_id'
              name="university_id"
              placeholder="Выберите"
              options={optionsUniversity}
              component={FieldSelect}
          />
        </div>

        <div className="form__item">
          <label className="form__label" htmlFor="ticket">Номер студенческого билета</label>
          <Field
              name="ticket"
              id="ticket"
              component={FieldInput}
              type="text"
          />
        </div>

        <div className="form__item">
          <label className="form__label" htmlFor="password">Пароль</label>
          <Field
              name="password"
              id="password"
              type="password"
              component={FieldInput}
          />
        </div>

        {error && <span className="hasError hasError--pb">{error}</span>}

        <div className="flex-element flex-element--options form__line">
          <button type="submit" className="button button-gradient-primary button-indent">Войти</button>
          <div className="form__group">
            <Link to="restore">Забыли пароль?</Link>
          </div>
        </div>
      </form>
  )
};

export default connect(null, {
  onSubmit: values => {
    let valuesNewObj = Object.assign({}, values);
    valuesNewObj.city = values.city.value;
    valuesNewObj.university_id = values.university_id.value;
    return userActions.login('auth/student', valuesNewObj);
  }
})(reduxForm({
  form: 'login-student',
  validate,
})(LoginStudentForm))

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from 'react-router-dom'
import Modal from "@material-ui/core/Modal";
import {invitationRequest, invitationRequestGet} from "../../actions/FeedbackAction";
import {VacancyCard} from "./VacancyCard";
import VacancyRespondForm from "../VacancyList/MainVacancyList/VacancyRespondForm";
import {addFavorites, favoritesDelete, getFavorites} from "../../actions/FavoritesAction";
import {getResumeByParams} from "../../actions/ResumeAction";
import {useVacancyCard} from "../../hooks/useVacancyCard";
import {vacancyCounter} from "../../actions/VacancyAction";
import {Preloader} from "../Preloader/Preloader";
import {CloseIcon} from "../Icons/Close";
import {status} from "../../constants/status";
import {getModalStyle} from "../Modals/getModalStyle";

export const MainVacancyCardContainer = ({match}) => {
    const dispatch = useDispatch();
    const vacancyId = match.params.vacancyId;
    const informationForVacancy = useVacancyCard(vacancyId);

    const user = useSelector(state => state.authentication);
    const resume = useSelector(state => state.resume.resume);
    const gallery = useSelector(state => state.media.portfolio);
    const favorites = useSelector(state => state.favorites.favorite);
    const feedback = useSelector(state => state.feedback.invite);

    const [open, setOpen] = useState(false);
    const handleOpenModal = () => {
      setOpen(true);
    };

    const handleCloseModal = () => {
      setOpen(false);
    };

    let typeUser;
    let userId;
    if (user.loggedIn && user.user.type === 'student') {
        typeUser = user.user.type;
        userId = user.user.id;
    }

    useEffect(() => {
        dispatch(vacancyCounter(vacancyId));

        if (user.loggedIn && user.user.type === 'student') {
           dispatch(getResumeByParams({
              uid: userId,
              status: 10
           }));

           dispatch(getFavorites({
              vacancy_id: vacancyId,
              uid: userId
           }));

           dispatch(invitationRequestGet({
              vacancy_id: vacancyId,
              from_uid: userId
           }))
        }
    }, [dispatch, userId, vacancyId]);

    const handleSubmit = (values) => {
        const options = {
            to_uid: informationForVacancy.vacancy.vacancyInfo.hirer.uid,
            sender_type: user.user.type,
            message: values.message,
            resume_id: values.resume_id.value,
            vacancy_id: vacancyId
        };

        dispatch(invitationRequest(options));
        setOpen(false);
    };

    const handleAddFavorites = () => {
        const values = {vacancy_id: vacancyId};
        dispatch(addFavorites(values))
    };

    const handleRemoveFavorites = () => {
       dispatch(favoritesDelete(favorites[0].id))
    };


    return (
        <>
            {informationForVacancy.vacancy.isLoading || informationForVacancy.vacancy.vacancyInfo === null ? <Preloader/> :
               <>
                  {informationForVacancy.vacancy.vacancyInfo.status === status.DISABLED && <Redirect to="/vacancies" />}

                  <VacancyCard
                     vacancy={informationForVacancy.vacancy}
                     gallery={gallery}
                     checkAuth={user.user}
                     typeUser={typeUser}
                     handleOpenModal={handleOpenModal}
                     handleAddFavorites={handleAddFavorites}
                     handleRemoveFavorites={handleRemoveFavorites}
                     favorites={favorites.length}
                     feedback={feedback.length}
                  />
               </>
            }

            <Modal
                className="modal-feedback"
                aria-labelledby="modal-title"
                open={open}
                onClose={handleCloseModal}>
                <div style={getModalStyle} className="modal-content">
                    <div className="modal-header">
                        <h5 id="modal-title " className="dark title-semi-bold mb">Отклик на вакансию</h5>
                        <button type="button" className="button button-transparent" onClick={handleCloseModal} aria-label="Закрыть модальное окно">
                            <CloseIcon />
                        </button>
                    </div>

                    <div className="modal-body">
                        <VacancyRespondForm
                            resume={resume}
                            onSubmit={(values) => {
                                handleSubmit(values)
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
};

import React from 'react';
import {Link} from "react-router-dom";
import {CardPreviewVacancy} from "../../Layouts/CardPreviewVacancy";

export const MainVacancyList = ({vacancyList, match}) => {
   return (
      vacancyList.filter(status => status.status === 10).map(vacancy => {
         return <Link to={`${match.url}/${vacancy.id}`} className="card-preview flex-element" key={vacancy.id}>
            <CardPreviewVacancy data={vacancy} dop={true} />
         </Link>
      })
   )
};


import React from 'react';

const StudentHead = () => (
    <tr>
      <th scope="col">ФИО</th>
      <th scope="col">Телефон</th>
      <th scope="col">Номер зачетной книжки</th>
      <th scope="col">
        <span className="visually-hidden">Удалить</span>
      </th>
    </tr>
);
export default StudentHead;
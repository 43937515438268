import Select from "react-select";
import React from "react";
import {customStyles} from "./FieldSelect";

export const SelectFieldMulti = ({placeholder, id, input, options, meta: {touched, error}}) => (
   <>
      <Select
         styles={customStyles}
         inputId={id}
         {...input}
         value={input.value}
         options={options}
         isMulti
         placeholder={placeholder}
         className="basic-multi-select"
         classNamePrefix="select"
         onBlur={() => input.onBlur()}
         onChange={value => input.onChange(value)}
         noOptionsMessage = {() => 'Ничего не найдено'}
         // onBlur={() => input.onBlur(input.value)}
      />
      {touched && error && <span className='hasError'>{error}</span>}
   </>
);

import React from "react";
import { Link } from "react-router-dom";
import {
   ButtonAccept,
   ButtonArchive,
   ButtonDelete,
} from "../../ButtonOptions/ButtonOptions";
import { status } from "../../../constants/status";

export const MyVacancyList = ({
   data,
   url,
   handleClickDelete,
   handleClickArchive,
   handleClickAccept,
}) =>
   data.map((vacancy) => {
      return (
         <tr key={vacancy.id}>
            <td>
               {new Date(vacancy.created_at * 1000).toLocaleString("ru", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
               })}
            </td>
            <td>
               {vacancy.status === status.DISABLED ? (
                  <span>{vacancy.name}</span>
               ) : (
                  <Link to={`${url}/${vacancy.id}`}>{vacancy.name}</Link>
               )}
            </td>
            <td>{vacancy.city}</td>
            <td>{vacancy.num_views}</td>
            <td>{vacancy.invitations_count}</td>
            <td>
               {vacancy.status === 50 ? (
                  <span className="text-light">В архиве</span>
               ) : vacancy.status === 5 ? (
                  <span className="text-red">Заблокировано</span>
               ) : (
                  <span className="text-green">Активный</span>
               )}
            </td>
            <td>
               {vacancy.status !== status.DISABLED && (
                  <div className="btn-group">
                     <ButtonDelete
                        onClick={() => handleClickDelete(vacancy.id)}
                     />
                     {vacancy.status === 50 && (
                        <ButtonAccept
                           onClick={() => handleClickAccept(vacancy.id)}
                        />
                     )}
                     {vacancy.status !== 50 && (
                        <ButtonArchive
                           onClick={() => handleClickArchive(vacancy.id)}
                        />
                     )}
                  </div>
               )}
            </td>
         </tr>
      );
   });


import {universityConstants} from "../../constants/universityConstants";
import API from '../../helpers/api'

export function getTemporalSuccess(students) {
  return {
    type: universityConstants.GET_TEMPORAL_SUCCESS,
    students
  }
}

export function getTemporalFailure(error) {
  return {
    type: universityConstants.GET_TEMPORAL_FAILURE,
    error
  }
}

export function getTemporalPassword(id) {
  return (dispatch) => {
    return API.get(`/students/passwords?university_id=${id}`)
        .then(students => {
          dispatch(getTemporalSuccess(students.data));
        })
        .catch(error => {
          console.log(error);
          dispatch(getTemporalFailure(error));
        })
  }
}

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
   getCompanies,
   getListCompaniesSearch,
} from "../../../actions/CompanyAction";
import { CompaniesList } from "./СompanyList";
import FilterCompany from "../../Filter/FilterCompany";
import { Preloader } from "../../Preloader/Preloader";
import { Pagination } from "../../Pagination/Pagination";
import { getFilter } from "../../../reducers/ClassifiersRedicer";

export const CompanyListContainer = ({ match }) => {
   const dispatch = useDispatch();
   const classifier = useSelector(
      (state) => state.filterClassifier.filterOrganization
   );
   const companiesList = useSelector((state) => state.companiesList);

   useEffect(() => {
      dispatch(getCompanies(1));
      dispatch(getFilter("industry", "organization"));
   }, [dispatch]);

   const handleSubmit = (values) => {
      if (values.category) {
         values.category = values.category.map((item) => item.value).join(",");
      }
      dispatch(getListCompaniesSearch(values));
   };

   const onSetPage = (page) => {
      dispatch(getCompanies(page));
   };

   return companiesList.isLoading ? (
      <Preloader />
   ) : (
      <>
         <h1 className="dark title-semi-bold title-bold display-large">
            Компании
         </h1>
         <div className="inner-block inner-block--primary flex-element flex-element--justify-sb">
            <aside className="aside-outside aside-outside--basis">
               <div className="bg-card filter-block">
                  <FilterCompany
                     data={classifier}
                     onSubmit={(values) => {
                        handleSubmit(values);
                     }}
                  />
               </div>
            </aside>
            <div className="inner-block__content">
               {companiesList.companies.length === 0 ? (
                  <p className="mb-none">Ничего не найдено</p>
               ) : (
                  <CompaniesList
                     companiesList={companiesList.companies}
                     match={match}
                  />
               )}

               <Pagination
                  pageCount={companiesList.pagination.pageCount}
                  currentPage={companiesList.pagination.currentPage}
                  onSetPage={onSetPage}
               />
            </div>
         </div>
      </>
   );
};

import React from 'react';

export const Keywords = ({keywords, classBadge}) => {
   return keywords.map((item, index) => (
      <div className={`badge ${classBadge}`} key={index}>
         {item.label ? item.label : item}
      </div>
   ))
};


import React from 'react';
import RestorePasswordForm from "./RestorePasswordForm";
import {useSelector} from "react-redux";
import {Link, Redirect} from 'react-router-dom';
import {Helmet} from "react-helmet/es/Helmet";
import VerificationForm from "./VerificationForm";

export const RestorePassword = () => {
  const user = useSelector(state => state.authentication);
  const status = useSelector(state => state.restore);

  if (user.loggedIn) {
    return <Redirect from='/restore' to="/" />
  }

  return (
      <div className="login-page-wrap flex-element flex-element--align-center">
        <Helmet>
          <title>Восстановление пароля</title>
        </Helmet>
        <div className="login-page">
           {status.status === undefined &&
              <div className="login-page__inner">
                 <div className="login-page__inner-head">
                    <div className="login-page__title title-bold text-black">Восстановление пароля</div>
                 </div>
                 <div className="login-page__inner-content">
                    <div className="login-page-inner-form">
                       <RestorePasswordForm/>
                    </div>
                 </div>
              </div>
           }

           {(status.status && status.reset === undefined) &&
               <div className="login-page__inner">
                  <div className="login-page__inner-head">
                     <div className="login-page__title title-bold text-black">Новый пароль</div>
                  </div>
                  <div className="login-page__inner-content">
                     <p className="login-page__inner-text">{status.message}</p>
                     <div className="login-page-inner-form">
                        <VerificationForm />
                     </div>
                  </div>
               </div>
           }

           {(status.status && status.reset) &&
           <div className="login-page__inner">
              <div className="login-page__inner-head">
                 <div className="login-page__title title-bold text-black">Успешно</div>
              </div>
              <div className="login-page__inner-content">
                 <Link to="/login" className="button button-green-gradient button-small button-upper button-indent button-bold">Войти</Link>
              </div>
           </div>
           }

        </div>
      </div>
  )
};

export const companyConstants = {
    IS_LOADING_COMPANIES: 'IS_LOADING_COMPANIES',
    GET_LIST_COMPANIES_SUCCESS: 'GET_LIST_COMPANIES_SUCCESS',
    GET_LIST_COMPANIES_FAILURE: 'GET_LIST_COMPANIES_FAILURE',

    GET_LIST_COMPANIES_SEARCH_FAILURE: "GET_LIST_COMPANIES_SEARCH_FAILURE",
    GET_LIST_COMPANIES_SEARCH_SUCCESS: "GET_LIST_COMPANIES_SEARCH_SUCCESS",

    GET_COMPANY: 'GET_COMPANY',
    IS_LOADING_INFO_COMPANIES: 'IS_LOADING_INFO_COMPANIES',
};
import {toast} from "react-toastify";
import {history} from "../helpers/history";
import API from "../helpers/api";
import {vacancyConstant} from "../constants/vacancyСonstant";
import {filterChange} from "../reducers/ClassifiersRedicer";
import {getResumeMedia} from "./UploadPhoto";

export function postVacancySuccess(vacancy) {
    return {
        type: vacancyConstant.ADD_VACANCY,
        vacancy
    };
}

export function postVacancy(values) {
    return (dispatch) => {
        return API.post(`/vacancy`, values)
            .then(vacancy => {
                dispatch(postVacancySuccess(vacancy.data.model));
                toast.success("Вакансия добавлена");
                setTimeout(() => history.push('/employer/vacancy'), 1000);
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export function changeVacancySuccess(vacancy) {
    return {
        type: vacancyConstant.CHANGE_VACANCY,
        vacancy
    };
}

export function patchVacancy(id, values) {
    return (dispatch) => {
        return API.patch(`/vacancy/${id}`, values)
            .then(vacancy => {
                dispatch(changeVacancySuccess(vacancy.data));
                toast.success('Вакансия сохранилась');
                setTimeout(() => history.push('/employer/vacancy'), 1000);
            })
            .catch(error => {
                console.log(error);
            })
    }
}


export function deleteVacancySuccess(vacancy) {
    return {
        type: vacancyConstant.DELETE_VACANCY,
        vacancy
    }
}

export function deleteVacancy(id) {
    return (dispatch) => {
        return API.delete(`/vacancy/${id}`)
            .then(vacancy => {
                dispatch(deleteVacancySuccess(vacancy.data.id));
                toast.success('Вакансия удалена');
            })
            .catch(error => {
                console.log(error)
            })
    }
}

/* ALL LIST */
export function iSLoadingVacancy(isLoading) {
    return {
        type: vacancyConstant.IS_LOADING_VACANCIES,
        isLoading
    };
}

export function getVacancySuccess(vacancy, pagination) {
    return {
        type: vacancyConstant.GET_VACANCY_SUCCESS,
        vacancy,
        pagination
    };
}

export function vacancyFailure(error) {
    return {
        type: vacancyConstant.GET_VACANCY_FAILURE,
        error
    };
}

export function getListVacancy(count) {
    return (dispatch) => {
        dispatch(iSLoadingVacancy(true));
        return API.get(`/vacancy?page=${count}`)
            .then(vacancy => {
                dispatch(getVacancySuccess(vacancy.data.items, vacancy.data._meta))
            })
            .catch(error => {
                dispatch(vacancyFailure(error))
            })
    }
}

/* LIST BY ID */
export function getVacancyByParams(params) {
   return (dispatch) => {
      dispatch(iSLoadingVacancy(true));
      return API.get(`/vacancy/search`, {params})
         .then(vacancy => {
            dispatch(getVacancySuccess(vacancy.data.items))
         })
         .catch(error => {
            dispatch(vacancyFailure(error))
         })
   }
}

/* CARD VACANCIES */
export function getVacancyInfoSuccess(vacancyInfo, userInfo) {
    return {
        type: vacancyConstant.GET_INFO_VACANCY_SUCCESS,
        vacancyInfo,
        userInfo
    };
}

export function getVacancyInfoFailure(error) {
    return {
        type: vacancyConstant.GET_INFO_VACANCY_FAILURE,
        error
    };
}

export function isLoadingVacancyInfo(isLoading) {
    return {
        type: vacancyConstant.IS_LOADING_INFO_VACANCIES,
        isLoading
    }
}

export function getVacancyInfo(id) {
    return async dispatch => {
        dispatch(isLoadingVacancyInfo(true));
        try {
            const category = await API.get(`/categories`);
            const vacancyInfo = await API.get(`/vacancy/${id}`);
            const userInfo = await API.get(`/user/${vacancyInfo.data.hirer.uid}`);

            dispatch(getVacancyInfoSuccess(vacancyInfo.data, userInfo.data));
            dispatch(filterChange(category.data.items, vacancyInfo.data.category, 'category'));
            dispatch(getResumeMedia(
                 {
                    link: "hirer-portfolio",
                    title: "portfolio"
                 },
                 {uid: vacancyInfo.data.hirer.uid}
              ));
        } catch (error) {
            dispatch(getVacancyInfoFailure(error))
        }
    }
}

/* COUNTER */
export function counterVacancy(count) {
  return {
    type: vacancyConstant.COUNTER_VACANCY,
    count
  }
}

export function vacancyCounter(id) {
    return (dispatch) => {
        return API.post(`/vacancy/counter`, {id: id})
            .then(counter => {
                dispatch(counterVacancy(counter.data))
            })
            .catch(error => {
                console.log(error, 'error')
            })
    }
}

/* SEARCH */
export function getListVacancySearchSuccess(vacancy) {
    return {
        type: vacancyConstant.GET_LIST_VACANCY_SEARCH_SUCCESS,
        vacancy
    };
}

export function getListVacancySearchFailure(error) {
    return {
        type: vacancyConstant.GET_LIST_VACANCY_SEARCH_FAILURE,
        error
    };
}

export function getListVacancySearch(values) {
    return (dispatch) => {
        return API.get(`/search/vacancy`, {params:values})
            .then(vacancy => {
                dispatch(getListVacancySearchSuccess(vacancy.data.items))
            })
            .catch(error => {
                dispatch(getListVacancySearchFailure(error))
            })
    }
}

import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getResumeInfo} from "../actions/ResumeAction";

export const useResumeCard = (id) => {
  const dispatch = useDispatch();
  const resume = useSelector(state => state.resumesInfo);

  useEffect(() => {
    dispatch(getResumeInfo(id));
  },[dispatch, id]);

  return {resume}
};

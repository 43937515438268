import { SubmissionError, reset } from 'redux-form';
import {personalDataConstants} from "../constants/personalDataConstats";
import API from "../helpers/api";
import {toast} from "react-toastify";
import {universityConstants} from "../constants/universityConstants";
import {getUser} from "./userAction";

/* основные настройки (ПРОФИЛЬ) */
export function changePersonalDataSuccess(user) {
  return {
    type: personalDataConstants.CHANGE_PROFILE_SETTING,
    user
  };
}

export function changePersonalData(id, values) {
  return (dispatch) => {
    return API.patch(`/user/${id}`, values)
      .then(res => {
        localStorage.setItem('user', JSON.stringify(res.data));
        dispatch(changePersonalDataSuccess(res.data));
        toast.success("Настройки сохранены");
      })
    .catch(error => {
       const err = error.response.data;
       for (let error in err) {
          toast.error(err[error].message);
       }
    })
  }
}

export function photoChange(file) {
  const formData = new FormData();
  formData.append("file", file);

  return () => {
    return new Promise((resolve, reject) => {
       API.post(`/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(res => {
            resolve(res.data[0]);
          })
          .catch(error => {
            console.log(error);
            reject();
          })
    });
  }
}


/* настройки employer => данные организации */

export function changeSettingEmployer(idEmployer, values) {
  return async dispatch => {
    try {
      const hirerInformation = await API.patch(`/hirer/${idEmployer}`, values);
      const userInfo = await API.get(`/user/${hirerInformation.data.uid}`);
      dispatch(changePersonalDataSuccess(userInfo.data));
      localStorage.setItem('user', JSON.stringify(userInfo.data));
      toast.success("Данные сохранены");
    } catch (error) {
      console.log(error)
    }
  }
}

/* end */


/* настройки университета */
export function changeSettingsUniversitySuccess(settings) {
  return {
    type: universityConstants.CHANGE_UNIVERSITY_SETTINGS,
    settings
  };
}

export function postSettingsUniversity(id, values) {
  return (dispatch) => {
    return API.patch(`/university/${id}`, values)
        .then(settings => {
          dispatch(changeSettingsUniversitySuccess(settings.data));
          toast.success("Настройки сохранены");
        })
        .catch(error => {
          console.log(error)
        })
  }
}

/* end */


/* CHANGE PASSWORD */
export function changePersonalPasswordSuccess() {
    return {
        type: personalDataConstants.CHANGE_PASSWORD
    };
}

export function changePersonalPassword(values) {
    return (dispatch) => {
        return API.post(`/user/change-password`, values)
            .then(res => {
                dispatch(reset('changePassword'));
                dispatch(changePersonalPasswordSuccess());
                toast.success("Настройки сохранены");
            })
            .catch(error => {
              throw new SubmissionError(error.response.data);
            })
    }
}

/* Студент - данные */
export function changeSettingStudentSuccess(user) {
    return {
        type: personalDataConstants.CHANGE_SETTINGS,
        user
    };
}

export function changeSettingStudent(idStudent, values) {
    return async dispatch => {
        try {
            let infoStudent = await API.patch(`/students/${idStudent}`, values);
            dispatch(getUser(infoStudent.data.uid));
        } catch (error) {
            console.log(error)
        }
    }
}

export function postPersonalDataSuccess(user) {
    return {
        type: personalDataConstants.POST_PROFILE_SETTING,
        user
    };
}

export function postPersonalData(values){
    return (dispatch) => {
        return API.post(`/user/update-student`, values)
            .then(res => {
                localStorage.setItem('user', JSON.stringify(res.data));
                dispatch(changePersonalDataSuccess(res.data))
            })
            .catch(error => {
                const err = error.response.data;
                for (let error in err) {
                    toast.error(err[error][0]);
                }
            })
    }
}


import React from 'react';
import {PersonalDataSettingContainer} from "../PersonalDataSettings/PersonalDataSettingContainer";
import {OrganizationData} from './OrganizationData';
import {PersonalPasswordContainer} from "../PersonalPassword/PersonalPasswordContainer";
import {PhotoGalleryEmployer} from "../PhotoGallery/PhotoGalleryEmployer";
import {NotificationsContainer}  from "../Notifications/NotificationsContainer";
import {Helmet} from "react-helmet/es/Helmet";
import {TabsContent} from "../../Tabs/TabsContent";

export const SettingsEmployerContainer = () => {
   const tabs = [
      {
         label: 'Профиль',
         components: <PersonalDataSettingContainer />
      },
      {
         label: 'Пароль',
         components: <PersonalPasswordContainer/>
      },
      {
         label: 'Уведомления',
         components: <NotificationsContainer />
      },
      {
         label: 'Данные организации',
         components: <OrganizationData />
      },
      {
         label: 'Фотогалерея',
         components: <PhotoGalleryEmployer />
      }
   ];


   return (
        <div className="bg-card page-setting">
            <Helmet>
                <title>Настройки</title>
            </Helmet>

            <TabsContent tabs={tabs} />
        </div>
    )
};

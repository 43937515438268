import React from 'react';
import Notifications from "./Notifications";
import {useDispatch, useSelector} from "react-redux";
import {settingsNotify} from "../../../actions/NotificationAction";

export const NotificationsContainer = () => {
   const dispatch = useDispatch();
   const user = useSelector(state => state.authentication.user);
   const notify = user.invitations_notify === 1;
   const userId = user.id;

   const handleSubmit = ({invitations_notify}) => {
      let newValues = invitations_notify === true ? 1 : 0;
      dispatch(settingsNotify(newValues, userId))
   };

   const initialValues = {
      invitations_notify: notify
   };

   return (
      <Notifications
         initialValues={initialValues}
         onSubmit={(values) => {
            handleSubmit(values)
         }}
      />

   )
};



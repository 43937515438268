export const homeConstants = {
  //список для университетов
  GET_LIST_UNIVERSITIES_SUCCESS: 'GET_LIST_UNIVERSITIES_SUCCESS',
  GET_LIST_UNIVERSITIES_FAILURE: 'GET_LIST_UNIVERSITIES_FAILURE',
  GET_LIST_UNIVERSITIES_FILTER_SUCCESS: 'GET_LIST_UNIVERSITIES_FILTER_SUCCESS',
  GET_LIST_UNIVERSITIES_FILTER_FAILURE: 'GET_LIST_UNIVERSITIES_FILTER_FAILURE',
  IS_LOADING_UNIVERSITIES: 'IS_LOADING_UNIVERSITIES',

  GET_UNIVERSITY_INFO: 'GET_UNIVERSITY_INFO',
  IS_LOADING_UNIVERSITY_INFO: 'IS_LOADING_UNIVERSITY_INFO',

  /* фильтр студентов */
  GET_LIST_STUDENTS_SEARCH_FAILURE: 'GET_LIST_STUDENTS_SEARCH_FAILURE',
  GET_LIST_STUDENTS_SEARCH_SUCCESS: 'GET_LIST_STUDENTS_SEARCH_SUCCESS',

  /* города */
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
  GET_CITIES_FAILURE: 'GET_CITIES_FAILURE'
};
import React from "react";

export const UploadFile= ({ id, input, type, meta: { touched, error } }) => {
   delete input.value;
   return (
      <>
         <input id={id} {...input} type={type}/>
         {touched && error && <span className='hasError'>{error}</span>}
      </>
   )
};

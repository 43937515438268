import {feedbackConstants} from "../constants/feedbackConstants";
import API from "../helpers/api";
import {toast} from "react-toastify";

export function addFavoritesSuccess(favorites) {
    return {
        type: feedbackConstants.ADD_FAVORITES_SUCCESS,
        favorites
    };
}

export function addFavorites(id) {
    return (dispatch) => {
        return API.post(`/favorites`, id)
            .then(favorites => {
                dispatch(addFavoritesSuccess(favorites.data.model));
                toast.success("Добавлено в избранное");
            })
            .catch(error => {
                console.log(error)
            })
    }
}

//удаление
export function favoritesDeleteSuccess(favorites) {
    return {
        type: feedbackConstants.FAVORITES_DELETE_SUCCESS,
        favorites
    };
}

export function favoritesDelete(id) {
    return (dispatch) => {
        return API.delete(`/favorites/${id}`)
            .then(favorite => {
                dispatch(favoritesDeleteSuccess(favorite.data.id));
                toast.success('Удаление из избранного');
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export function getFavoritesSuccess(favorite) {
    return {
        type: feedbackConstants.GET_FAVORITES_SUCCESS,
        favorite
    };
}

export function getFavoritesFailure(error) {
    return {
        type: feedbackConstants.GET_FAVORITES_FAILURE,
        error
    };
}

export function isLoadingFavorites(isLoading) {
  return {
    type: feedbackConstants.IS_LOADING_FAVORITES,
    isLoading
  };
}


export function getFavorites(params) {
    return (dispatch) => {
        dispatch(isLoadingFavorites(true));
        return API.get(`/favorites/search`,{params})
            .then(favorite => {
                dispatch(getFavoritesSuccess(favorite.data.items));
            })
            .catch(error => {
                dispatch(getFavoritesFailure(error))
            })
    }
}

export const personalDataConstants = {
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PROFILE_SETTING: 'CHANGE_PROFILE_SETTING',

    CHANGE_SETTINGS: 'CHANGE_SETTINGS', //для студента и работодателя
    CHANGE_SETTINGS_EMPLOYER_ORGANIZATION: 'CHANGE_SETTINGS_EMPLOYER_ORGANIZATION',
    GET_PROFILE_SETTINGS: 'GET_PROFILE_SETTINGS',
    POST_PROFILE_SETTING: 'POST_PROFILE_SETTING',
    CHANGE_PHOTO: 'CHANGE_PHOTO',

};
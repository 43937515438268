import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Preloader } from "../../Preloader/Preloader";
import { CompanyCard } from "./CompanyCard";
import { getCompany } from "../../../actions/CompanyAction";
import { getVacancyByParams } from "../../../actions/VacancyAction";

export const CompanyCardContainer = ({ match }) => {
   const dispatch = useDispatch();
   const checkAuth = useSelector((state) => state.authentication.user);
   const companyInfo = useSelector((state) => state.company);
   const vacancy = useSelector((state) => state.vacancy.vacancies);
   const gallery = useSelector((state) => state.media.portfolio);

   useEffect(() => {
      const id = match.params.companyId;
      dispatch(getCompany(id));
      dispatch(
         getVacancyByParams({
            hirer_id: id,
         })
      );
   }, [dispatch, match.params.companyId]);

   return companyInfo.isLoading || companyInfo.company === null ? (
      <Preloader />
   ) : (
      <CompanyCard
         companyInfo={companyInfo}
         checkAuth={checkAuth}
         gallery={gallery}
         vacancy={vacancy}
      />
   );
};

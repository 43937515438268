import React from 'react';
import {GeneralInformation} from "../GeneralInformation/GeneraInformation";
import {Keywords} from "../Keywords/Keywords";
import GalleryPhoto from "../GalleryPhoto/GalleryPhoto";
import {GetFeedbackListContainer} from "../Feedback/GetFeedback/GetFeedbackListContainer";
import {Avatar} from "../Avatar/Avatar";
import {ButtonFavorites} from "../ButtonOptions/ButtonOptions";
import {YandexMap} from "../YandexMap/YandexMap"
import {DeleteIcon} from "../Icons/Delete";
import {RejectIcon} from "../Icons/Reject";
import {AgreeIcon} from "../Icons/Agree";

export const VacancyCard = ({vacancy, gallery, ...props}) => {
    const vacancyInfo = vacancy.vacancyInfo;
    const list = [
        {name: 'Город:', intent: vacancyInfo.city, show: true},
        {name: 'Контактное лицо:', intent: `${vacancy.userInfo.username} ${vacancy.userInfo.surname}`, show: true},
        {name: 'Телефон:', intent: vacancy.userInfo.phone, show: false},
        {name: 'Email:', intent: vacancy.userInfo.email, show: false},
        {name: 'Сайт:', intent: vacancyInfo.hirer.site, show: true},
    ];

   const disabled = props.feedback === 1;
   const favorite = props.favorites === 1;

   return (
        <>
           <div className="inner-card inner-card--primary">
                <aside>
                    <div className="card-aside mb">
                        <div className="card-info card-bg">
                            <div className="card-info__head card-info__head--center">
                                <Avatar photo={vacancyInfo.hirer.photo} alt={vacancyInfo.hirer.name} class="card-info__img"/>
                                <h1 className="title-bold text-upper mb-none">{vacancyInfo.hirer.name}</h1>
                            </div>

                            <div className="card-info__body">
                                <h3 className="title-bold text-upper">Общая информация</h3>
                                <ul>
                                    <GeneralInformation list={list} checkAuth={props.checkAuth}/>
                                </ul>
                            </div>

                            {props.checkAuth === undefined &&
                               <div className="card-info__body">
                                   <p className="mb-none text-red">Персональные данные доступны только зарегистрированным
                                       пользователям</p>
                               </div>
                            }

                            {vacancyInfo.hirer.category &&
                               <div className="card-info__foot">
                                   <h3 className="title-bold text-upper">Отрасль компании</h3>
                                  <Keywords keywords={vacancyInfo.hirer.category} classBadge="badge--primary" />
                               </div>
                            }
                        </div>
                    </div>
                    <div className="card-map card-bg">
                        <h3>Местоположение</h3>
                        <p>{vacancyInfo.address}</p>
                        <YandexMap address={vacancyInfo.address} />
                    </div>
                </aside>

                <div className="inner-card__content card-content flex-element flex-element--justify-sb">
                    <div className="card-content__left">
                        <div className="card-about card-bg mb">

                            <div className="card-about__head">
                                <div className="card-about__top flex-element flex-element--justify-sb">
                                    <div className="card-about-group">
                                        <h3 className="title-bold mb-none">{vacancyInfo.name}</h3>
                                        {
                                          vacancyInfo.salary_undefined === 1 ?
                                          <h4 className="title-bold mb-none">з/п по договоренности</h4>
                                          :
                                          <h4 className="title-bold mb-none">{vacancyInfo.salary_from} - {vacancyInfo.salary_up}</h4>
                                        }
                                    </div>
                                    <div
                                        className="date">{new Date(vacancyInfo.created_at * 1000).toLocaleString('ru', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                    </div>
                                    {props.typeUser === 'student' &&
                                    <div className="button-group button-group--pt">
                                        <button className="button button-gradient-primary" disabled={disabled} onClick={props.handleOpenModal}>Откликнуться</button>

                                       {favorite ?
                                          <ButtonFavorites onClick={props.handleRemoveFavorites} class="active" />
                                          :
                                          <ButtonFavorites onClick={props.handleAddFavorites} />
                                       }

                                    </div>
                                    }
                                </div>
                                <div className="card-about__bottom flex-element flex-element--options">
                                    <div className="card-about__group">
                                        <ul>
                                            <li>Требуемый опыт работы: <b>{vacancyInfo.work_experience}</b></li>
                                            <li> Занятость: <b>{vacancyInfo.type_employment}</b></li>
                                        </ul>
                                        <div className="card-about-bottom-description">
                                           <p className="list-characteristics__content list-characteristics__content--pt"
                                              dangerouslySetInnerHTML={{__html: vacancyInfo.description.replace(/\n/g, '<br>').trim()}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list-characteristics-wrap">
                                <div className="list-characteristics">
                                    <div className="list-characteristics__title title-bold">Обязанности:</div>
                                    <p className="list-characteristics__content list-characteristics__content--pt"
                                         dangerouslySetInnerHTML={{__html: vacancyInfo.duties.replace(/\n/g, '<br>').trim()}}/>
                                </div>
                                <div className="list-characteristics">
                                    <div className="list-characteristics__title title-bold">Требования:</div>
                                    <p className="list-characteristics__content list-characteristics__content--pt"
                                         dangerouslySetInnerHTML={{__html: vacancyInfo.requirements.replace(/\n/g, '<br>').trim()}}/>
                                </div>
                                <div className="list-characteristics">
                                    <div className="list-characteristics__title title-bold">Условия:</div>
                                    <p className="list-characteristics__content list-characteristics__content--pt"
                                         dangerouslySetInnerHTML={{__html: vacancyInfo.conditions.replace(/\n/g, '<br>').trim()}}/>
                                </div>
                                <div className="list-characteristics">
                                    <div
                                        className="list-characteristics__title list-characteristics__title--min title-bold">Ключевые
                                        навыки:
                                    </div>
                                    <div className="list-characteristics__content list-characteristics__content--pt">{
                                        <Keywords keywords={vacancyInfo.keywords.split(", ")}
                                                  classBadge="badge--primary"/>}</div>
                                </div>

                                <div className="list-characteristics">
                                    <div
                                        className="list-characteristics__title list-characteristics__title--min title-bold">Профессиональная
                                        область:
                                    </div>
                                    <div
                                        className="list-characteristics__content list-characteristics__content--pt">
                                        {vacancyInfo.category &&
                                            <Keywords keywords={vacancyInfo.category} classBadge="badge--light" />
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                        {props.inviteInfo &&
                        <>
                            <div className="message-block card-bg mb">
                                <div className="flex-element flex-element--options">
                                    <h4 className="min title-semi-bold dark mb-none">{props.myFeedback === true ? "Ваше сообщение" : 'Сообщение от работодателя'}</h4>
                                    <div
                                        className="date min">{new Date(props.inviteInfo.created_at * 1000).toLocaleString('ru', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric'
                                    })}</div>
                                </div>

                                <div className="message-block__content">{props.inviteInfo.message}</div>
                            </div>

                            <div className="btn-options">
                                {props.myFeedback === true ?
                                    <>
                                        <button className="button btn-option btn-option--gray-dark"
                                                onClick={() => props.handleClickDeleteFeedback(props.inviteInfo.id)}>
                                                <span>
                                                  <DeleteIcon />
                                                </span> Удалить
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className="button btn-option btn-option--green" onClick={() => props.handleClickTake(props.inviteInfo.id)}>
                                            <span>
                                              <AgreeIcon />
                                            </span> Принять
                                        </button>
                                        <button className="button btn-option btn-option--red"
                                                onClick={() => props.handleClickReject(props.inviteInfo.id)}>
                                                <span>
                                                     <RejectIcon />
                                                </span> Отклонить
                                        </button>
                                        <button className="button btn-option btn-option--gray-dark"
                                                onClick={() => props.handleClickDelete(props.inviteInfo.id)}>
                                                <span>
                                                  <DeleteIcon />
                                                </span>
                                            Удалить
                                        </button>
                                    </>
                                }
                            </div>
                        </>
                        }
                    </div>

                    <div className="card-content__right">
                        <div className="card-bg card-photo">
                            <h3 className="title-bold mb-none">Фотогалерея</h3>
                            {gallery ? <GalleryPhoto gallery={gallery}/> : null}
                        </div>
                    </div>
                </div>

                {props.typeUser === 'hirer' &&  <GetFeedbackListContainer vacancyId={props.vacancyId} userId={props.userId} match={props.match}/> }
            </div>
        </>
    )
};

import React from "react";
import { NavLink, Route, Redirect } from "react-router-dom";
import LoginBase from "../components/Login/LoginBase/LoginBase";
import { LoginStudent } from "../components/Login/LoginStudent/LoginStudent";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet/es/Helmet";

export const LoginPage = ({ match }) => {
   const user = useSelector((state) => state.authentication);
   return (
      <div className="login-page-wrap flex-element flex-element--align-center">
         <Helmet>
            <title>Авторизация</title>
         </Helmet>
         <div className="login-page">
            <div className="login-page__content">
               <div className="login-page__title title-bold text-black">
                  Войти
               </div>
               <Route exact path={`${match.path}`} component={LoginBase} />
               <Route path={`${match.path}/student`} component={LoginStudent} />
            </div>
            <div className="login-page__footer">
               <p>Нет аккаунта</p>
               <NavLink
                  to="/registration"
                  className="button button-green-gradient button-small button-upper button-indent button-bold"
               >
                  Зарегистрироваться
               </NavLink>
            </div>
         </div>
         {user.loggedIn && <Redirect from="/university" to="/" />}
      </div>
   );
};

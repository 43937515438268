import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

const PrivateRouteUniversity= ({ component: Component, store, ...rest }) => {
  const { authentication } = rest;
  const loggedIn = localStorage.getItem('loggedIn');
  return (
      <Route {...rest} render={props =>
          (loggedIn ==='true' && authentication.user.type === 'student' ? (
              <Component {...props} />
          ) : (
              <Redirect to={{pathname: '/', state: {from: props.location}}}/>
          ))
      } />
  )
};

const mapStateToProps = state =>  ({
  authentication: state.authentication
});

export default connect(mapStateToProps)(PrivateRouteUniversity)
import {universityConstants} from '../constants/universityConstants';
import API from "../helpers/api";
import {toast} from "react-toastify";

export function getSettingsUniversitySuccess(settings) {
  return {
    type: universityConstants.GET_UNIVERSITY_SETTINGS,
    settings
  };
}

export function getSettingsUniversity(id) {
  return (dispatch) => {
    return API.get(`/university/${id}`)
        .then(settings => {
          dispatch(getSettingsUniversitySuccess(settings.data))
        })
        .catch(error => {
          console.log(error)
        })
  }
}

/* ASSESSMENTS */

export const getSubjectSuccess = (subjects) => ({
  type: universityConstants.GET_SUBJECTS_SUCCESS,
  subjects
});

export const getSubjectFailure = (error) => ({
  type: universityConstants.GET_SUBJECTS_FAILURE,
  error
});

export function getSubjects(id) {
  return dispatch => {
    return API.get(`/students-assessments/search?student_id=${id}`)
        .then(subjects => {
          dispatch(getSubjectSuccess(subjects.data.items))
        })
        .catch(error => dispatch(getSubjectFailure(error)))
  }
}


export function deleteSubjectSuccess(id) {
  return {
    type: universityConstants.DELETE_SUBJECT,
    id
  }
}

export function deleteSubject(id) {
  return (dispatch) => {
    return API.delete(`/students-assessments/${id}`)
      .then(subject => {
        dispatch(deleteSubjectSuccess(subject.data.id));
        toast.success('Предмет удален')
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const editSubjectSuccess = (subject) => ({
  type: universityConstants.EDIT_SUBJECTS_SUCCESS,
  subject
});

export function editSubject(values) {
  return dispatch => {
    return API.patch(`/students-assessments/${values.id}`, values)
        .then(subject => {
          dispatch(editSubjectSuccess(subject.data));
          toast.success('Сохранение')
        })
        .catch(error => console.log(error))
  }
}


export const addSubjectSuccess = (subject) => ({
  type: universityConstants.ADD_SUBJECT_SUCCESS,
  subject
});

export function addSubject(subject) {
  return dispatch => {
    return API.post(`/students-assessments`, subject)
        .then(subject => {
          dispatch(addSubjectSuccess(subject.data.model));
          toast.success('Вы добавили новый предмет')
        })
        .catch(error => console.log(error))
  }
}



import React from 'react'
import {Field, reduxForm} from 'redux-form';
import {FieldInput} from '../../FormElement/Field/FieldInput';
import {FieldMask} from "../../FormElement/Field/FieldMask";
import validate from "../../FormElement/validate";
import {QuillEditor} from "../../FormElement/Field/QuillEditor";
import {FieldImage} from "../../FormElement/Field/FieldImage";

const UniversityDataForm = ({handleSubmit}) => (
    <form className="form flex-element" onSubmit={e => handleSubmit(e)}>
      <label htmlFor="photo" className="visually-hidden">Аватар</label>
      <Field
          id="photo"
          name="photo"
          type="file"
          component={FieldImage}
      />

      <div className="form__group">
         <fieldset>
            <legend>Общая информация</legend>
            <div className="form__line flex-element">

               <div className="form__item">
                  <label className="form__label" htmlFor="abbreviation">Аббревиатура</label>
                  <Field component={FieldInput} id="abbreviation" name="abbreviation" type="text" props={{disabled: true}}/>
               </div>

               <div className="form__item">
                  <label className="form__label" htmlFor="full_name">Полное наименование</label>
                  <Field component={FieldInput} id="full_name" name="full_name" type="text" props={{disabled: true}}/>
               </div>
            </div>
            <div className="form__item">
               <label className="form__label" htmlFor="since">Год основания</label>
               <Field
                  component={FieldInput}
                  id="since"
                  name="since"
                  type="number"
               />
            </div>

            <div className="form__item">
               <label className="form__label" htmlFor="location">Местоположение</label>
               <Field component={FieldInput} id="location" name="location" type="text"/>
            </div>

            <div className="form__item">
               <label className="form__label" htmlFor="city">Город</label>
               <Field component={FieldInput} id="city" name="city" type="text"/>
            </div>

            <div className="form__item">
               <label className="form__label" htmlFor="rector">Ректор</label>
               <Field component={FieldInput} id="rector" name="rector" type="text" placeholder="Укажите ФИО"/>
            </div>

            <div className="form__item">
               <label className="form__label" htmlFor="reception_phone">Телефон приемной</label>
               <Field
                  component={FieldMask}
                  name="reception_phone"
                  id="reception_phone"
                  placeholder="+7 (___) ___-__-__"
                  mask="+7 (999) 999-99-99"
               />
            </div>

            <div className="form__item">
               <label className="form__label" htmlFor="site">Сайт</label>
               <Field component={FieldInput} id="site" name="site" type="text"/>
            </div>

            <div className="form__item">
               <label className="form__label" htmlFor="endowment_fund">Эндаумент фонд</label>
               <Field component={FieldInput} id="endowment_fund" name="endowment_fund" type="text"/>
            </div>

            <div className="form__item form__item--textarea">
               <label className="form__label" htmlFor="description">Описание</label>
               <Field component={QuillEditor} id="description" name="description" type="text"/>
            </div>

            <button className="button button-green-gradient button-indent button-bold button-save">Сохранить</button>
         </fieldset>
      </div>
    </form>
);

export default reduxForm({
  form: 'settingsUniversity',
  validate,
  enableReinitialize: true
})(UniversityDataForm)

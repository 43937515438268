import React from 'react';

export const PasswordForStudentsList = ({data}) => (
  <>
    {
      data.map((student, index) => {
        return <tr key={index}>
          <td>{student.fio}</td>
          <td>{student.ticket}</td>
          <td>{student.password}</td>
        </tr>
      })
    }
  </>
);
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
   invitationDelete,
   invitationRequestGet,
} from "../../../actions/FeedbackAction";
import { MySendFeedbackList } from "./MySendFeedbackList";
import { Preloader } from "../../Preloader/Preloader";
import { Helmet } from "react-helmet/es/Helmet";
import { TabsContent } from "../../Tabs/TabsContent";

const TableThead = () => (
   <thead>
      <tr>
         <th scope="col">Дата</th>
         <th scope="col">Отправленное резюме</th>
         <th scope="col">Вакансия</th>
         <th scope="col">Статус</th>
         <th scope="col">Действие</th>
      </tr>
   </thead>
);

export const MySendFeedbackListContainer = ({ match }) => {
   const user = useSelector((state) => state.authentication.user);
   const feedback = useSelector((state) => state.feedback);
   const dispatch = useDispatch();
   const url = match.url;

   useEffect(() => {
      const userId = user.id;
      dispatch(
         invitationRequestGet({
            from_uid: userId,
         })
      );
   }, [dispatch, user.id]);

   const handleClickDelete = (id) => {
      dispatch(invitationDelete(id));
   };

   /*
    *  10 - приянто
    *  5 - отказано
    *  0 - отправлен
    *  -1 - отказано, необходим для удаление со стороны работодателя, но для отображение со стороны студента
    *  -2 - принято, необходим для удаление со стороны работодателя, но для отображение со стороны студента
    * */

   const FeedbackListAll = feedback.invite;
   const FeedbackListReject = feedback.invite.filter(
      (status) => status.status === 5 || status.status === -1
   );
   const FeedbackListOffer = feedback.invite.filter(
      (status) => status.status === 10 || status.status === -2
   );

   const tabs = [
      {
         label: "Мои отклики",
         components: (
            <FeedbackContent
               list={FeedbackListAll}
               text="У вас нет откликов"
               url={url}
               handleClickDelete={handleClickDelete}
            />
         ),
      },
      {
         label: "Принятые",
         components: (
            <FeedbackContent
               list={FeedbackListOffer}
               text="У вас нет принятых откликов"
               url={url}
               handleClickDelete={handleClickDelete}
            />
         ),
      },
      {
         label: "Отклоненные",
         components: (
            <FeedbackContent
               list={FeedbackListReject}
               text="У вас нет отклоненных откликов"
               url={url}
               handleClickDelete={handleClickDelete}
            />
         ),
      },
   ];

   return (
      <>
         <Helmet>
            <title>Мои отклики</title>
         </Helmet>

         {feedback.isLoading ? (
            <Preloader />
         ) : (
            <div className="bg-card bg-card--inner">
               <TabsContent tabs={tabs} />
            </div>
         )}
      </>
   );
};

const FeedbackContent = ({ list, text, url, handleClickDelete }) =>
   list.length === 0 ? (
      <p className="mb-none txt-info">{text}</p>
   ) : (
      <div className="table-responsive">
         <table className="table table--shadow-none table--upper table-small-size table--head-transparent">
            <TableThead />
            <tbody>
               <MySendFeedbackList
                  data={list}
                  handleClickDelete={handleClickDelete}
                  url={url}
               />
            </tbody>
         </table>
      </div>
   );

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { postVacancy } from "../../../actions/VacancyAction";
import VacancyForm from "./VacancyForm";
import { getFilter } from "../../../reducers/ClassifiersRedicer";
import { Helmet } from "react-helmet/es/Helmet";
import { mapArrayToString } from "../../../helpers/conversion";
import { status } from "../../../constants/status";

export const CreateVacancy = () => {
   const user = useSelector((state) => state.authentication);
   const classifier = useSelector(
      (state) => state.filterClassifier.filterCategory
   );
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getFilter("categories", "category"));
   }, [dispatch]);

   const handleSubmit = (values) => {
      let copyValues = Object.assign({}, values);
      mapArrayToString(copyValues, [
         "type_employment",
         "work_time",
         "category",
         "keywords",
      ]);

      values.hirer_id = user.user.hirer_local.id;

      if (values.salary_undefined) {
         copyValues.salary_undefined = "1";
         copyValues.salary_from = 0;
         copyValues.salary_up = 100;

         dispatch(postVacancy(copyValues));
      } else {
         copyValues.salary_undefined = "0";
         dispatch(postVacancy(copyValues));
      }
   };

   const initialValues = {
      salary_undefined: false,
   };

   if (user.user.hirer_local.status === status.DISABLED) {
      return <Redirect to="/employer/vacancy" />;
   }

   return (
      <div className="bg-card create-block">
         <Helmet>
            <title>Создать вакансию</title>
         </Helmet>

         <h1 className="title-bold dark display-average">Создать вакансию</h1>
         <VacancyForm
            initialValues={initialValues}
            data={classifier}
            onSubmit={(values) => {
               handleSubmit(values);
            }}
         />
      </div>
   );
};

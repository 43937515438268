import {Avatar} from "../Avatar/Avatar";
import React from "react";

export const Education  = ({education}) => {
   const educationDetails = education.details;
   return (
      <div className="card-university flex-element">
         <h3 className="title-bold">Образование</h3>
         <div className="card-university__icon">
            <Avatar photo={educationDetails.university_photo} alt={educationDetails.university_abbreviation} class="card-info__img card-info__img--circle" />
         </div>

         <div className="card-university__information">
            <div className="card-university__title title-inner-bold flex-element">
               {educationDetails.university_abbreviation} - {educationDetails.university_name}
               {educationDetails.city}
            </div>

            <p>{educationDetails.faculty_name}, {educationDetails.speciality_name}</p>
            <div className="card-university__foot">
               <div className="card-university__foot-content">Курс обучения: <span>{education.course}</span></div>
               <div className="card-university__foot-content">Средний балл: <span>{educationDetails.average_ball}</span></div>
            </div>
         </div>
      </div>
   )
};

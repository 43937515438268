import React from 'react';
import RegistrationForm from './RegistrationEmployerForm';
import {Helmet} from "react-helmet/es/Helmet";

export const RegistrationEmployee = () => {
    return (
        <div className="content-home">
            <Helmet>
                <title>Регистрация работодателя</title>
            </Helmet>
            <div className="content-home__title title-bold">Регистрация работодателя</div>
            <div className="content-home__form">
                <RegistrationForm />
            </div>
        </div>
    )
};

import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "../FormElement/validate";
import { FieldInput } from "../FormElement/Field/FieldInput";
import { UploadFile } from "../FormElement/Field/UploadDile";

function UploadFileFaculties({ handleSubmit }) {
   return (
      <form className="form" onSubmit={(e) => handleSubmit(e)}>
         <div className="item">
            <div className="upload">
               <label htmlFor="file-faculty" aria-label="Загрузить из файла">
                  <Field
                     id="file-faculty"
                     name="file"
                     type="file"
                     accept=".xlsx"
                     component={UploadFile}
                  />
               </label>
            </div>
         </div>

         <div className="item">
            <label className="form__label" htmlFor="name_start">
               Введите номер ячейки с наименование факультета
            </label>
            <Field
               name="name_start"
               id="name_start"
               component={FieldInput}
               type="text"
               placeholder="например, A3"
            />
         </div>

         <button className="button button-gradient-primary button-indent button-submit">
            Сохранить
         </button>
      </form>
   );
}

export default reduxForm({
   form: "file",
   validate,
})(UploadFileFaculties);

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getListVacancySearch} from "../../../actions/VacancyAction";
import {getListVacancy} from "../../../actions/VacancyAction";
import {MainVacancyList} from "./MainVacancyList";
import FilterVacancies from "../../Filter/FilterVacancies";
import {flattenObjHelper} from "../../Filter/flatteronObjHelper";
import {Preloader} from "../../Preloader/Preloader";
import {Pagination} from "../../Pagination/Pagination";
import {Helmet} from "react-helmet/es/Helmet";
import {getFilter} from "../../../reducers/ClassifiersRedicer";
import {FilterIcon} from "../../Icons/Filter";

export const MainVacancyListContainer = ({match}) => {
   const dispatch = useDispatch();
   const classifier = useSelector(state => state.filterClassifier.filterCategory);
   const vacancyList = useSelector(state => state.vacancy);
   const [filterOpen, setFilterOpen] = useState(false);

   useEffect(() => {
      dispatch(getListVacancy(1));
      dispatch(getFilter('categories', 'category'));
   }, [dispatch]);

   const handleSubmit = (values) => {
      const objCopy = flattenObjHelper(values);
      if (values.category) {
         objCopy.category = values.category.map(item => item.value).join(',');
      }

      dispatch(getListVacancySearch(objCopy));
      setFilterOpen(false)
   };

   const onSetPage = (page) => {
      dispatch(getListVacancy(page))
   };

   const handleOpenFilter = (e) => {
      e.preventDefault();
      filterOpen ? setFilterOpen(false) : setFilterOpen(true);
   };

   return (
      <>
         <Helmet>
            <title>Вакансии</title>
         </Helmet>

         {vacancyList.isLoading ? <Preloader/> :
            <>
               <h1 className="dark title-semi-bold title-bold display-large">Вакансии</h1>
               <div className="inner-block inner-block--primary flex-element flex-element--justify-sb">

                  <aside className="aside-outside aside-outside--secondary relative">
                     <button aria-label="Открыть фильтр" className="filter-button" onClick={handleOpenFilter}>
                        <FilterIcon />
                     </button>

                     <div className={`bg-card filter-block ${filterOpen ? 'active' : ''}`}>
                        <FilterVacancies
                           data={classifier}
                           onSubmit={(values) => {
                              handleSubmit(values)
                           }}
                        />
                     </div>
                  </aside>
                  <div className="inner-block__content">
                     <p>Найдено: {vacancyList.vacancies.length}</p>
                     <MainVacancyList vacancyList={vacancyList.vacancies} match={match}/>
                     {vacancyList.pagination !== undefined &&
                        <Pagination
                           pageCount={vacancyList.pagination.pageCount}
                           currentPage={vacancyList.pagination.currentPage}
                           onSetPage={onSetPage}
                        />
                     }
                  </div>
               </div>
            </>
         }
      </>
   )
};

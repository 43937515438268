import { feedbackConstants } from "../constants/feedbackConstants";

const initialStateFeedback = {
   isLoading: false,
   invite: [],
};

export function feedback(state = initialStateFeedback, action) {
   switch (action.type) {
      case feedbackConstants.FEEDBACK_INVITATION_LOADING:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case feedbackConstants.FEEDBACK_INVITATION_REQUEST_GET_SUCCESS:
         return {
            ...state,
            isLoading: false,
            invite: action.invite,
         };

      case feedbackConstants.FEEDBACK_INVITATION_REQUEST_GET_FAILURE:
         return {
            isLoading: false,
            invite: [],
         };

      case feedbackConstants.FEEDBACK_INVITATION_DELETE:
         return {
            ...state,
            invite: state.invite.filter((id) => id.id !== action.invite),
         };

      case feedbackConstants.FEEDBACK_INVITATION_REQUEST:
         return {
            ...state,
            invite: [...state.invite, action.invite],
         };

      case feedbackConstants.CHANGE_INVITE_SUCCESS:
         return {
            ...state,
            invite: state.invite.map((item) => {
               if (item.id === action.invite.id) {
                  return {
                     ...item,
                     status: action.invite.status,
                  };
               }
               return item;
            }),
         };

      default:
         return state;
   }
}

/* student/my-feedback/card */
const stateInviteInformation = {
   isLoading: false,
   inviteInfo: null,
   userInfo: null,
};

export function inviteInformationForCard(
   state = stateInviteInformation,
   action
) {
   switch (action.type) {
      case feedbackConstants.LOADING_INVITATION_INFO: {
         return {
            ...state,
            isLoading: true,
         };
      }
      case feedbackConstants.GET_INVITATION_INFO_SUCCESS:
         return {
            ...state,
            inviteInfo: action.inviteInfo,
            userInfo: action.userInfo,
            isLoading: false,
         };
      case feedbackConstants.GET_INVITATION_INFO_FAILURE:
         return {};
      default:
         return state;
   }
}

import {useEffect} from 'react';
import {getUniversities} from "../../actions/HomeAction";
import {getSpecialities} from "../../actions/Universtiy/SpecialtyAction";
import {useDispatch, useSelector} from "react-redux";

export const useListForSelect = () => {
    const dispatch = useDispatch();

    const universityOptions = useSelector(state => state.universities.universities);
    const specialitiesOptions = useSelector(state => state.specialty);

    useEffect(() => {
        dispatch(getUniversities());
        dispatch(getSpecialities());
    },[dispatch]);

    return {
        universityOptions,
        specialitiesOptions
    }
};
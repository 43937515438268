import React from 'react';
import {Avatar} from "../Avatar/Avatar";
import LogoExample from '../../assets/img/logoExample.jpg'

export const CardPreviewVacancy = ({data, dop}) => (
   <>
      <div className="card-preview__img card-preview__img--border">
         {dop ?
            <Avatar photo={data["hirer"].photo} alt={data.name}/>
            :
            <img src={LogoExample} alt={data.name} />
         }
      </div>
      <div className="card-preview__content">
         <div className="card-preview__title  title-semi-bold">
            {data.name}
            {dop &&
               <>
                  <span>• {data.city}</span>
                  <span>, {data.hirer.name}</span>
               </>
            }
         </div>
         <div className="card-preview__info">{data.duties.length > 250 ? data.duties.slice(0, 250) + `.....` : data.duties}</div>
         <div className="card-preview__foot card-preview__foot--pt">
            {
               data.salary_undefined === 1 ?
                  <span className="salary title-semi-bold">з/п по договоренности</span>
                  :
                  <span className="salary title-semi-bold">{data.salary_from} - {data.salary_up}</span>
            }
         </div>
      </div>
   </>
);

import React, {useState} from 'react';
import FormCourse from "./FormCourse";
import {flattenObjHelper} from "../../Filter/flatteronObjHelper";

export const Subjects = ({assessments, handleSubjectDelete, handleEditSubjectSubmit}) => {
   const [editing, setEditing] = useState('');

   const handleEdit = (id) => {
      setEditing(id)
   };

   const handleSubmitEdit = (values) => {
      const objCopy = flattenObjHelper(values);
      if (objCopy.type === '') {
         objCopy.mark = 0;
      }

      handleEditSubjectSubmit(objCopy);
      setEditing('')
   };

   return (
       assessments.map(item => {
          return (
              <div className="list-subject__item flex-element" key={item.id}>
                 {
                    editing === item.id ?
                        <>
                           <FormCourse
                               form={'assessments-' + item.id}
                               onSubmit={handleSubmitEdit}
                               initialValues={{
                                  ...item,
                                  type: item.type !== 'exam' ? {label: 'Зачет', value: ''} : {label: 'Экзамен', value: 'exam'},
                               }}
                           />
                        </>
                        :
                        <>
                           <div className="list-subject__group flex-element flex-element--align-center">
                              <div className="list-subject__name">{item.name}</div>
                              <div className="list-subject__mark">
                                 {item.mark === 0 ? 'Зачет' : item.mark}
                              </div>
                              <div className="list-subject__button flex-element">
                                 <button className="button-action flex-element flex-element--align-center" aria-label="Редактировать"
                                         onClick={() => handleEdit(item.id)}>
                                    <svg width="18" height="20" viewBox="0 0 512 512.00004" xmlns="http://www.w3.org/2000/svg">
                                       <path d="m511.132812 79.929688c-.019531-21.390626-8.367187-41.488282-23.507812-56.59375-31.226562-31.15625-81.992188-31.113282-113.183594.117187l-322.207031 323.503906c-10.480469 10.472657-18.480469 23.4375-23.136719 37.496094l-.300781.914063-28.796875 126.632812 126.984375-28.429688.945313-.3125c14.0625-4.65625 27.035156-12.648437 37.542968-23.152343l322.25-323.542969c15.113282-15.132812 23.429688-35.246094 23.410156-56.632812zm-440.714843 375.34375-13.464844-13.472657 9.722656-42.765625 46.613281 46.640625zm389.003906-346.9375-312.847656 314.105468-56.652344-56.6875 214.300781-215.160156 32.632813 32.632812 28.261719-28.261718-32.691407-32.691406 30.402344-30.519532 32.75 32.75 28.261719-28.261718-32.808594-32.808594 11.707031-11.753906c15.605469-15.625 41.023438-15.648438 56.65625-.050782 7.578125 7.5625 11.757813 17.625 11.769531 28.332032.007813 10.710937-4.152343 20.777343-11.742187 28.375zm-249.164063 363.261718h300.875v39.96875h-340.707031zm0 0"/>
                                    </svg>
                                 </button>
                                 <button className="button-action flex-element flex-element--align-center" aria-label="Удалить"
                                         onClick={() => handleSubjectDelete(item.id)}>
                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                       <path
                                           d="M17 4H13V3C13 2.20435 12.6839 1.44129 12.1213 0.87868C11.5587 0.316071 10.7956 0 10 0H8C7.20435 0 6.44129 0.316071 5.87868 0.87868C5.31607 1.44129 5 2.20435 5 3V4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H2V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V6H17C17.2652 6 17.5196 5.89464 17.7071 5.70711C17.8946 5.51957 18 5.26522 18 5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4ZM7 3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H10C10.2652 2 10.5196 2.10536 10.7071 2.29289C10.8946 2.48043 11 2.73478 11 3V4H7V3ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H5C4.73478 18 4.48043 17.8946 4.29289 17.7071C4.10536 17.5196 4 17.2652 4 17V6H14V17Z"
                                           fill="#363E68"/>
                                    </svg>
                                 </button>
                              </div>
                           </div>
                        </>
                 }
              </div>
          )
       })
   )
};

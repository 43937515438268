import React, {useState} from "react";
import {Field} from "redux-form";
import {FieldInput} from "../../../FormElement/Field/FieldInput";
import FieldTextarea from "../../../FormElement/Field/FieldTextarea";
import {MonthNames} from "../../../FormElement/Options";
import {FieldSelect} from "../../../FormElement/Field/FieldSelect";

export const LayoutsWorkExperience = ({fields, meta: {touched, error, submitFailed}}) => {
   return (
      <>
         {fields.map((experience, index) => (
            <LayoutsItemWorkExperience key={index} experience={experience} index={index} fields={fields} />
         ))}

         <button className="button button-gradient-primary button-indent button-small button-experience" type="button"
                 onClick={() => fields.push({until_now: true})}>
            Добавить место работы
         </button>
         {(touched || submitFailed) && error && <span className="hasError">{error}</span>}
      </>
   )
};

const LayoutsItemWorkExperience =  ({experience, fields, index}) => {
   const [show, setShow] = useState(Boolean(fields.get(index).until_now));

   const handleChange = () => {
      setShow(!show)
   };

   return (
      <div className="work-experience__block">
         <div className="form__bg">
            <div className="form__item form__item--custom">
               <label className="form__label">
                  <span>Организация</span>
                  <Field
                     name={`${experience}.organization_name`}
                     type="text"
                     component={FieldInput}
                  />
               </label>

            </div>
            <div className="form__item form__item--custom">
               <label className="form__label">
                  <span>Должность</span>
                  <Field
                     name={`${experience}.post`}
                     type="text"
                     placehoder="Название компании"
                     component={FieldInput}
                  />
               </label>
            </div>
            <div className="form__item form__item--custom">
               <label className="form__label">
                  <span>Обязанности на рабочем месте</span>
                  <Field
                     name={`${experience}.responsibility`}
                     placehoder="Опишите, что вы делали на работе"
                     component={FieldTextarea}
                  />
               </label>
            </div>
            <div className="form__inline flex-element">
               <div className="form__group-wrap">
                  <fieldset>
                     <legend>Начало работы:</legend>
                     <div className="form__group flex-element">
                        <div className="form__item form__item--custom">
                           <label className="form__label">
                              <Field
                                 name={`${experience}.start_month`}
                                 options={MonthNames}
                                 type="text"
                                 placeholder="Месяц"
                                 component={FieldSelect}
                              />
                           </label>
                        </div>
                        <div className="form__item form__item--custom">
                           <label className="form__label">
                              <Field
                                 name={`${experience}.start`}
                                 type="text"
                                 placeholder="Год"
                                 component={FieldInput}
                              />
                           </label>
                        </div>
                     </div>
                  </fieldset>
                  {show !== true &&
                  <fieldset>
                     <legend>Окончание работы:</legend>
                     <div className="form__group flex-element">
                        <div className="form__item form__item--custom">
                           <label className="form__label">
                              <Field
                                 name={`${experience}.end_month`}
                                 options={MonthNames}
                                 type="text"
                                 placeholder="Месяц"
                                 component={FieldSelect}
                              />
                           </label>
                        </div>
                        <div className="form__item form__item--custom">
                           <label className="form__label">
                              <Field
                                 name={`${experience}.end`}
                                 type="text"
                                 placeholder="Год"
                                 component={FieldInput}
                              />
                           </label>
                        </div>
                     </div>
                  </fieldset>
                  }

                  <div className="form__item form__item--width">
                     <Field
                        name={`${experience}.until_now`}
                        id={`${experience}.until_now`}
                        component="input"
                        type="checkbox"
                        onChange={handleChange}
                     />
                     <label className="label-custom mb-none label-custom--secondary" htmlFor={`${experience}.until_now`}>
                        <span>По настоящее время</span>
                     </label>
                  </div>
               </div>

               <button className="button-action button-action--gray-dark flex-element flex-element--align-center"
                       aria-label="Удалить" title="Удалить" onClick={() => fields.remove(index)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none"
                       aria-hidden="true">
                     <path
                        d="M17 4H13V3C13 2.20435 12.6839 1.44129 12.1213 0.87868C11.5587 0.316071 10.7956 0 10 0H8C7.20435 0 6.44129 0.316071 5.87868 0.87868C5.31607 1.44129 5 2.20435 5 3V4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H2V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V6H17C17.2652 6 17.5196 5.89464 17.7071 5.70711C17.8946 5.51957 18 5.26522 18 5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4ZM7 3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H10C10.2652 2 10.5196 2.10536 10.7071 2.29289C10.8946 2.48043 11 2.73478 11 3V4H7V3ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H5C4.73478 18 4.48043 17.8946 4.29289 17.7071C4.10536 17.5196 4 17.2652 4 17V6H14V17Z"
                        fill="white"/>
                  </svg>
               </button>
            </div>

         </div>
      </div>
   )
};

import React, {useState} from 'react';
import {Field, reduxForm} from 'redux-form';
import {optionsTraining, courseList} from "../FormElement/Options";
import {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
import {FieldSelect} from "../FormElement/Field/FieldSelect";
import {FieldDaData} from "../FormElement/Field/FieldDaData";

const DynamicBounds = ({input}) => {
  const [sliderValues, setSliderValues] = useState([3, 5]);

  const handleChange = (sliderValues) => {
    setSliderValues(sliderValues);
    input.onChange(sliderValues);
  };

  return (
      <div className="average-ball">
        <div className="average-ball__head flex-element flex-element--options">
          <span>от {sliderValues[0]}</span><span>до {sliderValues[1]} </span>
        </div>

        <Range min={3} max={5} defaultValue={sliderValues} onChange={handleChange}
               railStyle={{backgroundColor: '#d2d8e4', height: 1}}

        />
      </div>
  )
};

const FilterStudents = ({universityOptions, specialitiesOptions, handleSubmit}) => {
  const universities = universityOptions.map(university => ({
    label: university.abbreviation,
    value: university.id
  }));

  const specialty = specialitiesOptions.map(speciality => ({label: speciality.name, value: speciality.name}));

  return (
      <form className="form" onSubmit={e => handleSubmit(e)}>
          <div className="form__group">
            <div className="form__item form__item--custom">
              <label className="form__label">
                 <span>Город</span>
                 <Field
                    name="city"
                    type="text"
                    component={FieldDaData}
                 />
              </label>

            </div>

            <div className="form__item">
              <label className="form__label" htmlFor="university_id">Университет/Вуз</label>
              <Field
                  id="university_id"
                  name="university_id"
                  type="text"
                  placeholder="Выберите университет/Вуз"
                  options={universities}
                  component={FieldSelect}
              />
            </div>

            <div className="form__item">
              <label className="form__label" htmlFor="specialty_name">Специальность</label>

              <Field
                  id='specialty_name'
                  name="specialty_name"
                  options={specialty}
                  component={FieldSelect}
                  placeholder="Введите специальность"
              />
            </div>

            <div className="form__item">
              <label className="form__label" htmlFor="training_form">Квалификация</label>

              <Field
                  id='training_form'
                  name="training_form"
                  options={optionsTraining}
                  component={FieldSelect}
                  placeholder="Выберите квалификацию"
              />
            </div>

            <div className="form__item">
              <label className="form__label" htmlFor="course">Курс</label>

              <Field
                  id='course'
                  name="course"
                  options={courseList}
                  component={FieldSelect}
                  placeholder="Выберите курс"
              />
            </div>

            <div className="form__item">
              <label className="form__label" htmlFor="average_ball">Средний балл</label>
              <Field
                  id='average_ball'
                  name="average_ball"
                  options={courseList}
                  component={DynamicBounds}
              />

            </div>
          </div>
        <button className="button button-green-gradient button-bold button-small button-upper button-indent">Найти
        </button>
      </form>
  )
};

export default reduxForm({
  form: 'filter'
})(FilterStudents)

import React from 'react';
import { Link } from "react-router-dom";
import {ButtonDelete} from "../../ButtonOptions/ButtonOptions";

export const GroupTableList = ({ data, handleClickDelete, url }) => (
    <>
      {
        data.map((group) => {
          return <tr key={group.id}>
            <td> <Link to={{
              pathname: `${url}/${group.id}`,
              state: {
                fromNotifications: true
              }
            }}>{group.name} </Link></td>

            <td>{group.training_form}</td>
            <td>{group.study_form}</td>
            <td>{group.course}</td>
            <td>{group.infoCount.students}</td>
            <td>
               <ButtonDelete onClick={() => handleClickDelete(group.id)} />
            </td>
          </tr>
        })
      }
    </>
);

import React, {useState, useRef, useEffect} from 'react';
import {Field, reduxForm} from 'redux-form';
import {courseList, optionsTraining, relocation, trip, typeEmployment} from "../FormElement/Options";
import {FieldSelect} from "../FormElement/Field/FieldSelect";
import {useListForSelect} from "./useListForSelect";
import {FieldInput} from "../FormElement/Field/FieldInput";
import {FieldDaData} from "../FormElement/Field/FieldDaData";
import {FilterIcon} from "../Icons/Filter";

function FilterMain({handleSubmit, updateSelect}) {
   const [openFilter, setOpenFilter] = useState(false);
   const [typeFilter, setTypeFilter] = useState({type: 'resumes', txt: 'резюме', name: 'post'});
   const node = useRef();

   const handleOpenFilter = (e) => {
      e.preventDefault();
      setOpenFilter(!openFilter);
   };

   const handleChangeTypeFilter = (e, options) => {
      e.preventDefault();
      setTypeFilter(options);
      updateSelect(options.type)
   };

   const handleClickOutside = (e) => {
      if (node.current.contains(e.target)) {
         // inside click
         return;
      }
      // outside click
      setOpenFilter(false);
   };

   useEffect(() => {
      if (openFilter) {
         document.addEventListener("mousedown", handleClickOutside);
      } else {
         document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   });

   const optionListForSelect = useListForSelect();
   const specialitiesOptions = optionListForSelect.specialitiesOptions.specialties;
   const universityOptions = optionListForSelect.universityOptions;

   const universities = universityOptions.map(university => ({label: university.abbreviation, value: university.id}));
   const specialty = specialitiesOptions.map(university => ({label: university.name, value: university.name}));

   return (
      <form className="form form-search flex-element" onSubmit={e => handleSubmit(e)} ref={node}>
         <label className="form__label visually-hidden" htmlFor="post">
            Поиск по {typeFilter.txt}
         </label>
         <Field
            id="post"
            name="post"
            type="text"
            placeholder={`Поиск по ${typeFilter.txt}`}
            component='input'
            className="main-input"
         />
         <button className="filter" onClick={handleOpenFilter} aria-label="Фильтр">
            <span>Фильтр</span>
           <FilterIcon />
         </button>
         <button className="button button-gradient-primary" aria-label="Найти">
            <span>Найти</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                 aria-hidden="true">
               <path
                  d="M6.4989 12.9999C2.90905 12.9999 -0.00109863 10.0897 -0.00109863 6.49988C-0.00109863 2.91003 2.90905 -0.00012207 6.4989 -0.00012207C10.0888 -0.00012207 12.9989 2.91003 12.9989 6.49988C12.9989 7.99334 12.4952 9.36917 11.6485 10.4668L15.72 14.3741C16.0919 14.746 16.0919 15.349 15.72 15.7209C15.348 16.0929 14.745 16.0929 14.3731 15.7209L10.2768 11.7899C9.21212 12.5516 7.90787 12.9999 6.4989 12.9999ZM6.4989 10.9999C8.98418 10.9999 10.9989 8.98516 10.9989 6.49988C10.9989 4.0146 8.98418 1.99988 6.4989 1.99988C4.01362 1.99988 1.9989 4.0146 1.9989 6.49988C1.9989 8.98516 4.01362 10.9999 6.4989 10.9999Z"
                  fill="white"/>
            </svg>
         </button>


         <div className={openFilter === true ? 'filter-content filter-content--open' : 'filter-content'}>
            <div className="filter-content__button">
               <button className={`button-small ${typeFilter.type === 'students' ? 'active' : ''}`}
                       onClick={(e) =>  handleChangeTypeFilter(e, {type: 'students', txt: 'студентам'})}>Студенты</button>
               <button className={`button-small ${typeFilter.type === 'resumes' ? 'active' : ''}`}
                       onClick={(e) =>  handleChangeTypeFilter(e, {type: 'resumes', txt: 'резюме'})}>Резюме</button>
            </div>


            {typeFilter.type === 'resumes' ?
               (
                  <div className="flex-element">
                     <div className="form__item form__item--custom">
                        <label className="form__label">
                           <span>Город проживания</span>
                           <Field
                              name="city"
                              type="text"
                              component={FieldDaData}
                           />
                        </label>
                     </div>
                     <div className="form__item">
                        <label className="form__label" htmlFor="university_id">Университет</label>
                        <Field
                           id='university_id'
                           name="university_id"
                           placeholder="Выберите"
                           options={universities}
                           component={FieldSelect}
                        />
                     </div>
                     <div className="form__item">
                        <label className="form__label" htmlFor="type_employment">Тип занятости</label>
                        <Field
                           id="type_employment"
                           name="type_employment"
                           placeholder="Выберите"
                           options={typeEmployment}
                           component={FieldSelect}
                        />
                     </div>
                     <div className="form__item">
                        <label htmlFor="trip" className="form__label">Готовность к командировкам</label>
                        <Field
                           id="trip"
                           name="trip"
                           placeholder="Выберите"
                           options={trip}
                           component={FieldSelect}
                        />
                     </div>
                     <div className="form__item">
                        <label className="form__label" htmlFor="relocation">Переезд</label>
                        <Field
                           id="relocation"
                           name="relocation"
                           type="text"
                           placeholder="Выберите"
                           options={relocation}
                           component={FieldSelect}
                        />
                     </div>

                     <div className="form__item">
                        <fieldset>
                           <legend className="legend-custom">Зарплатные ожидания, руб</legend>
                           <div className="flex-element form__inline">
                              <div className="form__inline-item">
                                 <label className="visually-hidden" htmlFor="salary_from">От</label>
                                 <Field
                                    id="salary_from"
                                    name="salary_from"
                                    type="number"
                                    placeholder="От"
                                    component={FieldInput}
                                 />
                              </div>
                              <div className="form__inline-item">
                                 <label className="visually-hidden" htmlFor="salary_up">До</label>
                                 <Field
                                    id="salary_up"
                                    name="salary_up"
                                    type="number"
                                    placeholder="До"
                                    component={FieldInput}
                                 />
                              </div>
                           </div>

                        </fieldset>
                     </div>
                  </div>
               )
               : (
                  <div className="flex-element">
                     <div className="form__item form__item--custom">
                        <label className="form__label">
                           <span>Город</span>
                           <Field
                              name="city"
                              type="text"
                              component={FieldDaData}
                           />
                        </label>
                     </div>

                     <div className="form__item">
                        <label className="form__label" htmlFor="university_id">Университет/Вуз</label>
                        <Field
                           id="university_id"
                           name="university_id"
                           type="text"
                           placeholder="Выберите университет/Вуз"
                           options={universities}
                           component={FieldSelect}
                        />
                     </div>

                     <div className="form__item">
                        <label className="form__label" htmlFor="speciality_name">Специальность</label>

                        <Field
                           id='speciality_name'
                           name="speciality_name"
                           options={specialty}
                           component={FieldSelect}
                           placeholder="Введите специальность"
                        />
                     </div>

                     <div className="form__item">
                        <label className="form__label" htmlFor="training_form">Квалификация</label>

                        <Field
                           id='training_form'
                           name="training_form"
                           options={optionsTraining}
                           component={FieldSelect}
                           placeholder="Выберите квалификацию"
                        />
                     </div>

                     <div className="form__item">
                        <label className="form__label" htmlFor="course">Курс</label>

                        <Field
                           id='course'
                           name="course"
                           options={courseList}
                           component={FieldSelect}
                           placeholder="Выберите курс"
                        />
                     </div>
                  </div>
               )
            }
         </div>
      </form>
   )
}


export default reduxForm({
   form: 'filter',
})(FilterMain)

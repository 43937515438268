import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getResumeMedia} from "../../actions/UploadPhoto";
import {getInvitationInfoStudent} from "../../actions/FeedbackAction";
import {Preloader} from "../Preloader/Preloader";
import {ResumeCard} from "./RecumeCard";
import {status} from "../../constants/status";
import {Redirect} from 'react-router-dom';

/* приглашения => резюме */
export const InvitationsCardResume = ({match}) => {
   const inviteID = match.params.invitationId;
   const dispatch = useDispatch();
   const files = useSelector(state => state.media);
   const invite = useSelector(state => state.inviteInformationForCard);
   const user = useSelector(state => state.authentication.user);

   useEffect(() => {
      dispatch(getInvitationInfoStudent(inviteID));
   }, [dispatch, inviteID]);

   return (
      invite.isLoading || invite.inviteInfo === null ? <Preloader/> :
      <>
         {invite.inviteInfo.resume_data.status === status.DISABLED && <Redirect to="/employer/invitation" /> }

         <ResumeCard
            resume={invite.inviteInfo.resume_data}
            files={files}
            inviteInfo={invite.inviteInfo}
            checkAuth={user}
         />
      </>
   )
};

/* отклики => резюме */
export const FeedbackCardResume = ({match}) => {
   const feedbackID = match.params.feedbackID;
   const dispatch = useDispatch();
   const infoFeedback = useSelector(state => state.inviteInformationForCard);
   const files = useSelector(state => state.media);
   const user = useSelector(state => state.authentication.user);

   useEffect(() => {
      dispatch(getInvitationInfoStudent(feedbackID));

      if (infoFeedback.inviteInfo.resume_id) {
         dispatch(getResumeMedia(
            {
               link: "user-portfolio",
               title: "portfolio"
            },
            {resume_id: infoFeedback.inviteInfo.resume_id}
         ));

         dispatch(getResumeMedia(
            {
               link: "user-documents",
               title: "documents"
            },
            {resume_id: infoFeedback.inviteInfo.resume_id}
         ));
      }
   }, [dispatch, feedbackID, infoFeedback.inviteInfo.resume_id]);

   return (
      infoFeedback.isLoading || Object.keys(infoFeedback.inviteInfo).length === 0 ? <Preloader/>
         :
         <>
            {infoFeedback.inviteInfo.resume_data.status === status.DISABLED && <Redirect to={`/employer/vacancy/${match.params.vacancyId}`} />}
            <ResumeCard
               resume={infoFeedback.inviteInfo.resume_data}
               files={files}
               inviteInfo={infoFeedback.inviteInfo}
               checkAuth={user}
            />
         </>
   )
};

import React from 'react';
import {reduxForm, Field} from "redux-form";

const Notifications = ({handleSubmit}) => {
  return (
      <div className="notification-container">
        <form className="form" onSubmit={e => handleSubmit(e)}>
          <fieldset>
            <legend>Уведомления на почту</legend>

            <div className="form__item">
              <label className="switch">
                <Field
                  type="checkbox"
                  component="input"
                  name="invitations_notify"
                />
                <span className="switch__slider"></span>
                <span className="switch__label">Присылать на почту уведомления об откликах и приглашениях</span>
              </label>
            </div>
          </fieldset>

           <button className="button button-green-gradient button-indent button-bold">Сохранить</button>
        </form>
      </div>
  )
};

export default reduxForm({
   form: 'notifications',
   enableReinitialize: true,
})(Notifications)


import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {StudentSettings} from "../components/Settings/StudentJustSetting/StudentSettingContainer";
import {MyResumeListContainer} from '../components/ResumeList/MyResumeList/MyResumeListContainer';
import {ResumeCreate} from '../components/ResumeList/CreateOrEditResume/CreateResume/ResumeCreate';
import {MyResumeCardContainer} from "../components/ResumeCard/MyResumeCardContainer";
import {GetInvitationsContainer} from "../components/Invitations/GetInvitations/GetInvitationsContainer";
import {InvitationsCardStudent} from "../components/Invitations/InvitationsComponentStudent/InvitationsCardStudent";
import {FavoritesStudents} from '../components/Favorites/FavoritesContainer';
import {MySendFeedbackListContainer} from "../components/Feedback/MySendFeedback/MySendFeedbackListContainer";
import {SendFeedbackCard} from "../components/Feedback/SendFeedbackCard/SendFeedbackCard";
import {ResumeEdit} from "../components/ResumeList/CreateOrEditResume/EditResume/ResumeEdit";
import {useSelector} from "react-redux";

export function PersonalAreaStudents({match}) {
  const user = useSelector(state => state.authentication.user);
  return (
      <div className="content">
        <div className="container">

               <Switch>
                  {user.email && user.phone ?
                     <>
                        <Route exact path={`${match.path}/resume`} component={MyResumeListContainer}/>
                        <Route exact path={`${match.path}/resume/:resumeId`} component={MyResumeCardContainer}/>
                        <Route exact path={`${match.path}/create-resume`} component={ResumeCreate}/>
                        <Route exact path={`${match.path}/resume/:resumeId/edit`} component={ResumeEdit}/>
                        <Route exact path={`${match.path}/my-feedback`} component={MySendFeedbackListContainer}/>
                        <Route exact path={`${match.path}/my-feedback/:feedbackId`} component={SendFeedbackCard}/>
                        <Route exact path={`${match.path}/invitations`} component={GetInvitationsContainer}/>
                        <Route exact path={`${match.path}/invitations/:invitationsID`}
                               component={InvitationsCardStudent}/>
                        <Route exact path={`${match.path}/favorites`} component={FavoritesStudents}/>
                        <Route exact path={`${match.path}/settings`} component={StudentSettings}/>
                     </>
                     :
                     <Route exact path={`${match.path}/settings`} component={StudentSettings}/>
                  }
               </Switch>
        </div>
      </div>
  )
}


import { homeConstants } from "../constants/homeConstants";

const stateUniversities = {
  universities: [],
  isLoading: false,
  pagination: {
    pageCount: 0,
    currentCount: 0,
  },
};

export function universities(state = stateUniversities, action) {
  switch (action.type) {
    case homeConstants.IS_LOADING_UNIVERSITIES:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case homeConstants.GET_LIST_UNIVERSITIES_SUCCESS:
      return {
        ...state,
        universities: action.universities,
        isLoading: false,
        pagination: action.pagination,
      };

    case homeConstants.GET_LIST_UNIVERSITIES_FILTER_SUCCESS:
      return {
        ...state,
        universities: action.universities,
      };
    case homeConstants.GET_LIST_UNIVERSITIES_FAILURE:
    case homeConstants.GET_LIST_UNIVERSITIES_FILTER_FAILURE:
      return {
        ...state,
        universities: [],
        isLoading: false,
      };
    default:
      return state;
  }
}

const initialStateUniversityInfo = {
  isLoading: false,
  university: null,
};

export function universityInfo(state = initialStateUniversityInfo, action) {
  switch (action.type) {
    case homeConstants.IS_LOADING_UNIVERSITY_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case homeConstants.GET_UNIVERSITY_INFO:
      return {
        university: action.university,
        isLoading: false,
      };
    default:
      return state;
  }
}

export function cities(state = [], action) {
  switch (action.type) {
    case homeConstants.GET_CITIES_SUCCESS:
      return action.cities;
    case homeConstants.GET_CITIES_FAILURE:
      return [];
    default:
      return state;
  }
}

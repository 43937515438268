import React, {useState} from 'react';
import {Modal} from "@material-ui/core";
import TechnicalSupportForm from "./TechnicalSupportForm";
import {toast} from "react-toastify";
import API from "../../helpers/api";
import {CloseIcon} from "../Icons/Close";
import {getModalStyle} from "../Modals/getModalStyle";

export const TechnicalSupport = () => {
  const [open, setOpen] = useState(false);
  const [lengthText, setLengthText] = useState(0);


  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true)
  };

  const handleSubmit = (values) => {
    values.category = values.category.value;

     API.post(`/feedback`, values)
        .then(res => {
            toast.success('Вопрос был отправлен')
        })
        .catch(error => {
            console.log(error)
        });

    setOpen(false);
  };

  //кол-во символов
  const handleLengthChange = (e) => {
    const count = e.target.value.length;
    setLengthText(count);
  };

  return (
      <>
        <button className="button button-green" onClick={handleOpenModal}>Задать вопрос</button>

        <Modal
            className="modal-support"
            aria-labelledby="modal-title"
            open={open}
            onClose={handleCloseModal}>

            <div style={getModalStyle} className="modal-content modal-content--support">
              <div className="modal-header">
                <h5 id="modal-title" className="dark title-semi-bold mb">Обращение в службу поддержки</h5>
                <p className="mb-none">Вы можете написать нам на почту admin@upliftplatform.ru. Или воспользоваться формой ниже</p>
                <button type="button" className="button button-transparent" onClick={handleCloseModal} aria-label="Закрыть модальное окно">
                  <CloseIcon />
                </button>
              </div>
              <div className="modal-body">
                <TechnicalSupportForm
                   lengthText={lengthText}
                   handleLengthChange={handleLengthChange}
                   onSubmit={(values) => {
                      handleSubmit(values)
                    }} />
              </div>
            </div>
        </Modal>
      </>
  )
};

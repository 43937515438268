import React from 'react';
import { Field, reduxForm } from "redux-form";
import validate from "../../FormElement/validate";
import FieldTextarea from "../../FormElement/Field/FieldTextarea";
import { FieldSelect } from "../../FormElement/Field/FieldSelect";

const VacancyRespondForm = ({ handleSubmit, resume }) => {
   const resumeList = resume.map(item => {
      return {
         label: item.post,
         value: item.id
      }
   });

   return (
      <form className="form" onSubmit={e => handleSubmit(e)}>
         <div className="form__item">
            <label htmlFor="resume_id" className="form__label">Выберите Резюме</label>
            <Field
               id="resume_id"
               name="resume_id"
               type="text"
               placeholder="Выберите резюме"
               options={resumeList}
               component={FieldSelect}/>
         </div>

         <div className="form__item form__item--bg">
            <label htmlFor="message" className="form__label">Ваше сообщение</label>
            <Field
               id="message"
               name="message"
               type="text"
               placeholder="Введите сообщение"
               className="height-textarea"
               component={FieldTextarea}/>
         </div>

         <button className="button button-green-gradient button-upper button-small button-submit">Отправить отклик</button>
      </form>
   )
};

export default reduxForm({
   form: 'respond',
   validate
})(VacancyRespondForm)

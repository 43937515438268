import React from 'react';
import {PersonalDataSettingContainer} from "../PersonalDataSettings/PersonalDataSettingContainer";
import {PersonalPasswordContainer} from "../PersonalPassword/PersonalPasswordContainer";
import {UniversityData} from "./UniversityData";
import {PhotoGalleryUniversity} from "../PhotoGallery/PhotoGalleryUniversity";
import {Helmet} from "react-helmet/es/Helmet";
import {TabsContent} from "../../Tabs/TabsContent";

export const SettingsUniversityContainer = () => {
   const tabs = [
      {
         label: 'Профиль',
         components: <PersonalDataSettingContainer />
      },
      {
         label: 'Пароль',
         components: <PersonalPasswordContainer/>
      },
      {
         label: 'Общая информаци',
         components: <UniversityData/>
      },
      {
         label: 'Фотогалерея',
         components: <PhotoGalleryUniversity/>
      }
   ];

   return (
      <>
         <Helmet>
            <title>Настройки</title>
         </Helmet>
         <div className="bg-card page-setting">
            <TabsContent tabs={tabs} />
         </div>
      </>
   )
};


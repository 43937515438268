import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useRouteMatch} from 'react-router-dom';
import Modal from "@material-ui/core/Modal";
import {addStudentsAssessments, getStudentsById} from '../../../actions/Universtiy/StudentsAction';
import { deleteStudent } from "../../../actions/Universtiy/StudentsAction";
import StudentHead from "./StudentsTableHead";
import StudentsBodyList from "./StudentsBodyList";
import UploadFileAssessments from "../../UploadFile/UploadFileAssessments";
import {Preloader} from "../../Preloader/Preloader";
import {Pagination} from "../../Pagination/Pagination";
import {Plus} from "../../Icons/Plus";
import {CloseIcon} from "../../Icons/Close";
import {getModalStyle} from "../../Modals/getModalStyle";
import {Helmet} from "react-helmet/es/Helmet";

export const StudentsContainer = () => {
  const {url, params} = useRouteMatch();
  const dispatch = useDispatch();
  const students = useSelector(state => state.students);

  const [openModalAssessments, setOpenModalAssessments] = useState(false);
  const handleOpenModalAssessments = () => {setOpenModalAssessments(true)};
  const handleCloseModalAssessments = () => {setOpenModalAssessments(false)};

  useEffect(() => {
    dispatch(getStudentsById(params.groupsId, 1))
  },[dispatch, params.groupsId]);

  const onSetPage = (page) => {
    dispatch(getStudentsById(params.groupsId, page))
  };

  const handleClickDelete = (id) => {
    dispatch(deleteStudent(id));
  };

  const handleFileSubmitAssessments = (values) => {
    const file = values.file[0];
    dispatch(addStudentsAssessments(file, values));
    setOpenModalAssessments(false);
  };

  return (
     <>
        <Helmet>
           <title>Группы</title>
        </Helmet>
        {
           students.isLoading ? <Preloader/> :
              <>
                 <h1 className="title-bold dark display-large">База студентов</h1>

                 <div className="block-inner flex-element">
                    <Link to={`${url}/new-student`} className="button button-green button-shadow button-mr">
                       <Plus />
                       <span className='title'>Добавить студента</span>
                    </Link>

                    <button className="button button-gradient-primary button-width" onClick={handleOpenModalAssessments} >Загрузить оценки из файла</button>
                 </div>

                 <div className="table-responsive">
                    <table className="table table--upper table-pt">
                       <thead>
                       <StudentHead />
                       </thead>
                       <tbody>
                       <StudentsBodyList url={url} data={students.students} handleClickDelete = {handleClickDelete} />
                       </tbody>
                    </table>

                    <Pagination
                       pageCount={students.pagination.pageCount}
                       currentPage={students.pagination.currentPage}
                       onSetPage={onSetPage}
                    />
                 </div>

                 <Modal
                    className="modal-upload"
                    aria-labelledby="modal-title"
                    open={openModalAssessments}
                    onClose={handleCloseModalAssessments} >

                    <div style={getModalStyle} className="modal-content">
                       <div className="modal-header">
                          <button type="button" className="button button-transparent" onClick={handleCloseModalAssessments} aria-label="Закрыть модальное окно">
                             <CloseIcon />
                          </button>
                       </div>

                       <div className="modal-body">
                          <UploadFileAssessments onSubmit={(values) => {
                             handleFileSubmitAssessments(values)
                          }} />
                       </div>
                    </div>
                 </Modal>
              </>
        }
     </>
  )
};

import React, {useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {useDispatch} from 'react-redux';
import {gallery} from "../../../actions/UploadPhoto";
import {PlusElement} from "../../Icons/PlusElement";

export const Dropzone = ({toSend}) => {
  const [myFiles, setMyFiles] = useState([]);
  const dispatch = useDispatch();

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setMyFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

      acceptedFiles.forEach(file => dispatch(gallery(file, toSend)));
    }
  });

  useEffect(() => () => {
    myFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }, [myFiles]);

  return (
      <>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <div className="photo-open">
            <div className="photo-open__inner flex-element flex-element--align-center">
              <PlusElement />
            </div>
          </div>
        </div>
      </>
  );
};

import React from "react";
import { Route } from "react-router-dom";
import { UniversitiesContainer } from "../components/UniversityPage/UniverstitesContainer";
import { UniversityCard } from "../components/UniversityPage/UniverstitesCard";
import { Helmet } from "react-helmet/es/Helmet";

export function UniversitiesPage({ match }) {
   return (
      <div className="inner-page inner-page--pb">
         <Helmet>
            <title>Университеты</title>
         </Helmet>
         <div className="container">
            <Route exact path={match.path} component={UniversitiesContainer} />
            <Route
               path={`${match.url}/:universityId`}
               component={UniversityCard}
            />
         </div>
      </div>
   );
}

import React from "react";
import { Route } from "react-router-dom";
import { HelpCardContainer } from "../components/Help/HelpCardContainer";
import { HelpInfo } from "../components/Help/HelpInfo";
import { Helmet } from "react-helmet/es/Helmet";

export const Help = ({ match }) => {
   return (
      <>
         <Helmet>
            <title>Помощь</title>
         </Helmet>
         <Route exact path={match.path} component={HelpInfo} />
         <Route path={`${match.url}/:helpId`} component={HelpCardContainer} />
      </>
   );
};

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PersonalDataSettingContainer} from "../PersonalDataSettings/PersonalDataSettingContainer";
import {Personal} from "./Personal/Personal";
import Form from './FirstVisitForm';
import {postPersonalData} from '../../../actions/PersonalData';
import {PersonalPasswordContainer} from "../PersonalPassword/PersonalPasswordContainer";
import {Helmet} from "react-helmet/es/Helmet";
import {NotificationsContainer} from "../Notifications/NotificationsContainer";
import {TabsContent} from "../../Tabs/TabsContent";

export const StudentSettings = ({location}) => {
   const locationState = location.state;
   const dispatch = useDispatch();
   const [value, setValue] = useState(0);
   const user = useSelector(state => state.authentication.user);

   const tabs = [
      {
         label: 'Профиль',
         components: <PersonalDataSettingContainer />
      },
      {
         label: 'Пароль',
         components: <PersonalPasswordContainer/>
      },
      {
         label: 'Уведомления',
         components: <NotificationsContainer />
      },
      {
         label: 'Данные',
         components: <Personal/>
      },
   ];

   const handleSubmit = (values) => {
      dispatch(postPersonalData(values))
   };

   useEffect(() => {
      if (locationState) {
         setValue(locationState.value)
      }
   }, [locationState]);

   return (
      <>
         <div className="bg-card page-setting">
            <Helmet>
               <title>Настройки</title>
            </Helmet>

            {user.email ?
               <TabsContent tabs={tabs} changeValue={value}/>
               :
               <Form
                  onSubmit={(values) => {
                     handleSubmit(values)
                  }}
               />
            }
         </div>
      </>
   )
};

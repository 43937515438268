import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {postResume} from '../../../../actions/ResumeAction';
import {mediaResumes} from "../../../../actions/UploadPhoto";
import ResumeForm from "../ResumeForm";
import {Preloader} from "../../../Preloader/Preloader";
import {getFilter} from "../../../../reducers/ClassifiersRedicer";
import {Helmet} from "react-helmet/es/Helmet";
import {mapArrayToString} from "../../../../helpers/conversion";

export const ResumeCreate = () => {
  const dispatch = useDispatch();
  const classifier = useSelector(state => state.filterClassifier.filterCategory);
  const isLoading = useSelector(state => state.media.isLoading);
  const aboutUniversity = useSelector(state => state.authentication.user.student_info.details);

  useEffect(() => {
     dispatch(getFilter('categories', 'category'));
  },[dispatch]);

  const handleSubmit = (values) => {
    let valuesNewObj = Object.assign({}, values);
    
    mapArrayToString(valuesNewObj, ['type_employment', 'category', 'keywords']);
    valuesNewObj.university_id = aboutUniversity.university_id;
    valuesNewObj.work = null;

     if (values.work) {
        valuesNewObj.work = values.work.map(experience => {
           return {
              ...experience,
              start_month: experience.start_month.value,
              end_month: experience.end_month && experience.end_month.value,
              until_now: experience.until_now === true ? '1' : '0'
           }
        })
     }

    let filesDocuments = values.docs || [];
    let filesPortfolio = values.portfolio || [];
    let files = filesDocuments.concat(filesPortfolio);
    let promises = files.map(file => dispatch(mediaResumes(file)));


    Promise.all(promises).then((file) => {
      const regexImg = new RegExp(/\.(jpg|jpeg|png)$/i);
      const regexFiles = new RegExp(/.+(.doc|.docx|.PPTX)$/i);
      valuesNewObj.portfolio = file.filter(file => file.path.match(regexImg));
      valuesNewObj.docs = file.filter(file => file.path.match(regexFiles));

      dispatch(postResume((valuesNewObj)));
    });
  };

  return (
      <div className="bg-card create-block">
         <Helmet>
            <title>Создать Резюме</title>
         </Helmet>

         <h1 className="title-bold dark display-average">Создать Резюме</h1>

        <ResumeForm
            initialValues={{
               university_id: aboutUniversity.university_name
            }}
            data={classifier}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
        />

        {isLoading &&
          <Preloader />
        }
      </div>
  )
};

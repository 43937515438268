import React from 'react';
import {Link} from 'react-router-dom';
import {MapIcon} from "../Icons/Map";
import {EmailIcon} from "../Icons/Email";
import {PhoneIcon} from "../Icons/Phone";
import {BookIcon} from "../Icons/Book";
import {HeaderIcon} from "../Icons/Header";

export const StudentsList = ({studentsList, url, checkAuth}) => (
    studentsList.filter(student => student.visible === 'true').map(student => {
      return <Link to={`${url}/${student.id}`} className="card-preview flex-element" key={student.id}>
        <div className="card-preview__content card-preview__content--width">
          <div className="card-preview__group card-preview__group--custom">
            <div className="card-preview__title title-inter-medium">{`${student.details.username} ${student.details.surname}`}</div>
          </div>
          <div className="card-preview__info">
            <ul className="card-preview__list flex-element">
              <li>
                 <HeaderIcon />
                <span>{student.details.university_abbreviation}, {student.details.speciality_name}, {student.details.faculty_name} <b>{student.course}</b> курс</span></li>
              <li>
                <BookIcon />
                <span>Средний балл: <b>{student.details.average_ball}</b></span>
              </li>
            </ul>
            {checkAuth !== undefined ?
                (checkAuth.type !== 'student' || (checkAuth.type === 'student' && checkAuth.id === student.uid)) &&
                  <ul className="card-preview__list card-preview__list--pt flex-element">
                    <li>
                      <PhoneIcon />
                      <span>Телефон: <b>{student.details.phone}</b></span>
                    </li>
                    <li>
                      <EmailIcon />
                      <span>Email: <b>{student.details.email}</b></span>
                    </li>
                    <li>
                      <MapIcon />
                      <span>Город: <b>{student.city }</b></span>
                    </li>
                  </ul>

                : <p className="mb-none text-red">Персональные данные студента доступны только зарегистрированным пользователям</p>
            }

          </div>
        </div>
      </Link>
    })
);

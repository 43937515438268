import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getFavorites} from "../../actions/FavoritesAction";
import {Favorites} from './Favorites';
import {Preloader} from "../Preloader/Preloader";
import {Helmet} from "react-helmet/es/Helmet";

const useFavoritesContainer = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const userId = user.id;
    const favorites = useSelector(state => state.favorites);

    useEffect(() => {
        dispatch(getFavorites({
           uid: userId
        }));
    }, [dispatch, userId]);

    return favorites;
};

export const FavoritesStudents = () => {
    const favorites = useFavoritesContainer();
    return (
        <>
            <Helmet>
                <title>Избранное</title>
            </Helmet>

            {
                favorites.isLoading ? <Preloader/> : <Favorites favorites={favorites.favorite} text="Вакансии"/>
            }
        </>

    )
};

export const FavoritesEmployer = () => {
    const favorites = useFavoritesContainer();
    return (
        <>
            <Helmet>
                <title>Избранное</title>
            </Helmet>

            {
                favorites.isLoading ? <Preloader/> : <Favorites favorites={favorites.favorite} text="Резюме"/>
            }
        </>
    )
};

import React from "react";
import { Route } from "react-router-dom";
import { CompanyListContainer } from "../components/CompanyPage/CompaniesList/CompanyListContainer";
import { CompanyCardContainer } from "../components/CompanyPage/CompanyCard/CompanyCardContainer";
import { Helmet } from "react-helmet/es/Helmet";

export function CompaniesPage({ match }) {
   return (
      <div className="inner-page inner-page--pb">
         <Helmet>
            <title>Компании</title>
         </Helmet>
         <div className="container">
            <Route exact path={match.path} component={CompanyListContainer} />
            <Route
               path={`${match.url}/:companyId`}
               component={CompanyCardContainer}
            />
         </div>
      </div>
   );
}

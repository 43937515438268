import React from 'react';
import {Avatar} from "../Avatar/Avatar";
import {Keywords} from "../Keywords/Keywords";
import {HeaderIcon} from "../Icons/Header";
import {BookIcon} from "../Icons/Book";

export const CardPreviewResume  = ({data, education}) => (
   <div className="card-preview card-preview--custom flex-element">
      <div className="card-preview__img card-preview__img--custom">
         <Avatar photo={data.user.photo} alt={`${data.user.username} ${data.user.surname}`} />
      </div>
      <div className="card-preview__content">
         <div className="card-preview__group flex-element">
            <div className="card-preview__title card-preview__title--primary title-inter-medium">
               <div>{data.post}</div>
               <span> {`${data.user.username} ${data.user.surname}`}</span>
            </div>
         </div>
         {education &&
            <div className="card-preview__info">
               <ul className="flex-element card-preview__list">
                  <li>
                     <HeaderIcon/>
                     <span>
                     {data.user.student.city}, {data.user.student.details.university_abbreviation}, {data.user.student.details.speciality_name}, <b>{data.user.student.course}</b> курс</span>
                  </li>
                  <li>
                     <BookIcon/>
                     <span>Средний балл: <b>{data.user.student.details.average_ball}</b></span>
                  </li>
               </ul>
            </div>
         }
         <div className="card-preview__foot flex-element flex-element--align-center"><span
            className="salary title-semi-bold">{data.salary}</span>
            <div className="group">
               {<Keywords keywords={data.keywords.split(", ")} classBadge="badge--secondary"/>}
            </div>
         </div>
      </div>
   </div>
);

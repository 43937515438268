import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {DeleteIcon} from "../../Icons/Delete";
import {PlusElement} from "../../Icons/PlusElement";

export function FieldPortfolioDrop(props) {
  const {input: { onChange }} = props;
  const [files, setMyFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    minSize: 0,
    maxSize: 2000000,
    onDrop: acceptedFiles => {
      setMyFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      onChange(acceptedFiles);
    }

  });

  const removeFile = file => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);

    onChange(newFiles)
  };

  const thumbs = files.map(file => (
      <div className="img-wrap relative" key={file.name}>
        <img src={file.preview} alt="" />
        <button aria-label="Удалить" className="button-transparent"  onClick={removeFile(file)}>
          <DeleteIcon />
        </button>
      </div>
  ));

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
     <>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <div className="photo-open">
          <div className="photo-open__inner flex-element flex-element--align-center">
            <PlusElement />
          </div>
        </div>
      </div>

      {thumbs}
      </>

  );
}

import { companyConstants } from "../constants/companyConstants";

const initialStateCompanyList = {
   companies: [],
   isLoading: false,
   pagination: {
      pageCount: 0,
      currentCount: 0,
   },
};

export function companiesList(state = initialStateCompanyList, action) {
   switch (action.type) {
      case companyConstants.IS_LOADING_COMPANIES:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case companyConstants.GET_LIST_COMPANIES_SUCCESS:
         return {
            ...state,
            companies: action.companies,
            isLoading: false,
            pagination: action.pagination,
         };

      case companyConstants.GET_LIST_COMPANIES_SEARCH_SUCCESS:
         return {
            ...state,
            companies: action.companies,
            isLoading: false,
         };

      case companyConstants.GET_LIST_COMPANIES_FAILURE:
      case companyConstants.GET_LIST_COMPANIES_SEARCH_FAILURE:
         return {
            companies: [],
            isLoading: false,
            pagination: {
               pageCount: 0,
               currentCount: 0,
            },
         };
      default:
         return state;
   }
}

const initialStateCompany = {
   company: null,
   isLoading: false,
};

export function company(state = initialStateCompany, action) {
   switch (action.type) {
      case companyConstants.IS_LOADING_INFO_COMPANIES:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case companyConstants.GET_COMPANY:
         return {
            ...state,
            company: action.company,
            isLoading: false,
         };

      default:
         return state;
   }
}

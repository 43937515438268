export const universityConstants = {
  ADD_FACULTY_SUCCESS: 'ADD_FACULTY_SUCCESS',
  DELETE_FACULTY_SUCCESS: 'DELETE_FACULTY',
  GET_FACULTIES_SUCCESS: 'GET_FACULTIES_SUCCESS',
  GET_FACULTIES_FAILURE: 'GET_FACULTIES_FAILURE',
  IS_LOADING_FACULTIES: 'IS_LOADING_FACULTIES',

  ADD_SPECIALTY_SUCCESS: 'ADD_SPECIALTY_SUCCESS',
  DELETE_SPECIALTY_SUCCESS: 'DELETE_SPECIALTY_SUCCESS',
  GET_SPECIALTIES_SUCCESS: 'GET_SPECIALTIES_SUCCESS',
  GET_SPECIALTIES_FAILURE: 'GET_SPECIALTIES_FAILURE',
  IS_LOADING_SPECIALTIES: 'IS_LOADING_SPECIALTIES',

  ADD_GROUP_SUCCESS: 'ADD_GROUP_SUCCESS',
  GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
  GET_GROUPS_FAILURE: 'GET_GROUPS_FAILURE',
  DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
  IS_LOADING_GROUPS: 'IS_LOADING_GROUPS',

  // список временный паролей
  GET_TEMPORAL_SUCCESS: 'GET_TEMPORAL_SUCCESS',
  GET_TEMPORAL_FAILURE: 'GET_TEMPORAL_FAILURE',

  // assessments
  GET_SUBJECTS_SUCCESS: 'GET_SUBJECTS_SUCCESS',
  GET_SUBJECTS_FAILURE: 'GET_SUBJECTS_FAILURE',
  DELETE_SUBJECT: 'DELETE_SUBJECT',
  EDIT_SUBJECTS_SUCCESS: 'EDIT_SUBJECTS_SUCCESS',
  ADD_SUBJECT_SUCCESS: 'ADD_SUBJECT_SUCCESS',
  
  //настройки
  GET_UNIVERSITY_SETTINGS: 'GET_UNIVERSITY_SETTINGS',
  CHANGE_UNIVERSITY_SETTINGS: 'CHANGE_UNIVERSITY_SETTINGS',
};

import { vacancyConstant } from "../constants/vacancyСonstant";

const initialStateVacancies = {
   vacancies: [],
   isLoading: false,
   pagination: {
      pageCount: 0,
      currentCount: 0,
   },
};

export function vacancy(state = initialStateVacancies, action) {
   switch (action.type) {
      case vacancyConstant.ADD_VACANCY:
         return {
            ...state,
            vacancies: [...state.vacancies, action.vacancy],
         };

      case vacancyConstant.IS_LOADING_VACANCIES:
         return {
            ...state,
            isLoading: true,
         };

      case vacancyConstant.GET_VACANCY_SUCCESS:
         return {
            ...state,
            vacancies: action.vacancy,
            isLoading: false,
            pagination: action.pagination,
         };

      case vacancyConstant.GET_LIST_VACANCY_SEARCH_SUCCESS:
         return {
            ...state,
            vacancies: action.vacancy,
            isLoading: false,
         };

      case vacancyConstant.GET_VACANCY_FAILURE:
      case vacancyConstant.GET_LIST_VACANCY_SEARCH_FAILURE:
         return {
            ...state,
            vacancies: [],
            isLoading: false,
            pagination: {},
         };

      case vacancyConstant.DELETE_VACANCY:
         return {
            ...state,
            vacancies: state.vacancies.filter((id) => id.id !== action.vacancy),
         };

      case vacancyConstant.CHANGE_VACANCY:
         return {
            ...state,
            vacancies: state.vacancies.map((item) => {
               if (item.id === action.vacancy.id) {
                  return {
                     ...item,
                     status: action.vacancy.status,
                  };
               }
               return item;
            }),
         };

      default:
         return state;
   }
}

const initialStateVacancyInfo = {
   vacancyInfo: null,
   isLoading: false,
};

export function vacancyInfo(state = initialStateVacancyInfo, action) {
   switch (action.type) {
      case vacancyConstant.IS_LOADING_INFO_VACANCIES:
         return {
            ...state,
            isLoading: action.isLoading,
         };

      case vacancyConstant.GET_INFO_VACANCY_SUCCESS:
         return {
            ...state,
            vacancyInfo: action.vacancyInfo,
            userInfo: action.userInfo,
            isLoading: false,
         };

      case vacancyConstant.GET_INFO_VACANCY_FAILURE:
         return {
            ...state,
            vacancyInfo: null,
            isLoading: false,
         };

      default:
         return state;
   }
}

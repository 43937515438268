import React, {useEffect, useState} from "react";
import RegistrationUniversityForm from './RegistrationUniversityForm';
import {getCities, getUniversitiesByFilterCity} from "../../../actions/HomeAction";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet/es/Helmet";

export const RegistrationUniversity = () => {
  const dispatch = useDispatch();
  const cities = useSelector(state => state.cities);
  const universities = useSelector(state => state.universities.universities);
  const [show, setShow] = useState(false);

  useEffect( () => {
    dispatch(getCities());
  }, [dispatch]);

  const handleChange = (value) => {
    const newValue = value && value.label;
    dispatch(getUniversitiesByFilterCity(newValue))
    setShow(true);
  };

  return (
    <div className="content-home">
      <Helmet>
        <title>Регистрация университета</title>
      </Helmet>
      <div className="content-home__title title-bold">Регистрация университета</div>
      <div className="content-home__form">
        <RegistrationUniversityForm
            cities={cities}
            universities={universities}
            handleChange={handleChange}
            show={show}
        />
      </div>
    </div>
  )
};

import React from "react";
import {NavLink} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

/* акардеон */
const ExpansionPanelSummary = withStyles(theme => ({
   root: {
      color: '#2d3033',
      fontFamily: 'Inter-SemiBold',
      minHeight: 'auto',
      backgroundColor: '#f4f6fa',
      '&$expanded': {
         minHeight: 'auto',
         backgroundColor: 'transparent',
      },
      IconButton: {
         [theme.breakpoints.down('md')]: {
            padding: '10px'
         },
      },
      [theme.breakpoints.down('md')]: {
         fontSize: '15px'
      },
   },
   content: {
      '&$expanded': {
         margin: '12px 0 18px 0',
      },
      [theme.breakpoints.down('md')]: {
         margin: '10px 0'
      },
   },
   expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanel = withStyles({
   root: {
      borderBottom: '1px solid #dadce391',
      boxShadow: 'none',
      '&:before': {
         display: 'none',
      },
      '&$expanded': {
         margin: 'auto',
      },
   },
   expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles(theme => ({
   root: {
      padding: "0 0 10px",
      "div": {
         width: '100%'
      }
   },
}))(MuiExpansionPanelDetails);

const MoreIcon = () => (
   <svg aria-hidden="true"  viewBox="0 0 512.011 512.011" width="17px" height="17px">
      <g>
         <path d="M505.755,123.592c-8.341-8.341-21.824-8.341-30.165,0L256.005,343.176L36.421,123.592c-8.341-8.341-21.824-8.341-30.165,0    s-8.341,21.824,0,30.165l234.667,234.667c4.16,4.16,9.621,6.251,15.083,6.251c5.462,0,10.923-2.091,15.083-6.251l234.667-234.667    C514.096,145.416,514.096,131.933,505.755,123.592z"/>
      </g>
   </svg>
);


export const HelpCard = ({themes, handleGetArticle, match}) => {
   const [expanded, setExpanded] = React.useState('panel1');
   const handleChange = panel => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
   };

   return (
     <aside>
        <div className="bg-card accordion-block">
           {
              themes.map((theme, index) => {
                 return (
                    <ExpansionPanel square expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
                       <ExpansionPanelSummary aria-controls="panel1d-content" id={`panel-header${index}`} expandIcon={<MoreIcon />}>
                          {theme.name}
                       </ExpansionPanelSummary>
                       <ExpansionPanelDetails>
                          <ul className="help-link">
                             {theme.materials.map((material, index) => <li key={index}>
                                   <NavLink to={`${match.url}/${material.link}`} onClick={() => handleGetArticle(material.link)}>{material.title}</NavLink>
                                </li>
                             )}
                          </ul>
                       </ExpansionPanelDetails>
                    </ExpansionPanel>
                 )
              })
           }
        </div>
     </aside>
  )
};

import {companyConstants} from "../constants/companyConstants";
import API from "../helpers/api";
import {getResumeMedia} from "./UploadPhoto";

/* COMPANIES LIST */
export function isLoadingCompanies(isLoading) {
    return {
        type: companyConstants.IS_LOADING_COMPANIES,
        isLoading
    };
}

export function getCompaniesFailure(error) {
    return {
        type: companyConstants.GET_LIST_COMPANIES_FAILURE,
        error
    };
}

export function getCompaniesSuccess(companies, pagination) {
    return {
        type: companyConstants.GET_LIST_COMPANIES_SUCCESS,
        companies,
        pagination
    };
}

export function getCompanies(count) {
    return (dispatch) => {
        dispatch(isLoadingCompanies(true));
        return API.get(`/hirer?page=${count}`)
            .then(companies => {
                dispatch(getCompaniesSuccess(companies.data.items, companies.data._meta))
            })
            .catch(error => {
                console.log(error);
                dispatch(getCompaniesFailure(error))
            })
    }
}


/* INFO FOR CARD */
export function isLoadingCompany(isLoading) {
    return {
        type: companyConstants.IS_LOADING_INFO_COMPANIES,
        isLoading
    };
}

export function getCompanySuccess(company) {
    return {
        type: companyConstants.GET_COMPANY,
        company
    };
}

export function getCompany(id) {
    return async dispatch => {
        dispatch(isLoadingCompany(true));
        try {
            const company = await API.get(`/hirer/${id}`);
            dispatch(getCompanySuccess(company.data));

            const galleryId = company.data.uid;
            dispatch(getResumeMedia(
              {
                 link: "hirer-portfolio",
                 title: "portfolio"
              },
              {uid: galleryId}
           ));
        } catch (error) {
            console.log(error)
        }
    };
}

/* SEARCH */
export function getListCompaniesSearchSuccess(companies) {
    return {
        type: companyConstants.GET_LIST_COMPANIES_SEARCH_SUCCESS,
        companies
    };
}

export function getListCompaniesSearchFailure(error) {
    return {
        type: companyConstants.GET_LIST_COMPANIES_SEARCH_FAILURE,
        error
    };
}

export function getListCompaniesSearch(values) {
    return (dispatch) => {
        return API.get(`/search/hirer`,{params:values})
            .then(answer => {
                dispatch(getListCompaniesSearchSuccess(answer.data.items))
            })
            .catch(error => {
                console.log(error);
                dispatch(getListCompaniesSearchFailure(error))
            })
    }
}

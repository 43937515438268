import React from 'react';
import {ButtonAccept, ButtonDelete, ButtonReject} from "../../ButtonOptions/ButtonOptions";
import {Link} from 'react-router-dom';
import {status} from "../../../constants/status";
import {CardPreviewResume} from "../../Layouts/CardPreviewResume";
import {MessageIcon} from "../../Icons/Message";

export const GetFeedbackList = ({data, handleClickFeedbackAccept, handleClickFeedbackReject, handleClickDeleteFeedbackReject, handleClickDeleteFeedbackAccept, match}) => (
   data.map((feedback, index) => {
      return <tr key={index}>
         <td>
            <div className="group-text">
               {new Date(feedback.created_at * 1000).toLocaleString('ru', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
               })}
               <button aria-label="Сообщение" className="button-transparent display-block">
                  <MessageIcon />
               </button>
            </div>
         </td>

         <td>
            {
               feedback.resume_data.status === status.DISABLED ?
                  <CardPreviewResume data={feedback.resume_data} education={true}/>
                  :
                  <Link to={`${match.url}/feedback/${feedback.id}`}>
                     <CardPreviewResume data={feedback.resume_data} education={true}/>
                  </Link>
            }
         </td>
         <td>
            {
               feedback.resume_data.status === status.DISABLED ?
                  <span className="text-red">Резюме студента заблокировано</span>
                  : (
                     feedback.status === 5 ? <span className="text-red">Отказано</span>
                        : feedback.status === 10 ? <span className="text-green">Принятые</span>
                        : <span className="text-blue">Ожидание ответа</span>
                  )
            }
         </td>
         <td>
            {
               (feedback.resume_data.status !== status.DISABLED) &&
                  <div className="btn-group">
                     {
                        feedback.status === 5 ?
                           <>
                              <ButtonAccept onClick={() => handleClickFeedbackAccept(feedback.id)}/>
                              <ButtonDelete onClick={() => handleClickDeleteFeedbackReject(feedback.id)}/>
                           </>
                           : (feedback.status === 10 ?
                           <>
                              <ButtonReject onClick={() => handleClickFeedbackReject(feedback.id)}/>
                              <ButtonDelete onClick={() => handleClickDeleteFeedbackAccept(feedback.id)}/>
                           </>
                           :
                           <>
                              <ButtonAccept onClick={() => handleClickFeedbackAccept(feedback.id)}/>
                              <ButtonReject onClick={() => handleClickFeedbackReject(feedback.id)}/>
                           </>)
                     }
                  </div>

            }

         </td>
      </tr>
   })
);

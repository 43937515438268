import React, {Component} from 'react';
import Carousel, {Modal, ModalGateway} from 'react-images';

export default class GalleryPhoto extends Component {
   state = {
      selectedIndex: 0,
      lightBoxIsOpen: false,
   };


   toggleLightBox = (selectedIndex) => {
      this.setState(state => ({
         lightBoxIsOpen: !state.lightBoxIsOpen,
         selectedIndex,
      }));
   };

   render() {
      const {lightBoxIsOpen} = this.state;
      const {gallery} = this.props;

      let test;

      if (gallery !== undefined) {
         test = gallery.map(item => {
            return {
               src: item.path
            }
         })
      }

      return (
         <>
            {gallery !== undefined &&
            <Gallery>
               {gallery.map(({id, path}, j) => (
                  <Image onClick={() => this.toggleLightBox(j)} key={id}>
                     <img
                        alt=""
                        src={path}
                     />
                  </Image>
               ))}
            </Gallery>
            }


            <ModalGateway>
               {lightBoxIsOpen ? (
                  <Modal onClose={this.toggleLightBox}>
                     <Carousel
                        views={test}
                        currentIndex={this.state.selectedIndex}
                     />
                  </Modal>
               ) : null}
            </ModalGateway>
         </>
      );
   }
};

const Gallery = (props) => (
   <div className="gallery-wrap"
        {...props}
   />
);


const Image = (props) => (
   <div className="gallery-img relative">
      <div className="gallery-preview relative" {...props} />
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
           className="gallery-icon">
         <path
            d="M13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 15.9869 24.9927 18.7386 23.2992 20.9337L31.4421 28.7483C32.186 29.4922 32.186 30.6982 31.4421 31.4421C30.6983 32.1859 29.4922 32.1859 28.7484 31.4421L20.5559 23.5799C18.4265 25.1034 15.8179 26 13 26ZM13 22C17.9706 22 22 17.9706 22 13C22 8.02944 17.9706 4 13 4C8.02944 4 4 8.02944 4 13C4 17.9706 8.02944 22 13 22Z"
            fill="white"/>
      </svg>

   </div>
);

import React, {useEffect} from 'react';
import UniversityDataForm from "./UniversityDataForm";
import {useDispatch, useSelector} from 'react-redux';
import {getSettingsUniversity} from "../../../actions/UniversitiesAction";
import {photoChange, postSettingsUniversity} from '../../../actions/PersonalData';

export const UniversityData  = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);
  const universityId = user.university_local.id;
  const settingUniversity = useSelector(state => state.settingUniversity);

  useEffect(() => {
    dispatch(getSettingsUniversity(universityId))
  },[dispatch, universityId]);

  const handleSubmit = (values) => {
    if (values.photo && typeof values.photo === 'object') {
      const promise = dispatch(photoChange(values.photo[0]));

      promise.then((newPhoto) => {
        values.photo = newPhoto;
        dispatch(postSettingsUniversity(universityId, values))
      })
    } else {
      dispatch(postSettingsUniversity(universityId, values))
    }
  };

  return (
    <UniversityDataForm
      initialValues={settingUniversity}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    />
  )
};

import {userConstants} from "../constants/userConstants";
import {history} from "../helpers/history";
import API from '../helpers/api';
import {toast} from 'react-toastify'
import {SubmissionError} from 'redux-form';
import store from "../store/configStore";

const requestHandler = (request) => {
   const token = localStorage.getItem('token');
   if (token) {
      request.headers['Authorization'] = `Bearer ${token.replace(/"/g, "")}`;
   }

   return request
};

const redirectToLogin = (error) => {
   if (error.response.status === 401 && !error.response.config.url.includes('auth') ) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('loggedIn');

      store.dispatch({type: userConstants.LOGOUT});
      history.push('/login');
   }

   return Promise.reject({...error});
};


API.interceptors.request.use(request => requestHandler(request));
API.interceptors.response.use(
   response => {
      return response;
   },
   error => redirectToLogin(error)
);


export const userActions = {
   register,
   login,
   logout,
   restorePassword,
   verificationPassword
};

export function successRegister(user) {
   return {
      type: userConstants.REGISTER_REQUEST,
      user
   }
}

export function failureRegister(user) {
   return {
      type: userConstants.REGISTER_FAILURE,
      user
   }
}


function register(values) {
   return (dispatch) => {
      return API.post(`/user`, values)
         .then(user => {
            dispatch(successRegister(user.user));
            toast.success('Регистрация прошла успешно');
            setTimeout(() => history.push('/login'), 1500);
         })
         .catch(error => {
            dispatch(failureRegister(error));
            throw new SubmissionError(error.response.data.message);
         });
   };
}

/* LOGIN */

export function successLogin(user) {
   return {
      type: userConstants.LOGIN_SUCCESS,
      user
   }
}

function login(path, value) {
   return (dispatch) => {
      return API.post(`/${path}`, value)
         .then(res => {
            console.log(res.data.message)

            if (res.data.status === false) {
               throw new Error()
            } else {
               localStorage.setItem('token', JSON.stringify(res.data.token));
               localStorage.setItem('user', JSON.stringify(res.data.model));
               localStorage.setItem('loggedIn', 'true');

               dispatch(successLogin(res.data.model));
               history.push('/')
            }
         })
         .catch(error => {
            if (error.response) {
               if (error.response.data.message) {
                  throw new SubmissionError({
                     _error: error.response.data.message.password
                  });
               } else {
                  throw new SubmissionError(error.response.data);
               }
            } else {
               throw new SubmissionError({_error: 'Учётная запись заблокирована'});
            }
         });
   }
}

function logout() {
   return (dispatch) => {
      try {
         localStorage.removeItem('user');
         localStorage.removeItem('token');
         localStorage.removeItem('loggedIn');

         dispatch({type: userConstants.LOGOUT});
         history.push('/')
      } catch (err) {
         console.log(err)
      }
   }
}

/* restore password */
export function restorePasswordSuccess(status) {
   return {
      type: userConstants.RESTORE_PASSWORD_SUCCESS,
      status
   }
}

export function restorePasswordError(error) {
   return {
      type: userConstants.RESTORE_PASSWORD_ERROR,
      error
   }
}

function restorePassword(email) {
   return (dispatch) => {
      return API.post(`/reset`, email)
         .then(res => {
            dispatch(restorePasswordSuccess(res.data))
         })
         .catch(error => {
            dispatch(restorePasswordError(error));
            throw new SubmissionError(error.response.data);
         })
   }
}

export function saveNewPasswordSuccess(status) {
   return {
      type: userConstants.SAVE_NEW_PASSWORD_SUCCESS,
      status
   }
}


function verificationPassword(value) {
   return (dispatch) => {
      return API.post(`/reset/new`, value)
         .then(res => {
            dispatch(saveNewPasswordSuccess(res.data));
         })
         .catch(error => {
            console.log(error.response);
            throw new SubmissionError(error.response.data);
         })
   }
}

import React from "react";
import {connect} from "react-redux";
import {FormStudent} from "./Form";
import {patchStudent} from "../../../../actions/Universtiy/StudentsAction";
import validate from "../../../FormElement/validate";
import {reduxForm} from "redux-form";

const PersonalDataStudentFormPatch = ({handleSubmit}) => {
  return <FormStudent handleSubmit={handleSubmit} disabled={true} />
};

export default connect(null, {
  onSubmit: values => patchStudent(values.student_id, values)
})(reduxForm({
  form: 'PersonalDataStudentFormPatch',
  enableReinitialize: true,
  validate
})(PersonalDataStudentFormPatch));


import React from "react";
import { useSelector } from "react-redux";
import { status } from "../../constants/status";

export const ToolBarBlocked = () => {
   const user = useSelector((state) => state.authentication.user);
   const loggedIn = localStorage.getItem("loggedIn");

   return (
      loggedIn === "true" &&
      user.type === "hirer" &&
      user.hirer_local.status === status.DISABLED && (
         <div className="sky-toolbar-wrap">
            <div className="sky-toolbar">
               <div className="sky-toolbar__content">
                  Пользователь заблокирован. Обратитесь к администратору
               </div>
            </div>
         </div>
      )
   );
};

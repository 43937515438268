import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch} from 'react-router-dom';
import {getArticle, getThemes} from "../../actions/Article";
import {HelpCard} from "./HelpCard";
import {Preloader} from "../Preloader/Preloader";

export const HelpCardContainer = ({match}) => {
   const helpUrl = match.params.helpId;
   const dispatch = useDispatch();
   const themes = useSelector(state => state.themes);
   const article = useSelector(state => state.article.article);

   useEffect(() => {
      dispatch(getThemes(helpUrl))
   }, [dispatch, helpUrl]);

   const handleGetArticle = (link) => {
      dispatch(getArticle(link))
   };

   return (
      <div className="inner-page inner-page--pb">
         <div className="container">
            {themes.isLoading ? <Preloader/>
               :
               themes.themes.length !== 0 ?
                  <div className="inner-card inner-card--help flex-element flex-element--justify-sb">
                     <HelpCard themes={themes.themes} match={match} handleGetArticle={handleGetArticle} />

                     <div className="inner-card__content">
                        <Switch>
                           <Route path={`${match.path}/:materialLink`}>
                              <Article article={article} match={match}/>
                           </Route>
                        </Switch>
                     </div>
                  </div>
                  :
                  <h1 className="dark title-semi-bold title-bold display-large">Ничего не найдено</h1>
            }
         </div>
      </div>
   )
};


const Article = ({article}) => {
   return (
      <article>
         <div className="card-bg help-page">
            <h1 className="help-page__title title-bold">{article.title}</h1>
            <div dangerouslySetInnerHTML={{__html: article.text}}></div>
         </div>
      </article>
   )
};

import React from 'react';
import {Link} from 'react-router-dom';

export const Pagination = ({pageCount, currentPage, onSetPage}) => {
   if (pageCount <= 1) {
      return null;
   }

   const handleClick = (e, page) => {
      e.preventDefault();
      onSetPage(page)
   };

   const allowPrevious = currentPage !== 1;
   const allowNext = currentPage !== pageCount;
   const previousPage = currentPage - 1;
   const nextPage = currentPage + 1;

   let pages = [];

   let lowerLimit = currentPage;
   let upperLimit = currentPage;
   for (let i = 1; i < 3 && i < pageCount;) {
      if (lowerLimit > 1) {
         lowerLimit--;
         i++;
      }

      if (i < 3 && upperLimit < pageCount) {
         upperLimit++;
         i++;
      }
   }

   if (lowerLimit > 1) {
      pages.push(1);
      pages.push('...');
   }

   for (let i = lowerLimit; i <= upperLimit; i++) {
      pages.push(i);
   }

   if (currentPage < pageCount - 2) {
      pages.push('...');
      pages.push(pageCount);
   }

   return (
      <nav aria-label="Пагинация">
         <ul className="pagination">
            {allowPrevious &&
               <li className="pagination__item pagination__item--option pagination__item--prev">
                  <Link to="#" className="pagination__link"
                        onClick={(e) => handleClick(e, previousPage)}>Предыдущий</Link>
               </li>
            }

            {pages.map((page, index) => {
               if (page === '...') {
                  return (
                     <li key={index} className='pagination__item'>
                        <span>...</span>
                     </li>
                  )
               } else {
                  return (
                     <li key={index}
                         className={currentPage === page ? 'pagination__item pagination__item--active' : 'pagination__item'}>
                        <Link to="#" className="pagination__link" onClick={(e) => handleClick(e, page)}>{page}</Link>
                     </li>
                  )
               }
            })}

            {allowNext &&
               <li className="pagination__item pagination__item--option pagination__item--next">
                  <Link to="#" className="pagination__link"
                        onClick={(e) => handleClick(e, nextPage)}>
                     <span>Следующий</span>
                     <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M1.17 1.0003C0.983753 1.18766 0.879211 1.44111 0.879211 1.7053C0.879211 1.96948 0.983753 2.22293 1.17 2.4103L4.71 6.0003L1.17 9.5403C0.983753 9.72766 0.879211 9.98111 0.879211 10.2453C0.879211 10.5095 0.983753 10.7629 1.17 10.9503C1.26297 11.044 1.37357 11.1184 1.49543 11.1692C1.61729 11.22 1.74799 11.2461 1.88 11.2461C2.01202 11.2461 2.14272 11.22 2.26458 11.1692C2.38644 11.1184 2.49704 11.044 2.59 10.9503L6.83 6.7103C6.92373 6.61733 6.99813 6.50673 7.04889 6.38487C7.09966 6.26301 7.1258 6.13231 7.1258 6.0003C7.1258 5.86828 7.09966 5.73758 7.04889 5.61572C6.99813 5.49386 6.92373 5.38326 6.83 5.2903L2.59 1.0003C2.49704 0.906568 2.38644 0.832173 2.26458 0.781404C2.14272 0.730636 2.01202 0.704496 1.88 0.704496C1.74799 0.704496 1.61729 0.730636 1.49543 0.781404C1.37357 0.832173 1.26297 0.906568 1.17 1.0003Z"
                           fill="#90A0B7"/>
                     </svg>
                  </Link>
               </li>
            }
         </ul>
      </nav>
   )
};

import InputMask from "react-input-mask";
import React from "react";

export const FieldMask = ({ placeholder, mask, input, id, meta: { touched, error } }) => {
  return (
      <>
        <InputMask placeholder={placeholder}
                   {...input} id={id} mask={mask} />
        { touched && error && <span className='hasError'>{error}</span> }
      </>
  )
};

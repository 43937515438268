import React from 'react';
import { Link } from "react-router-dom";
import {ButtonDelete} from "../../ButtonOptions/ButtonOptions";

const StudentsBodyList = ({ data, handleClickDelete, url }) => (
     data.map((student) => {
       return <tr key={student.id}>
         <td><Link to={`${url}/student${student.id}`}> {`${student.details.surname} ${student.details.username} ${student.details.patronymic} `}</Link></td>
         <td>{student.details.phone}</td>
         <td>{student.student_ticket}</td>
         <td>
           <ButtonDelete onClick={() => handleClickDelete(student.id)} />
         </td>
       </tr>
     })
);

export default StudentsBodyList;

import React, {useState, useEffect} from 'react';
import {Field, reduxForm} from "redux-form";
import {FieldSelect} from "../../FormElement/Field/FieldSelect";
import validate from "../../FormElement/validate";
import {FieldInput} from "../../FormElement/Field/FieldInput";

const type = [
   {label: 'Экзамен', value: 'exam'},
   {label: 'Зачет', value: ''}
];

const FormCourse = (props) => {
   const [valueSelect, setValueSelect] = useState(false);

   const handleChangeSelect = selectedOption => {
      selectedOption === null || selectedOption.value !== 'exam' ? setValueSelect(false) : setValueSelect(true);
   };
   
   useEffect(() => {
      if (props.initialValues.type) {
         props.initialValues.type.value === 'exam' && setValueSelect(true)
      }
   }, [props.initialValues.type]);

   return (
       <form className="form" onSubmit={props.handleSubmit}>
          <div className="form__line flex-element">
             <Field
                 name="type"
                 component="input"
                 type="hidden"
             />

             <Field
                 name="student_id"
                 component="input"
                 type="hidden"
             />

             <div className="form__item form__item--max">
                <label htmlFor="name" className="visually-hidden">Имя</label>
                <Field
                    id="name"
                    name="name"
                    component={FieldInput}
                    type="text"
                    placeholder="Название"
                />
             </div>


             <div className="form__item form__item--middle">
                <label className="visually-hidden" htmlFor="type">Тип</label>
                <Field
                    id="type"
                    name="type"
                    component={FieldSelect}
                    onChange={handleChangeSelect}
                    options={type}
                    placeholder="Выберите"
                    type="text"
                />
             </div>

             {valueSelect  &&
                <div className="form__item form__item--min">
                   <label className="visually-hidden" htmlFor="mark">Оценка</label>
                   <Field
                       id="mark"
                       name="mark"
                       component={FieldInput}
                       type="number"
                       placeholder="Оценка"
                   />
                </div>
             }

             <button type="submit" className="button button-small button-green-gradient">Сохранить</button>
          </div>
       </form>
   )
};


export default reduxForm({
   form: 'assessments',
   validate,
   enableReinitialize: true,
})(FormCourse);


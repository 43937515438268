import { classifiersConstants } from "../constants/classifiersConstants";
import axios from "axios";
import { API } from "../helpers/api";

const initialStateFilter = {
   filterOrganization: [],
   filterCategory: [],
};

export function filterClassifier(state = initialStateFilter, action) {
   switch (action.type) {
      case classifiersConstants.SET_FILTER:
         if (action.typeFilter === "category") {
            return {
               ...state,
               filterCategory: action.date,
            };
         } else if (action.typeFilter === "organization") {
            return {
               ...state,
               filterOrganization: action.date,
            };
         }

      case classifiersConstants.SET_CHANGE_FILTER:
         if (action.typeFilter === "category") {
            return {
               ...state,
               filterCategory: awesomeFilter(
                  action.category,
                  action.data.map((i) => i.value)
               ),
            };
         } else if (action.typeFilter === "organization") {
            return {
               ...initialStateFilter,
               filterOrganization: awesomeFilter(
                  action.category,
                  action.data.map((i) => i.value)
               ),
            };
         }

      default:
         return state;
   }
}

function awesomeFilter(category, checkedValues) {
   function checkItem(item) {
      if (checkedValues.includes(item.value)) {
         return { ...item, checked: true };
      } else {
         return item.children
            ? { ...item, children: item.children.map(checkItem) }
            : { ...item };
      }
   }

   return category.map(checkItem);
}

/* Получение списка */
export function getFilterCategoryClassifier(date, typeFilter) {
   return {
      type: classifiersConstants.SET_FILTER,
      date,
      typeFilter,
   };
}

export function getFilter(path, typeFilter, options) {
   return (dispatch) => {
      return axios
         .get(`${API}/${path}`)
         .then((data) => {
            if (options) {
               dispatch(filterChange(data.data.items, options, typeFilter));
            } else {
               dispatch(
                  getFilterCategoryClassifier(data.data.items, typeFilter)
               );
            }
         })
         .catch((error) => {
            console.log(error);
         });
   };
}

export function filterChange(category, data, typeFilter) {
   return {
      type: classifiersConstants.SET_CHANGE_FILTER,
      category,
      data,
      typeFilter,
   };
}

import React from "react";
import {Field, reduxForm} from "redux-form";
import {FieldSelect} from "../../../FormElement/Field/FieldSelect";
import {FieldDaData} from "../../../FormElement/Field/FieldDaData";
import {FieldMask} from "../../../FormElement/Field/FieldMask";
import {Link} from "react-router-dom";

const type = [
  { value: 'male', label: 'Мужской' },
  { value: 'female', label: 'Женский' },
];

const FormPersonal = ({handleSubmit}) => (
    <form className="form flex-element" onSubmit={e => handleSubmit(e)}>
      <fieldset>
        <legend>Общая информация</legend>

        <div className="form__item">
          <label htmlFor="gender" className="form__label">Пол</label>
          <Field
              component={FieldSelect}
              id="gender"
              name="gender"
              type="text"
              options={type}
              placeholder="Выберите"
          />
        </div>

        <div className="form__item">
          <label htmlFor="birthday" className="form__label">День рождения</label>

           <Field
               id='birthday'
               name="birthday"
               component={FieldMask}
               placeholder="__-__-____"
               mask="99-99-9999"
           />
        </div>

        <div className="form__item form__item--custom">
          <label htmlFor="city" className="form__label">
             <span>Город проживания</span>

             <Field
                placeholder="Выберите"
                name="city"
                component={FieldDaData}
             />
          </label>
        </div>

        <div className="form__item">
          <Field
              name="visible"
              id="visible"
              component="input"
              type="checkbox"
              value=""
          />
          <label className="label-custom mb-none" htmlFor="visible">
             <span>Я даю согласие на обработку и распространиение персональных данных в соответствии с <Link to={{pathname: `../file/Пользовательское соглашение.docx`}} target="e_blank">«Политикой конфиденциальности»</Link> и <Link to={{pathname: `../file/Политика_конфиденциальности_25.01.2020.docx`}} target="e_blank">«Пользовательским соглашением»</Link></span>
          </label>
        </div>

        <button className="button button-green-gradient button-indent button-bold">Сохранить</button>
      </fieldset>
    </form>
);

export default reduxForm({
  form: 'settings-student',
  // validate,
  enableReinitialize: true
})(FormPersonal)

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import queryString from 'query-string';
import {getUniversities} from "../../../actions/HomeAction";
import {getListResume, getListResumeSearch} from "../../../actions/ResumeAction";
import {MainResumeList} from './MainResumeList'
import FilterResume from "../../Filter/FilterResume";
import {flattenObjHelper} from "../../Filter/flatteronObjHelper";
import {Preloader} from "../../Preloader/Preloader";
import {Pagination} from "../../Pagination/Pagination";
import {getFilter} from "../../../reducers/ClassifiersRedicer";
import {FilterIcon} from "../../Icons/Filter";

export const MainResumeListContainer = ({match, location}) => {
   const dispatch = useDispatch();
   const resumes = useSelector(state => state.resume);
   const classifier = useSelector(state => state.filterClassifier.filterCategory);

   const universities = useSelector(state => state.universities.universities);
   const [filterOpen, setFilterOpen] = useState(false);
   const parseLocation = queryString.parse(location.search);

   useEffect(() => {
      dispatch(getUniversities());
      dispatch(getFilter('categories', 'category'));

      if (location.search) {
         dispatch(getListResumeSearch(queryString.parse(location.search)));
      } else {
         dispatch(getListResume(1));
      }
   }, [dispatch, location.search]);

   const handleSubmit = (values) => {
      const objCopy = flattenObjHelper(values);
      if (values.category) {
         objCopy.category = values.category.map(item => item.value).join(',');
      }

      dispatch(getListResumeSearch(objCopy));
      setFilterOpen(false);
   };

   const handleOpenFilter = (e) => {
      e.preventDefault();
      filterOpen ? setFilterOpen(false) : setFilterOpen(true);
   };

   const onSetPage = (page) => {
      dispatch(getListResume(page))
   };

   let initialValues = {
      ...parseLocation,
      university_id: universities.filter(el => el.id === Number(parseLocation.university_id)).map(universities => {
         return ({value: universities.id, label: universities.abbreviation})
      }),
      trip: parseLocation.trip && parseLocation.trip.split(", ").map(value => {
         return ({value: value, label: value})
      }),
      relocation: parseLocation.relocation && parseLocation.relocation.split(", ").map(value => {
         return ({value: value, label: value})
      }),
      type_employment: parseLocation.type_employment && parseLocation.type_employment.split(", ").map(value => {
         return ({value: value, label: value})
      }),
   };

   return (
      resumes.isLoading ? <Preloader/>
         :
         <>
            <h1 className="dark title-semi-bold title-bold display-large">Резюме</h1>
            <div className="inner-block inner-block--primary flex-element flex-element--justify-sb">
               <aside className="aside-outside aside-outside--secondary relative">
                  <button aria-label="Открыть фильтр" className="filter-button" onClick={handleOpenFilter}>
                     <FilterIcon />
                  </button>
                  <div className={`bg-card filter-block ${filterOpen ? 'active' : ''}`}>
                     <FilterResume
                        initialValues={initialValues}
                        data={classifier}
                        universities={universities}
                        onSubmit={(values) => {
                           handleSubmit(values)
                        }}
                     />
                  </div>
               </aside>
               <div className="inner-block__content">
                  {resumes.resume.length === 0 ?
                     <p>Ничего не найдено</p> :
                     <MainResumeList resumeList={resumes.resume} match={match}/>
                  }

                  <Pagination
                     pageCount={resumes.pagination.pageCount}
                     currentPage={resumes.pagination.currentPage}
                     onSetPage={onSetPage}
                  />
               </div>
            </div>
         </>

   )
};

import React from "react";
import { Link } from "react-router-dom";
import { Team } from "../components/Team/Team";
import styles from "../assets/style/modules/about.module.scss";
import bg from "../assets/img/bg-about.png";
import box1 from "../assets/img/box/icon.png";
import box2 from "../assets/img/box/icon2.png";
import box3 from "../assets/img/box/icon3.png";
import team from "../assets/img/team/СергейПопов.jpg";
import team2 from "../assets/img/team/ВикторСемидоцкий.jpg";
import team3 from "../assets/img/team/ЕленаСемидоцкая.jpg";
import bc from "../assets/img/team/bc.png";
import { Helmet } from "react-helmet/es/Helmet";

export function About() {
   return (
      <>
         <Helmet>
            <title>О проекте</title>
         </Helmet>
         <section>
            <div className="container">
               <div className="content-wrap flex-element flex-element--options">
                  <div className="content-text">
                     <h1 className="title-bold gray-dark title-mb text-upper display-average">
                        О Проекте
                     </h1>
                     <p>
                        <b>
                           Мы содействуем развитию человеческого капитала
                           современной России.
                        </b>
                     </p>
                     <p>
                        Данное развитие нам видится в оптимизации
                        образовательных программ, более тесной интеграции
                        бизнеса и учебных заведений, облегчении понимания
                        учебными заведениями кадровых запросов предприятий
                        реального сектора экономики.
                     </p>
                     <p>
                        Именно поэтому мы создали и развиваем глобальную
                        электронную платформу, обеспечивающую удовлетворение
                        потребностей студентов, вузов и бизнеса.{" "}
                     </p>
                  </div>
                  <img src={bg} alt="" aria-hidden="true" />
               </div>
            </div>
         </section>

         <section className="section-white">
            <div className="container">
               <div className="our-mission flex-element">
                  <div className="our-mission__content">
                     <h2 className="our-mission__title title-bold">
                        «UpLift» является официальным порталом более чем 300+
                        университетов для поиска студентов по всей стране.
                     </h2>

                     <div className="card-wrapper flex-element flex-element--options">
                        <div className={styles.card}>
                           <img src={box1} alt="" />
                           <h3 className="title-inner-bold">Бизнесу</h3>
                           <p>
                              Бизнес получает доступ к самому креативному
                              сектору рынка трудовых ресурсов с подтвержденными
                              компетенциями.
                           </p>
                        </div>
                        <div className={styles.card}>
                           <img src={box2} alt="" />
                           <h3 className="title-inner-bold">Университетам</h3>
                           <p>
                              Университеты имеют возможность корректировать
                              образовательные программы под потребности
                              бизнес-сообщества, участвовать в рейтинге вузов по
                              критерию успешности и полноты трудоустройства
                              выпускников.
                           </p>
                        </div>
                        <div className={styles.card}>
                           <img src={box3} alt="" />
                           <h3 className="title-inner-bold">
                              Студентам и Абитуриентам
                           </h3>
                           <p>
                              Студенты могут найти работу и места развития
                              практических навыков.
                           </p>
                           <p>
                              Абитуриенты, ориентируясь на рейтинги и параметры
                              спроса, могут выбрать будущую профессию,
                              востребованную народным хозяйством.{" "}
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="section-white">
            <div className="container">
               <div className="our-teams">
                  <h2 className="title-bold title-mb dark">Наша Команда</h2>

                  <div className="our-teams__group our-teams__group--border">
                     <div className="card-employee-wrapper flex-element">
                        <Team
                           img={team}
                           name="Сергей Попов"
                           list={[
                              "Сооснователь проекта",
                              "Стратегия и оперативное управление",
                              "Техническое развитие",
                              "Инновационное проектирование",
                              "Генеральный директор ООО «Инновационные платформы и решения»",
                           ]}
                        />
                        <Team
                           img={team2}
                           name="Виктор Семидоцкий"
                           list={[
                              "Сооснователь проекта",
                              "Стратегия и капитализация инноваций",
                              "Развитие взаимоотношений с бизнесом и образовательными учреждениями",
                              "Доктор экономических наук, профессор, заведующий кафедрой «Экономики и финансов» КубГТУ",
                           ]}
                        />
                        <Team
                           img={team3}
                           name="Елена Семидоцкая"
                           list={[
                              "Маркетинг и продвижение",
                              "Организационное сопровождение",
                              "Заместитель директора ООО «Инновационные платформы и решения»",
                           ]}
                        />
                     </div>
                  </div>

                  <div className="our-teams__group">
                     <div className="our-teams__title">Бизнес-ангелы</div>

                     <div className="card-employee-wrapper flex-element">
                        <Link
                           target="_blank"
                           to={{ pathname: `http://vashsovetnik.ru` }}
                           className="card-employee"
                        >
                           <div className="card-employee__img">
                              <img src={bc} alt="Ваш СоветникЪ" />
                           </div>
                        </Link>
                     </div>
                  </div>

                  <div className="our-teams__group our-teams__group--border">
                     <div className="card-employee-wrapper flex-element">
                        <div className="card-employee">
                           <div className="card-employee__content">
                              <div className="card-employee__position">
                                 Для четкого соответствия законодательным нормам
                                 РФ мы организовали и развиваем ООО
                                 «Инновационные платформы и решения», которое
                                 является обладателем прав на код платформы,
                                 логотип, название платформы UpLift.
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
}

import React from 'react';
import {Link} from "react-router-dom";

export const UniversitiesList = ({data, url}) => (
   data.map((university) => {
      return <tr key={university.id}>
         <td className="title-inter-medium">
            <Link to={`${url}/${university.id}`}>{university.full_name}</Link></td>
         <td className="title-inter-medium text-green">{university.students_count}</td>
      </tr>
   })
);

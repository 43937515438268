import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
   deleteVacancy,
   patchVacancy,
   getVacancyByParams,
} from "../../../actions/VacancyAction";
import Thead from "./TableThead";
import { MyVacancyList } from "./MyVacancyList";
import { Preloader } from "../../Preloader/Preloader";
import { Helmet } from "react-helmet/es/Helmet";
import { status } from "../../../constants/status";
import { TabsContent } from "../../Tabs/TabsContent";

export const MyVacancyListContainer = ({ match }) => {
   const url = match.url;
   const dispatch = useDispatch();
   const user = useSelector((state) => state.authentication.user);
   const vacanciesList = useSelector((state) => state.vacancy);

   useEffect(() => {
      const userId = user.hirer_local.id;
      dispatch(
         getVacancyByParams({
            hirer_id: userId,
         })
      );
   }, [dispatch, user.hirer_local.id]);

   const handleClickDelete = (id) => {
      dispatch(deleteVacancy(id));
   };

   const handleClickArchive = (id) => {
      const value = {
         status: status.ARCHIVE,
         title: "Вакансия добавлена в архив",
      };
      dispatch(patchVacancy(id, value));
   };

   const handleClickAccept = (id) => {
      const value = { status: 10, title: "Вакансия добавлена в активные" };
      dispatch(patchVacancy(id, value));
   };

   const vacancyList = vacanciesList.vacancies.filter(resStatus => resStatus.status === status.PUBLISHED);
   const vacancyDisabled = vacanciesList.vacancies.filter(resStatus => resStatus.status === status.DISABLED);
   const vacancyListArchive = vacanciesList.vacancies.filter(resStatus => resStatus.status === status.ARCHIVE);

   const tabs = [
      {
         label: "Активные",
         components: (
            <VacancyContent
               list={vacancyList}
               text="У вас нет активных вакансий"
               url={url}
               handleClickDelete={handleClickDelete}
               handleClickArchive={handleClickArchive}
               handleClickAccept={handleClickAccept}
            />
         ),
      },
      {
         label: "В архиве",
         components: (
            <VacancyContent
               list={vacancyListArchive}
               text="Архив с вакансиями пуст"
               url={url}
               handleClickDelete={handleClickDelete}
               handleClickArchive={handleClickArchive}
               handleClickAccept={handleClickAccept}
            />
         ),
      },
      {
         label: "Заблокированные",
         components: (
            <VacancyContent
               list={vacancyDisabled}
               text="У вас нет заблокированных ваканский"
               url={url}
               handleClickDelete={handleClickDelete}
               handleClickArchive={handleClickArchive}
               handleClickAccept={handleClickAccept}
            />
         ),
      },
   ];

   return (
      <>
         <Helmet>
            <title>Мои вакансии</title>
         </Helmet>

         {vacanciesList.isLoading ? (
            <Preloader />
         ) : (
            <>
               {user.hirer_local.status !== status.DISABLED && (
                  <Link
                     className="button button-green-gradient button-bold button-create"
                     to={`create-vacancy`}
                  >
                     Создать вакансию
                  </Link>
               )}

               <div className="bg-card bg-card--mt bg-card--inner">
                  <TabsContent tabs={tabs} />
               </div>
            </>
         )}
      </>
   );
};

const VacancyContent = ({
   list,
   text,
   url,
   handleClickAccept,
   handleClickArchive,
   handleClickDelete,
}) =>
   list.length === 0 ? (
      <p className="mb-none txt-info">{text}</p>
   ) : (
      <div className="table-responsive">
         <table className="table table--shadow-none table--upper table-small-size table--head-transparent">
            <Thead />
            <tbody>
               <MyVacancyList
                  data={list}
                  url={url}
                  handleClickAccept={handleClickAccept}
                  handleClickArchive={handleClickArchive}
                  handleClickDelete={handleClickDelete}
               />
            </tbody>
         </table>
      </div>
   );

export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',
  RESTORE_PASSWORD_ERROR: "RESTORE_PASSWORD_ERROR",
  RESTORE_PASSWORD_SUCCESS: "RESTORE_PASSWORD_SUCCESS",
  SAVE_NEW_PASSWORD_SUCCESS: 'SAVE_NEW_PASSWORD_SUCCESS'
};

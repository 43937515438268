export const companyType = [
    { value: 'ООО', label: 'ООО' },
    { value: 'ИП', label: 'ИП' },
];

export const typeEmployment = [
    {value: 'Полная занятость', label: 'Полная занятость'},
    {value: 'Частичная занятость', label: 'Частичная занятость'},
    {value: 'Проектная/Временная работа', label: 'Проектная/Временная работа'},
    {value: 'Волонтерство', label: 'Волонтерство'},
    {value: 'Стажировка', label: 'Стажировка'},
];

export const workTime = [
    { value: 'Полный день', label: 'Полный день'},
    { value: 'Сменный график', label: 'Сменный график'},
    { value: 'Гибкий график', label: 'Гибкий график'},
    { value: 'Удаленная работа', label: 'Удаленная работа'},
    { value: 'Вахтовый метод', label: 'Вахтовый метод'},
];

export const category = [
    { value: 'Web мастер', label: 'Web мастер'},
    { value: 'Маркетинг', label: 'Маркетинг'},
    { value: 'Компьютерная безопасность', label: 'Компьютерная безопасность'},
    { value: 'Администратор баз данных', label: 'Администратор баз данных'}
];

export const trip = [
  { value: 'Не готов', label: 'Не готов'},
  { value: 'Готов', label: 'Готов'},
  { value: 'Иногда', label: 'Иногда'}
];

export const relocation = [
  { value: 'Невозможен', label: 'Невозможен'},
  { value: 'Возможен', label: 'Возможен'},
];

export const workExperience = [
  { value: 'Нет опыта', label: 'Нет опыта'},
  { value: 'От 1 до 3 лет', label: 'От 1 до 3 лет'},
  { value: 'От 3 до 6 лет', label: 'От 3 до 6 лет'},
  { value: 'Более 6', label: 'Более 6'},
];

export const optionsTraining = [
  { value: 'Бакалавр', label: 'Бакалавр' },
  { value: 'Магистр', label: 'Магистр' },
  { value: 'Аспирантура', label: 'Аспирантура' },
];

export const optionsStudy = [
  { value: 'Очная', label: 'Очная' },
  { value: 'Заочная', label: 'Заочная' },
];

export const courseList = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
];

export const MonthNames = [
   {value: 'Январь', label: 'Январь'},
   {value: 'Февраль', label: 'Февраль'},
   {value: 'Март', label: 'Март'},
   {value: 'Апрель', label: 'Апрель'},
   {value: 'Май', label: 'Май'},
   {value: 'Июнь', label: 'Июнь'},
   {value: 'Июль', label: 'Июль'},
   {value: 'Август', label: 'Август'},
   {value: 'Сентябрь', label: 'Сентябрь'},
   {value: 'Октябрь', label: 'Октябрь'},
   {value: 'Ноябрь', label: 'Ноябрь'},
   {value: 'Декабрь', label: 'Декабрь'},
];

import React from 'react';
import {Field, reduxForm} from "redux-form";
import {FieldMask} from "../../FormElement/Field/FieldMask";
import {FieldInput} from '../../FormElement/Field/FieldInput'
import {FieldImage} from "../../FormElement/Field/FieldImage";
import validate from "../../FormElement/validate";

const PersonalDataSettingForm = ({handleSubmit}) => (
    <form className="form flex-element" onSubmit={e => handleSubmit(e)}>

      <label htmlFor="photo-profile" className="visually-hidden">Аватар</label>
      <Field
          id="photo-profile"
          name="photo"
          type="file"
          component={FieldImage}
      />

      <div className="form__group">

         <fieldset>
           <legend>Общая информация</legend>

           <div className="form__item">
             <label className="form__label" htmlFor="surname">Фамилия</label>
             <Field
                 component={FieldInput}
                 id="surname"
                 name="surname"
                 type="text"
                 placeholder="Фамилия"
             />
           </div>

           <div className="form__item">
             <label className="form__label" htmlFor="username">Имя</label>

             <Field
                 component={FieldInput}
                 id="username"
                 name="username"
                 type="text"
                 placeholder="Имя"
             />
           </div>

           <div className="form__item">
             <label className="form__label" htmlFor="phone">Телефон</label>
             <Field
                 component={FieldMask}
                 name="phone"
                 id="phone"
                 placeholder="+7 (___) ___-__-__"
                 mask="+7 (999) 999-99-99"
             />
           </div>

           <div className="form__item">
             <label className="form__label" htmlFor="email">Email</label>
             <Field
                 component={FieldInput}
                 name="email"
                 id="email"
                 placeholder="email"
             />
           </div>

           <button className="button button-save button-green-gradient button-indent button-bold">Сохранить</button>
         </fieldset>
      </div>
    </form>
);

export default reduxForm({
  form: 'personalDataSettings',
  enableReinitialize: true,
  validate
})(PersonalDataSettingForm)

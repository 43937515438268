import React from "react";
import { Link } from "react-router-dom";
import {
   ButtonDelete,
   ButtonAccept,
   ButtonReject,
} from "../../ButtonOptions/ButtonOptions";
import { status } from "../../../constants/status";

export const InvitationsList = ({
   data,
   handleClickRejectInvitations,
   url,
   handleClickAcceptInvitations,
   handleClickDeleteInvitationsReject,
   handleClickDeleteInvitationsAccept,
}) => {
   return data.map((invite) => {
      return (
         <tr key={invite.id}>
            <td>
               {new Date(invite.created_at * 1000).toLocaleString("ru", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
               })}
            </td>
            <td>{invite.resume_data.post}</td>
            <td>
               {invite.vacancy_data.status === status.DISABLED ||
               invite.resume_data.status === status.DISABLED ? (
                  <div>{invite.vacancy_data.name}</div>
               ) : (
                  <Link to={`${url}/${invite.id}`}>
                     {invite.vacancy_data.name}
                  </Link>
               )}
            </td>
            <td>
               {invite.vacancy_data.status === status.DISABLED ? (
                  <span className="text-red">Вакансия заблакирована</span>
               ) : invite.resume_data.status === status.DISABLED ? (
                  <span className="text-red">Ваше резюме - заблокировано</span>
               ) : invite.vacancy_data.status === 50 ? (
                  <span className="text-light">Вакансия в архиве</span>
               ) : invite.status === 5 ? (
                  <span className="text-red">Отказано</span>
               ) : invite.status === 10 ? (
                  <span className="text-green">Принятые</span>
               ) : (
                  <span className="text-blue">Приглашен на собеседование</span>
               )}
            </td>
            <td>
               <div className="btn-group">
                  {(invite.vacancy_data.status !== status.DISABLED && invite.resume_data.status !== status.DISABLED) &&
                        (invite.status === 5 ? (
                           <>
                              <ButtonAccept
                                 onClick={() =>
                                    handleClickAcceptInvitations(invite.id)
                                 }
                              />
                              <ButtonDelete
                                 onClick={() =>
                                    handleClickDeleteInvitationsReject(
                                       invite.id
                                    )
                                 }
                              />
                           </>
                        ) : invite.status === 10 &&
                          invite.vacancy_data.status !== 50 ? (
                           <>
                              <ButtonReject
                                 onClick={() =>
                                    handleClickRejectInvitations(invite.id)
                                 }
                              />
                              <ButtonDelete
                                 onClick={() =>
                                    handleClickDeleteInvitationsAccept(
                                       invite.id
                                    )
                                 }
                              />
                           </>
                        ) : invite.vacancy_data.status === 50 ? null : (
                           <>
                              <ButtonReject
                                 onClick={() =>
                                    handleClickRejectInvitations(invite.id)
                                 }
                              />
                              <ButtonAccept
                                 onClick={() =>
                                    handleClickAcceptInvitations(invite.id)
                                 }
                              />
                           </>
                        ))}
               </div>
            </td>
         </tr>
      );
   });
};

import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet/es/Helmet";

export const NotFount = () => {
  useEffect(() => {
    document.body.classList.add('body-found');

    return () => {
      document.body.classList.remove('body-found');
    }
  },[]);

  return (
      <div className="not-found">
          <Helmet>
              <title>404</title>
          </Helmet>
        <div className="not-found__sub title-semi-bold">К сожалению, запрошенной страницы не существует</div>
        <h1 className="title-inner-bold">404</h1>
        <Link to={{pathname: '/'}} className="button button-gradient-primary button-upper">Вернуться на главную</Link>
      </div>
  )
};
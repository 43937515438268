import API from "../helpers/api";
import {studentsConstants} from '../constants/resumeConstats';
import {toast} from "react-toastify";
import {history} from "../helpers/history";
import {filterChange} from "../reducers/ClassifiersRedicer";

export function resumeSuccess(resume) {
  return {
    type: studentsConstants.ADD_RESUME,
    resume
  };
}

export function postResume(values) {
  return (dispatch) => {
    return API.post(`/resumes`, values)
      .then(resume => {
        dispatch(resumeSuccess(resume.data.model));
        toast.success("Резюме сохранилось");
        setTimeout(() => history.push('/student/resume'), 2000);
      })
      .catch(error => {
        console.log(error)
      })
  }
}

/* ALL LIST */
export function isLoadingResumes(isLoading) {
  return {
    type: studentsConstants.IS_LOADING_RESUMES,
    isLoading
  }
}

export function getResumeSuccess(resume, pagination) {
  return {
    type: studentsConstants.GET_RESUME_SUCCESS,
    resume,
    pagination
  };
}

export function getResumeFailure(error) {
  return {
    type: studentsConstants.GET_RESUME_FAILURE,
    error
  };
}

export function getListResume(count) {
  return async dispatch => {
    dispatch(isLoadingResumes(true));
    try {
      const resumes = await API.get(`/resumes?page=${count}`);
      dispatch(getResumeSuccess(resumes.data.items, resumes.data._meta));
    } catch (error) {
      dispatch(getResumeFailure(error))
    }
  }
}


export function getResumeByParams(params){
  return (dispatch) => {
    dispatch(isLoadingResumes(true));
    return API.get(`/resumes/search`, {params})
      .then(resume => {
        dispatch(getResumeSuccess(resume.data.items));
      })
      .catch(error => {
        dispatch(getResumeFailure(error))
      })
  }
}


//редактирование и изменение статуса
export function changeResumeStatusSuccess(resume) {
    return {
        type: studentsConstants.CHANGE_RESUME_STATUS_SUCCESS,
        resume
    };
}

export function patchResumeStatus(id, values) {
    return (dispatch) => {
        return API.patch(`/resumes/${id}`, values)
            .then(resume => {
                dispatch(changeResumeStatusSuccess(resume.data));
                toast.success(values.title);
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export function changeResumeSuccess(resumeInfo) {
  return {
    type: studentsConstants.CHANGE_RESUME_SUCCESS,
    resumeInfo
  };
}

export function patchResume(id, values) {
    return (dispatch) => {
        return API.patch(`/resumes/${id}`, values)
            .then(resume => {
               for (let i = 0; i < values.work.length; i++) {
                  if (values.work[i].resume_id) {
                     dispatch(patchWorkExperiences(values.work[i].id, values.work[i]))
                  } else {
                     dispatch(createWorkExperiences(id, values.work[i]))
                  }
               }

               dispatch(changeResumeSuccess(resume.data));
               toast.success('Изменение сохранены');
               setTimeout(() => history.push('/student/resume'), 2000);
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export function deleteResumeSuccess(resume) {
    return {
        type: studentsConstants.DELETE_RESUME_SUCCESS,
        resume
    }
}

export function deleteResume(id) {
    return (dispatch) => {
      return API.delete(`/resumes/${id}`)
        .then(resume => {
            dispatch(deleteResumeSuccess(resume.data.id));
            toast.success('Удаление');
        })
        .catch(error => {
            console.log(error)
        })
    }
}


// карточка
export function getResumeInfoSuccess(resumeInfo) {
    return {
        type: studentsConstants.GET_INFO_RESUME_SUCCESS,
        resumeInfo,
    };
}

export function getResumeInfoFailure(error) {
  return {
    type: studentsConstants.GET_INFO_RESUME_FAILURE,
    error
  };
}

export function toggleIsLoadingResumeInfo(isLoading) {
  return {
    type: studentsConstants.IS_LOADING_RESUME_INFO,
    isLoading
  }
}

export function getResumeInfo(id) {
  return async dispatch => {
    dispatch(toggleIsLoadingResumeInfo(true));
    try {
      const category = await API.get(`/categories`);
      const resumeInfo = await API.get(`/resumes/${id}`);
      dispatch(getResumeInfoSuccess(resumeInfo.data));
      dispatch(filterChange(category.data.items, resumeInfo.data.category, 'category'));
    } catch (error) {
      dispatch(getResumeInfoFailure(error))
    }
  }
}

// счетчик
export function counterResume(count) {
    return {
        type: studentsConstants.COUNTER_RESUME,
        count
    }
}

export function resumeCounter(id) {
    return (dispatch) => {
        return API.post(`/resumes/counter`, {id: id})
            .then(counter => {
                dispatch(counterResume(counter.data))
            })
            .catch(error => {
                console.log(error, 'error')
            })
    }
}

/* SEARCH */
export function getListResumeSearchSuccess(resume) {
  return {
    type: studentsConstants.GET_LIST_RESUME_SEARCH_SUCCESS,
    resume
  };
}

export function getListResumeSearchFailure(error) {
  return {
    type: studentsConstants.GET_LIST_RESUME_SEARCH_FAILURE,
    error
  };
}

export function getListResumeSearch(values) {
  return (dispatch) => {
    return API.get(`/search/resumes`,{params:values})
        .then(answer => {
          dispatch(getListResumeSearchSuccess(answer.data.items));
        })
        .catch(error => {
          console.log(error);
          dispatch(getListResumeSearchFailure(error));
        })
  }
}


/* work experiences */

export function patchWorkExperiences(id, values) {
   return () => {
      return API.patch(`/work-experience/${id}`, values)
         .then(res => {
            console.log(res)
         })
         .catch((e) => {
            console.log(e)
         })
   }
}

export function createWorkExperiences(id, values) {
   return () => {
      return API.post(`/work-experience`, {resume_id: id, ...values})
         .then(res => {
            console.log(res);
            toast.success('Изменение сохранены');
         })
         .catch((e) => {
            console.log(e)
         })
   }
}

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {studentInformation} from "../../actions/Universtiy/StudentsAction";
import {GeneralInformation} from "../GeneralInformation/GeneraInformation";
import {PersonalInfo} from "../PersonalInfo/PersonalInfo";
import {Assessments} from "../Assessments/Assessments";
import {Preloader} from "../Preloader/Preloader";
import {Avatar} from "../Avatar/Avatar";
import {Education} from "../Education/Education";

export const StudentCard = ({match}) => {
  const dispatch = useDispatch();
  const studentId = match.params.studentId;
  const student = useSelector(state => state.student);
  const studentInfo = student.student;
  const userInfo = student.user;
  const checkAuth = useSelector(state => state.authentication.user);

  let list;
  if (Object.keys(studentInfo).length > 0) {
    list = [
      { name: 'Город:', intent: studentInfo.city, show: true},
      { name: 'Телефон:', intent: studentInfo.details.phone, show: false},
      { name: 'Email:', intent: studentInfo.details.email, show: false},
    ];
  }

  useEffect(() => {
    dispatch(studentInformation(studentId))
  }, [dispatch, studentId]);

  return (
      student.isLoading || Object.keys(student.student).length === 0 ? <Preloader/> :
          <div className="inner-card inner-card--custom">
            <aside className="card-aside">
              <div className="card-info">
                <div className="card-info__head card-info__head--center">
                  <Avatar photo={userInfo.photo} alt={`${studentInfo.details.username} ${studentInfo.details.surname}`} class="card-info__img card-info__img--circle" />
                  <h1 className="title-bold text-black">{studentInfo.details.username} {studentInfo.details.surname}</h1>
                  <div className="card-info__params">
                    <PersonalInfo studentData={{birthday: studentInfo.birthday, gender: studentInfo.gender }} />
                  </div>
                </div>

                 <div className="card-info__body">
                    <h3 className="title-bold text-upper">Общая информация</h3>
                    <ul>
                       <GeneralInformation list={list} checkAuth={checkAuth} condition={()=> checkAuth.id === studentInfo.uid || checkAuth.type === 'hirer' || checkAuth.type === 'university'} />
                    </ul>
                 </div>
                 {checkAuth === undefined &&
                     <div className="card-info__body">
                        <p className="mb-none text-red">Персональные данные студента доступны только
                           зарегистрированным пользователям</p>
                     </div>
                 }
              </div>
            </aside>
            <div className="inner-card__content">
              <div className="inner-card-body inner-card-body--border inner-card-body--indentation">
                <div className="inner-card-body__item inner-card-body__item--border">
                  <Education education={studentInfo} />
                </div>
                <div className="inner-card-body__item">
                  <h3 className="title-bold">Оценки</h3>
                  <Assessments assessments={studentInfo.details.assessments} />
                </div>
              </div>
            </div>
          </div>
  )
};


import React from "react";
import { Route } from "react-router-dom";
import { StudentsListContainer } from "../components/StudentsListPage/StudentsListContainer";
import { StudentCard } from "../components/StudentsListPage/StudentCard";

export const StudentsPage = ({ match }) => {
   return (
      <div className="inner-page inner-page--pb">
         <div className="container">
            <Route exact path={match.path} component={StudentsListContainer} />
            <Route path={`${match.url}/:studentId`} component={StudentCard} />
         </div>
      </div>
   );
};

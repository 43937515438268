import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {flattenObjHelper} from "../../Filter/flatteronObjHelper";
import {addGroup, getGroups, deleteGroup, addGroupsFile} from '../../../actions/Universtiy/GroupsAction';
import Modal from "@material-ui/core/Modal";
import {getModalStyle} from "../../Modals/getModalStyle";
import {GroupTableList} from "./GroupTableList";
import {GroupTableHead} from "./GroupTableHead";
import Form from './GroupsForm';
import UploadFileGroups from "../../UploadFile/UploadFileGroups";
import {Preloader} from "../../Preloader/Preloader";
import {Pagination} from "../../Pagination/Pagination";
import UploadFileStudents from "../../UploadFile/UploadFileStudents";
import {addStudentsFile} from "../../../actions/Universtiy/StudentsAction";
import {CloseIcon} from "../../Icons/Close";
import {useRouteMatch} from "react-router-dom";
import {Helmet} from "react-helmet/es/Helmet";

export const GroupsContainer = () => {
   const {url, params} = useRouteMatch();
   const idGroup = params.id;

   const dispatch = useDispatch();
   const groups = useSelector(state => state.groups);
   const universityId = useSelector(state => state.authentication.user.university_local.id);

   const [openAddModal, setAddOpen] = useState(false);
   const handleAddOpen = () => {setAddOpen(true)};
   const handleAddClose = () => {setAddOpen(false);};

   const [openModalAddGroups, setModalAddGroups] = useState(false);
   const handleOpenModalGroups = () => {setModalAddGroups(true)};
   const handleCloseModalGroups = () => {setModalAddGroups(false)};

   const [openModalAddStudents, setModalAddStudents] = useState(false);
   const handleOpenModalStudents = () => {setModalAddStudents(true)};
   const handleCloseModalStudents = () => { setModalAddStudents(false)};

   const handleClickDelete = (id) => {
      dispatch(deleteGroup(id));
   };

   useEffect(() => {
      dispatch(getGroups(idGroup, 1))
   }, [idGroup, dispatch]);

   const onSetPage = (page) => {
      dispatch(getGroups(idGroup, page))
   };

   const handleSubmit = (values) => {
      const newObj = flattenObjHelper(values);
      newObj.speciality_id = idGroup;
      newObj.university_id = universityId;

      dispatch(addGroup(newObj));
      setAddOpen(false);
   };

   const handleFileSubmitGroups = (values) => {
      const file = values.file[0];
      dispatch(addGroupsFile(file, universityId, idGroup, values));
      setModalAddGroups(false);
   };

   const handleFileSubmitStudents = (values) => {
      const file = values.file[0];
      dispatch(addStudentsFile(file, values, universityId));
      setModalAddStudents(false);
   };

   return (
      <>
         <Helmet>
            <title>Группы</title>
         </Helmet>
         {
            groups.isLoading ? <Preloader/> :
               <>
                  <h1 className="title-bold dark display-large">База студентов</h1>

                  <div className="block-inner flex-element">
                     <button className="button button-gradient-primary button-mr" onClick={handleOpenModalGroups}>Загрузить
                        группы из файла
                     </button>
                     <button className="button button-gradient-primary" onClick={handleOpenModalStudents}>Загрузить
                        студентов из файла
                     </button>
                  </div>

                  <div className="table-responsive">
                     <table className="table table--upper">
                        <thead>
                        <GroupTableHead handleOpen={handleAddOpen}/>
                        </thead>
                        <tbody>
                        <GroupTableList url={url} data={groups.groups} handleClickDelete={handleClickDelete}/>
                        </tbody>
                     </table>

                     <Pagination pageCount={groups.pagination.pageCount}
                                 currentPage={groups.pagination.currentPage}
                                 onSetPage={onSetPage}
                     />
                  </div>

                  <Modal
                     className="modal-upload"
                     aria-labelledby="modal-title"
                     open={openAddModal}
                     onClose={handleAddClose}>
                     <div style={getModalStyle} className="modal-content">
                        <div className="modal-header">
                           <button type="button" className="button button-transparent" onClick={handleAddClose}
                                   aria-label="Закрыть модальное окно">
                              <CloseIcon />
                           </button>
                        </div>

                        <div className="modal-body">
                           <Form onSubmit={(values) => {
                              handleSubmit(values)
                           }}/>
                        </div>
                     </div>
                  </Modal>

                  <Modal
                     className="modal-upload"
                     aria-labelledby="modal-title"
                     open={openModalAddGroups}
                     onClose={handleCloseModalGroups}>
                     <div style={getModalStyle} className="modal-content">
                        <div className="modal-header">
                           <button type="button" className="button button-transparent" onClick={handleCloseModalGroups}
                                   aria-label="Закрыть модальное окно">
                              <CloseIcon />
                           </button>
                        </div>

                        <div className="modal-body">
                           <UploadFileGroups
                              onSubmit={(values) => {
                                 handleFileSubmitGroups(values)
                              }}
                           />
                        </div>
                     </div>
                  </Modal>

                  <Modal
                     className="modal-upload"
                     aria-labelledby="modal-title"
                     open={openModalAddStudents}
                     onClose={handleCloseModalStudents}>

                     <div style={getModalStyle} className="modal-content">
                        <div className="modal-header">
                           <button type="button" className="button button-transparent" onClick={handleCloseModalStudents}
                                   aria-label="Закрыть модальное окно">
                              <CloseIcon />
                           </button>
                        </div>

                        <div className="modal-body">
                           <UploadFileStudents onSubmit={(values) => {
                              handleFileSubmitStudents(values)
                           }}/>
                        </div>
                     </div>
                  </Modal>
               </>
         }
      </>

   )
};




import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { universities, universityInfo, cities } from "./HomeReducer";
import { companiesList, company } from "./CompanyReducer";
import { authentication, registration, restore } from "./AuthReducer";
import {
   faculties,
   specialty,
   settingUniversity,
   groups,
   students,
   student,
   assessments,
   temporalPassword,
} from "./UniversitiesReducer";
import { vacancy, vacancyInfo } from "./VacancyReducer";
import { resume, resumesInfo } from "./ResumeReducer";
import { media } from "./FileReducer";
import { feedback, inviteInformationForCard } from "./FeedbackReducer";
import { favorites } from "./FavoritesReducer";
import { filterClassifier } from "./ClassifiersRedicer";
import { themes, article } from "./ArticlesReducer";

export default combineReducers({
   resume,
   resumesInfo,
   vacancy,
   vacancyInfo,
   companiesList,
   company,
   media,
   filterClassifier,
   cities,
   favorites,
   temporalPassword,
   faculties,
   specialty,
   groups,
   students,
   student,
   registration,
   settingUniversity,
   authentication,
   restore,
   assessments,
   universities,
   universityInfo,
   feedback,
   inviteInformationForCard,
   themes,
   article,
   form: formReducer,
});

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dropzone} from "./Dropzone";
import {DeleteIcon} from "../../Icons/Delete";
import {deleteMedia, getResumeMedia} from "../../../actions/UploadPhoto";

export const PhotoGalleryUniversity = () => {
  const user = useSelector(user => user.authentication.user);
  const universityId = user.university_local.id;
  const gallery = useSelector(state => state.media.portfolio);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getResumeMedia(
       {
          link: "university-portfolio",
          title: "portfolio"
       },
       {
          university_id: universityId
       }
    ))
  },[dispatch, universityId]);

  const handleDelete = (id)  => {
     dispatch(deleteMedia(
        {
           link: 'university-portfolio',
           title: "portfolio",
           id: id
        }
     ));
  };

  return (
      <>
        <div className="wrapper-images flex-element">
          <Dropzone toSend={universityId} />
          {gallery ?
              gallery.map((image) => {
                  return <div className="img-wrap relative" key={image.id}>
                    <img src={image.path} alt=""/>
                    <button aria-label="Удалить" className="button-transparent" onClick={() => handleDelete(image.id)}>
                      <DeleteIcon/>
                    </button>
                  </div>
                })
            : null
          }
        </div>
      </>
  )
};
